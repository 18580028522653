const initialState = {
  pause: false,
  dynamic_messages: {},
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_AUDIO_STATUS":
      return {
        ...state,
        pause: action?.pause,
      };
    case "SET_MESSAGES":
      return {
        ...state,
        dynamic_messages: action?.dynamic_messages || {},
      };

    default:
      return state;
  }
};

export default appReducer;
