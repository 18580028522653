import React, { useEffect, useState } from "react";
import "./styles.scss";
import HeadSection from "../../components/headSection";
import Hint from "../../components/hint";
import Progress from "../../components/progress";
import RectangularCallout from "../../components/rectangularCallout";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Volume from "../../components/volume";
import useDisableBrowserBack from "../../components/useDisableBrowserBack";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const ResultScreen = (props) => {
  useDisableBrowserBack();
  const navigate = useNavigate();
  const selectedPassageName = window.localStorage.getItem(
    "selectedPassageName"
  );
  const [selected, setSelected] = useState();

  const [audio, setAudio] = useState(null);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name: "Result",
        properties: { username: props.userData?.username || "" },
      });
    }
  }, [appInsights]);

  useEffect(() => {
    localStorage.removeItem("timer");
    setSelected({
      id: 0,
      content: `Congratulations, you finished today’s article on ${selectedPassageName}! You have earned 20 stars, good job!`,
    });
  }, []);

  const onClickBtn = () => {};

  const onClickPreview = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    navigate("/");
  };

  const onClickNext = () => {
    // navigate("/");
  };

  return (
    <>
      <HeadSection
        onClickPreview={onClickPreview}
        onClickNext={onClickNext}
        showSubmitBtn={false}
        selectedPassage={props?.selectedPassage}
        setSelectedPassage={props?.setSelectedPassage}
        showPrevious={false}
        showNext={false}
      />
      <Grid
        container
        spacing={2}
        className={`mt-4 ${props.selectedThemeClass}`}
      >
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <RectangularCallout
            selected={selected}
            type="RESULT"
            onClickBtn={onClickBtn}
            score={100}
            star={20}
          />
          <Progress
            val={7 + props?.totalSections}
            totalSections={props?.totalSections}
          />
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>

      <Hint data={[]} showVolume={false} audio={audio} setAudio={setAudio} />
      <Volume
        content={selected?.content}
        type="MAIN"
        audio={audio}
        setAudio={setAudio}
      />
    </>
  );
};

export default ResultScreen;
