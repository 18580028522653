import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/index";
import ToastContainer from "./components/toast";
import Loader from "./components/loader";
import MainRoutes from "./routes";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './appinsights';

const App = () => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ToastContainer>
            <Loader/>
            <MainRoutes />
          </ToastContainer>
        </PersistGate>
      </Provider>
    </AppInsightsContext.Provider>
  );
};

export default App;
