import React, { useEffect, useState } from "react";
import "./styles.scss";
import HeadSection from "../../components/headSection";
import Hint from "../../components/hint";
import Volume from "../../components/volume";
import Progress from "../../components/progress";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Detail from "./detail";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import useDisableBrowserBack from "../../components/useDisableBrowserBack";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  textAlign: "center",
};

const Flashcard = (props) => {
  useDisableBrowserBack();
  const navigate = useNavigate();
  const { dynamic_messages } = useSelector((state) => state.appStore);

  const selectedPassageName = window.localStorage.getItem(
    "selectedPassageName"
  );
  const [videoData, setVideoData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [hintData, setHintData] = useState([
    {
      id: 0,
      content: dynamic_messages?.flashcard_hint1,
      btn_label: "OK",
    },
  ]);
  const [hintShow, setHintShow] = useState(true);
  const [selectedHint, setSelectedHint] = useState({
    id: 0,
    content: dynamic_messages?.flashcard_hint1,
    btn_label: "OK",
  });
  const [showType, setShowType] = useState("video");

  const [selected, setSelected] = useState(null);
  const [isFlipped, setIsFlipped] = useState(false);

  const [audio, setAudio] = useState(null);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name: "Flashcard",
        properties: { username: props.userData?.username || "" },
      });
    }
  }, [appInsights]);

  useEffect(() => {
    if (props?.data?.video_url) {
      const fullUrlMatch = props?.data?.video_url.match(
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      );
      const shortUrlMatch = props?.data?.video_url.match(
        /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/)([a-zA-Z0-9_-]{11})/
      );

      if (fullUrlMatch) {
        setVideoData([
          {
            id: 1,
            url: fullUrlMatch[1],
            title: "",
            type: "youtube",
          },
        ]);
      } else if (shortUrlMatch) {
        setVideoData([
          {
            id: 1,
            url: shortUrlMatch[1],
            title: "",
            type: "youtube",
          },
        ]);
      }
    }
  }, [props?.data]);

  const onClickHintBtn = () => {
    if (selectedHint?.id < hintData.length - 1) {
      setSelectedHint(hintData[selectedHint?.id + 1]);
    } else {
      setHintShow(false);
    }
  };

  const onClickPreview = () => {
    if (showType === "image") {
      setShowType("video");
      return;
    }
    navigate("/");
  };

  const onClickNext = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    if (showType === "video") {
      setShowType("image");
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.flashcard_hint2.replace("erosion", selectedPassageName),
          btn_label: "Ok",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.flashcard_hint2.replace("erosion", selectedPassageName),
        btn_label: "Ok",
      });
      setHintShow(true);
      return;
    }
    navigate("/vocab");
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleOpen = () => {
    setShowPopup(true);
  };

  const onClickBear = () => {
    setHintShow(true);
  };

  return (
    <>
      <HeadSection
        onClickPreview={onClickPreview}
        onClickNext={onClickNext}
        onClickDone={onClickNext}
        showSubmitBtn={false}
        selectedPassage={props?.selectedPassage}
        setSelectedPassage={props?.setSelectedPassage}
        hintShow={hintShow}
      />
      <Grid
        container
        spacing={2}
        className={`mt-4 ${props?.selectedThemeClass}`}
      >
        {showType === "video" ? (
          <>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Detail
                data={videoData}
                type={showType}
                handleOpen={handleOpen}
                selected={selected}
                setSelected={setSelected}
                isFlipped={isFlipped}
                setIsFlipped={setIsFlipped}
              />
              <Progress
                val={2}
                margin={"25px"}
                totalSections={props?.totalSections}
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </>
        ) : (
          <>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Detail
                data={props?.data?.match_list}
                type={showType}
                handleOpen={handleOpen}
                selected={selected}
                setSelected={setSelected}
                isFlipped={isFlipped}
                setIsFlipped={setIsFlipped}
              />
              <Progress
                val={2}
                margin={"25px"}
                totalSections={props?.totalSections}
              />
            </Grid>
            <Grid item xs={3}></Grid>
          </>
        )}
      </Grid>

      <Hint
        show={hintShow}
        setShow={setHintShow}
        onClickBtn={onClickHintBtn}
        selected={selectedHint}
        showVolume={false}
        onClickBear={onClickBear}
        audio={audio}
        setAudio={setAudio}
      />

      <Volume
        content={
          hintShow
            ? selectedHint?.content
            : isFlipped
            ? `${selected?.word}. . ${selected?.meaning}`
            : ""
        }
        audio={audio}
        setAudio={setAudio}
        onClickBtn={onClickHintBtn}
      />

      <Modal
        open={showPopup}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="show-hint-modal-important options-modal" sx={style}>
          <label>
            Erosion is the process of eroding or being eroded by wind, water, or
            other natural agents.
          </label>
        </Box>
      </Modal>
    </>
  );
};

export default Flashcard;
