import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  I_do_json: null,
  isIDoEnable: true,
  loaderMusic: false,
  isAutoCorrect: false,
};

export const UiSlice = createSlice({
  name: "sectionStore",
  initialState,
  reducers: {
    setIDoJson: (state, action) => {
      state.I_do_json = action?.payload || [];
    },
    setIsIDoEnable: (state, action) => {
      state.isIDoEnable = action?.payload || false;
    },
    setLoaderMusic: (state, action) => {
      state.loaderMusic = action?.payload || false;
    },
    setIsAutoCorrect: (state, action) => {
      state.isAutoCorrect = action?.payload || false;
    },
  },
});

export const { setIDoJson, setIsIDoEnable, setLoaderMusic, setIsAutoCorrect } =
  UiSlice.actions;
export default UiSlice.reducer;
