import { useEffect, useRef } from "react";

const useInactivityTimer = (callback, timeout = 15) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    const resetTimer = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(callback, 1000 * timeout); // 5 seconds
    };

    const events = [
      "mousemove",
      "click",
      "mouseup",
      "mousedown",
      "keydown",
      "keypress",
      "keyup",
      "submit",
      "change",
      "mouseenter",
      "scroll",
      "resize",
      "dblclick",
    ];

    events.forEach((event) => {
      document.addEventListener(event, resetTimer);
    });

    // Initialize the timer on component mount
    resetTimer();

    // Cleanup event listeners on component unmount
    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, resetTimer);
      });
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  return null;
};

export default useInactivityTimer;
