import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDarkMode: false,
  isTextBold: false,
  textSize: "medium",
  buttonSize: "medium",
  readImageCaption: false
};

export const UiSlice = createSlice({
  name: "themeStore",
  initialState,
  reducers: {
    setIsDarkMode: (state, action) => {
      state.isDarkMode = action?.payload || false;
    },
    setIsTextBold: (state, action) => {
      state.isTextBold = action?.payload || false;
    },
    setTextSize: (state, action) => {
      state.textSize = action?.payload || "medium";
    },
    setButtonSize: (state, action) => {
      state.buttonSize = action?.payload || "medium";
    },
    setReadImageCaption: (state, action) => {
      state.readImageCaption = action?.payload || false;
    },
  },
});

export const { setIsDarkMode, setIsTextBold, setTextSize, setButtonSize, setReadImageCaption } =
  UiSlice.actions;
export default UiSlice.reducer;
