import { createSlice } from "@reduxjs/toolkit";
import { loginUser, forgotPassword } from "./actions";

const initialState = {
  accessToken: null,
  refreshToken: null,
  user_data: {},
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setInitialState: () => {
      console.log("called setInitialState");

      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      const { status, data } = payload;
      if (status === 200 && data.status) {
        return {
          ...state,
          ...data,
        };
      } else {
        return initialState;
      }
    });
    builder.addCase(loginUser.rejected, () => {
      console.log("rejected");
      return initialState;
    });
  },
});

export const { setInitialState } = AuthSlice.actions;

export { loginUser, forgotPassword };
export default AuthSlice.reducer;
