import api from "../api";
import authEndpoint from "./config";
import axios from "axios";

const auth = {
  async loginUser(payload) {
    const loginRes = await api.post(authEndpoint.loginUser, payload);
    const config = {
      headers: { Authorization: `Bearer ${loginRes?.data?.token}` },
    };
    const userRes = await axios.get(
      process.env.REACT_APP_BASE_URL + authEndpoint.user,
      config
    );
    return {
      status: 200,
      data: {
        accessToken: loginRes?.data?.token,
        status: true,
        user_data: userRes?.data?.data
      },
    };
  },
  async forgotPassword(payload) {
    return await api.post(authEndpoint.forgotPassword, payload);
  },
  async logoutUser() {
    await api.get(authEndpoint.logoutUser);
  },
};

export default auth;
