import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ToastContext from "../../../components/toast/toastContext";
import { setLoader } from "../../../store/reducer/ui";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import "./styles.scss";
import "../../../constants/styles.scss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import api from "../../../apis/api";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Confirmation from "../../../components/confirmation";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirm_password: "",
    account_type: "",
    email: "",
    first_name: "",
    last_name: "",
    code_name: "",
    speech_speed: "",
    school_or_org: "",
  });
  const [usernameErrorText, setUsernameErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState("");
  const [accountTypeErrorText, setAccountTypeErrorText] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [open, setOpen] = useState({
    show: false,
    title: "Confirm Submit",
    message:
      "Are you sure you want to save this information? Please review your details before proceeding.",
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onChangeInput = (event, type) => {
    const value = event.target.value;
    if (type === "first_name" || type === "last_name" || type === "username") {
      const htmlTagPattern = /<\/?[^>]+(>|$)/g;
      if (htmlTagPattern.test(value)) {
        return;
      }
    }
    formData[type] = value;
    setFormData({ ...formData });
    switch (type) {
      case "username":
        value
          ? setUsernameErrorText("")
          : setUsernameErrorText("Username is required.");
        break;
      case "password":
        value
          ? setPasswordErrorText("")
          : setPasswordErrorText("Password must be at least 8 characters.");
        break;
      case "confirm_password":
        value
          ? setConfirmPasswordErrorText("")
          : setConfirmPasswordErrorText(
              "Password must be at least 8 characters."
            );
        break;
      case "account_type":
        value
          ? setAccountTypeErrorText("")
          : setAccountTypeErrorText("AccountType is required.");
        break;
      default:
        break;
    }
  };

  // function validatePassword(password) {
  //   const regex =
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  //   return regex.test(password);
  // }

  const onClickRegister = async () => {
    if (
      !formData?.first_name ||
      !formData?.last_name ||
      !formData?.username ||
      !formData?.password ||
      !formData?.confirm_password ||
      !formData?.account_type
    ) {
      return toastMessage("error", "Please fill all required fields!");
    }
    if (formData.password !== formData?.confirm_password) {
      return toastMessage(
        "error",
        "Password and confirm password must be the same!"
      );
    }
    // if (formData?.password.length < 12) {
    //   return toastMessage(
    //     "error",
    //     "Minimum password length must be 12 characters!"
    //   );
    // }
    if (formData?.username.length < 3) {
      return toastMessage(
        "error",
        "Minimum username length must be 3 characters!"
      );
    }
    if (formData?.email) {
      const isValidEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          formData?.email
        );
      if (!isValidEmail) {
        return toastMessage("error", "Email address must be valid");
      }
    }
    setOpen({
      ...open,
      show: true,
    });
  };

  const onClickCancel = () => {
    navigate("/");
  };

  const hideModal = () => {
    setOpen({
      ...open,
      show: false,
    });
  };

  const confirmModal = async () => {
    try {
      dispatch(setLoader(true));
      const response = await api.post("/user/add", formData);
      hideModal();
      if (response?.status === 200) {
        toastMessage("success", "Account created Successfully!");
        dispatch(setLoader(false));
        navigate("/");
      } else {
        toastMessage(
          "error",
          response?.response?.data?.message
            ? response?.response?.data?.message
            : "Something went wrong"
        );
        dispatch(setLoader(false));
      }
    } catch (err) {
      dispatch(setLoader(false));
      console.log(err);
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <h4 className="theme-color">
        <b>Create New Account!</b>
      </h4>
      <Grid container spacing={2}>
        {/* <Grid item xs={5} className="left-section">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <p className="top-content">
              <h2 style={{ marginBottom: "10px" }}>
                <b>Meet Kai,</b>
              </h2>
              Already have an account? <br />{" "}
              <span
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={login}
              >
                sign in here
              </span>
            </p>
            <img src={clipArtImage} alt="clip-art" width="80%" />
          </Grid>
        </Grid>
        <Grid item xs={7} className="right-section"> */}

        <Grid item xs={12} className="register-form">
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <TextField
                label="First Name"
                placeholder=""
                value={formData?.first_name || ""}
                onChange={(event) => {
                  onChangeInput(event, "first_name");
                }}
                style={{
                  background: "white",
                  borderRadius: "8px",
                }}
                variant="outlined"
                size="small"
                fullWidth
                required
                onKeyDown={(e) => {
                  if (e.key === "Enter") onClickRegister();
                }}
                autoComplete="off"
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <TextField
                label="Last Name"
                placeholder=""
                value={formData?.last_name || ""}
                onChange={(event) => {
                  onChangeInput(event, "last_name");
                }}
                style={{
                  background: "white",
                  borderRadius: "8px",
                }}
                variant="outlined"
                size="small"
                fullWidth
                required
                onKeyDown={(e) => {
                  if (e.key === "Enter") onClickRegister();
                }}
                autoComplete="off"
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <TextField
                label="Username"
                placeholder=""
                value={formData?.username || ""}
                onChange={(event) => {
                  onChangeInput(event, "username");
                }}
                errorText={usernameErrorText}
                error={usernameErrorText ? true : false}
                style={{
                  background: "white",
                  borderRadius: "8px",
                }}
                variant="outlined"
                size="small"
                fullWidth
                required
                onKeyDown={(e) => {
                  if (e.key === "Enter") onClickRegister();
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <TextField
                label="Email"
                placeholder=""
                value={formData?.email || ""}
                onChange={(event) => {
                  onChangeInput(event, "email");
                }}
                style={{
                  background: "white",
                  borderRadius: "8px",
                }}
                variant="outlined"
                size="small"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") onClickRegister();
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <TextField
                label="Password"
                placeholder=""
                value={formData?.password || ""}
                onChange={(event) => {
                  onChangeInput(event, "password");
                }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                errorText={passwordErrorText}
                error={passwordErrorText ? true : false}
                style={{
                  background: "white",
                  borderRadius: "8px",
                }}
                variant="outlined"
                size="small"
                fullWidth
                required
                onKeyDown={(e) => {
                  if (e.key === "Enter") onClickRegister();
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <TextField
                label="Confirm Password"
                placeholder=""
                value={formData?.confirm_password || ""}
                onChange={(event) => {
                  onChangeInput(event, "confirm_password");
                }}
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                errorText={confirmPasswordErrorText}
                error={confirmPasswordErrorText ? true : false}
                style={{
                  background: "white",
                  borderRadius: "8px",
                }}
                variant="outlined"
                size="small"
                fullWidth
                required
                onKeyDown={(e) => {
                  if (e.key === "Enter") onClickRegister();
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <FormControl
                size="small"
                fullWidth
                required
                errorText={accountTypeErrorText}
                error={accountTypeErrorText ? true : false}
                style={{
                  background: "white",
                  borderRadius: "8px",
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Select Account Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData?.account_type || ""}
                  label="Select Account Type"
                  onChange={(event) => {
                    onChangeInput(event, "account_type");
                  }}
                  MenuProps={{
                    classes: {
                      paper: "account-type-select",
                    },
                  }}
                  size="small"
                >
                  <MenuItem value={""}>Select Account Type</MenuItem>;
                  <MenuItem value={"student"}>Student</MenuItem>;
                  <MenuItem value={"educator"}>Educator</MenuItem>;
                  <MenuItem value={"parent"}>Parent</MenuItem>;
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <TextField
                label="School/Organization"
                placeholder=""
                value={formData?.school_or_org || ""}
                onChange={(event) => {
                  onChangeInput(event, "school_or_org");
                }}
                style={{
                  background: "white",
                  borderRadius: "8px",
                }}
                variant="outlined"
                size="small"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") onClickRegister();
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <FormControl
                size="small"
                fullWidth
                style={{
                  background: "white",
                  borderRadius: "8px",
                }}
                className="speech-speed-select"
              >
                <InputLabel id="demo-simple-select-label">
                  Speech Speed
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData?.speech_speed || ""}
                  label="Select Account Type"
                  onChange={(event) => {
                    onChangeInput(event, "speech_speed");
                  }}
                  size="small"
                  MenuProps={{
                    classes: {
                      paper: "speech-speed-select",
                    },
                  }}
                >
                  <MenuItem value={""}>Select Speech Speed</MenuItem>;
                  <MenuItem value={"slow"}>Slow</MenuItem>;
                  <MenuItem value={"default"}>Medium</MenuItem>;
                  <MenuItem value={"fast"}>Fast</MenuItem>;
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={onClickCancel}
              style={{ backgroundColor: "orange", marginRight: "5px" }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onClickRegister}
              style={{ backgroundColor: "#008080" }}
            >
              Create New Account!
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
      <Confirmation
        data={open}
        hideModal={hideModal}
        confirmModal={confirmModal}
      />
    </Box>
  );
};

export default Register;
