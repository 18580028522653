import React, { useEffect, useState } from "react";
import "./styles.scss";
// import Detail from "./detail";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};
const maxWords = 15;

const GistScreen = (props) => {
  const {
    selected,
    formData,
    onChangeInput,
    handleCorrectionOnChange,
    selectedType,
    onKeyDownHandler,
    gistError,
    highlightGist,
    answers_and_clues,
    selectedSection,
    sectionImages,
    hintShow,
  } = props;

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (answers_and_clues && answers_and_clues?.length) {
      let clues = answers_and_clues.find(
        (x) => x.section_id == selectedSection?.id
      );
      setSelectedAnswer(clues?.section_level);
    }
  }, [answers_and_clues, selectedSection]);

  const handlePopup = () => {
    setOpen(!open);
  };

  return (
    <>
      <Grid container spacing={2} className="mt-4">
        <Grid
          container
          style={{ maxHeight: "45vh", overflow: "auto", padding: "0 15px" }}
        >
          {sectionImages?.length > 0 && (
            <>
              <Grid item xs={4}>
                <Grid>
                  {sectionImages?.map((img) => {
                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handlePopup();
                          setSelectedImage(img);
                        }}
                      >
                        <img
                          src={img?.url}
                          width="100%"
                          // style={{ marginBottom: "5px" }}
                        />
                        <br />
                        <p style={{ fontSize: "14px" }}>{img.caption}</p>
                      </div>
                    );
                  })}
                </Grid>
                <Grid></Grid>
              </Grid>
              <br />
            </>
          )}

          <Grid
            item
            xs={sectionImages?.length > 0 ? 8 : 10}
            style={{ paddingLeft: sectionImages?.length > 0 ? "4rem" : "1rem" }}
          >
            <label>
              <b>Who/What</b>
            </label>
            <div className="item-list" style={{ marginTop: "0" }}>
              <p style={{ margin: 0 }}>{selectedAnswer?.who_or_what?.answer}</p>
            </div>
            <label style={{ marginTop: "15px" }}>
              <b>Most Important Information</b>
            </label>
            <div className="item-list" style={{ marginTop: "0" }}>
              <p style={{ margin: 0 }}>
                {selectedAnswer?.most_important_info?.answer}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid>
            <label>
              <b>Gist Statement</b>
            </label>
            <TextField
              type="text"
              multiline
              placeholder="what's the gist?"
              className={`${highlightGist ? "highlighted-textfield" : ""}`}
              id="Erosion"
              label=""
              value={formData?.GIST}
              size="small"
              fullWidth
              // style={{ background: "#d9d9d9" }}
              style={{
                background: selectedType == "GIST" ? "white" : "#d9d9d9",
              }}
              disabled={selectedType != "GIST" || hintShow}
              inputProps={{
                maxLength: 100,
              }}
              onChange={(e) => {
                // const inputText = e.target.value;
                // const words = inputText.split(/\s+/); // Split by whitespace
                // let val = "";
                // if (words.length <= maxWords) {
                //   val = inputText;
                // } else {
                //   const limitedText = words.slice(0, maxWords).join(" ");
                //   val = limitedText;
                // }
                onChangeInput(e.target.value, "GIST");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") onKeyDownHandler();
                if (e.key === " ") handleCorrectionOnChange("GIST");
              }}
              onBlur = {() => {
                handleCorrectionOnChange("GIST")
              }}
              error={gistError ? true : false}
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={open}
        // onClose={handlePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="show-image-detail-modal" sx={style}>
          <img
            src={selectedImage?.url}
            alt="who what image"
            className="who-what-img"
            style={{ width: "100%" }}
          />
          <br />
          <span style={{ fontSize: "18px" }}>{selectedImage?.caption}</span>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#008080",
              float: "right",
              marginTop: "10px",
            }}
            onClick={() => {
              handlePopup();
            }}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default GistScreen;
