import axios from "axios";
import { store } from "../store";

import { setInitialState } from "../store/reducer/auth";

const defaultTimeout = 40000;

const handleRequest = (config) => {
  const accessToken = store.getState()?.auth?.accessToken;
  const addToken = config.url === "/auth/login" ? false : true;

  if (addToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  } else {
    return {
      ...config,
      headers: {
        ...config.headers,
      },
    };
  }
};

const createApiInstance = (baseURL, name = "") => {
  const api = axios.create({ baseURL, timeout: defaultTimeout });

  if (!baseURL) {
    throw new Error(
      `${name} baseURL not set during built. Please, set baseURL`
    );
  }

  api.interceptors.request.use(handleRequest);

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if(error.config.url === "/auth/login" || error.config.url === "/reset-password-link"){
        store.dispatch(setInitialState());
      } else if (error.response.status === 401 || error.response.status === 444) {
        store.dispatch(setInitialState());
        window.location.reload();
      }
      return error;
    }
  );

  return {
    instance: api,
  };
};

export default createApiInstance;
