import React, { useEffect, useState } from "react";
import "./styles.scss";
import HeadSection from "../../../components/headSection";
import Hint from "../../../components/hint";
import Progress from "../../../components/progress";
import RectangularCallout from "../../../components/rectangularCallout";
import Volume from "../../../components/volume";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import useDisableBrowserBack from "../../../components/useDisableBrowserBack";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useSelector } from "react-redux";

const data = [
  {
    id: 0,
    content:
      "Awesome, now that we have all our gist statements, let’s answer the purpose question: What causes erosion and how does it change the land?",
    btn_label: "",
  },
  {
    id: 2,
    content:
      "Now let’s answer the first part of the purpose question. Type your answer in the box at the bottom. Now let’s answer the first part of the purpose question. Type your answer in the box at the bottom.",
    btn_label: "OK",
  },
  {
    id: 3,
    content:
      "Let’s select all of the gist statements relevant to the first part of the answer purpose question. We also have a sentence starter to help us come up with the answer.",
    btn_label: "OK",
  },
  {
    id: 4,
    content: "That’s not exactly it, let’s give it another try",
    btn_label: "OK",
  },
];

const RemindScreen = (props) => {
  useDisableBrowserBack();
  const navigate = useNavigate();
  const { dynamic_messages } = useSelector((state) => state.appStore);
  const [selected, setSelected] = useState();

  const [audio, setAudio] = useState(null);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name: "APQ Remind",
        properties: { username: props.userData?.username || "" },
      });
    }
  }, [appInsights]);

  useEffect(() => {
    if (props?.data) {
      setSelected({
        id: 0,
        content: dynamic_messages?.apq_remind.replace(
          "{APQ_PART}",
          `<b>${props?.data}</b>`
        ),
        btn_label: "",
      });
    }
  }, [props?.data]);

  const onClickBtn = () => {
    let ind = data.findIndex((x) => x?.id === selected?.id);
    if (ind < data?.length - 1) {
      setSelected(data[ind + 1]);
    } else {
      onClickNext();
    }
  };

  const onClickPreview = () => {
    // navigate("/intro/final");
  };

  const onClickNext = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    navigate("/apq/level");
  };

  return (
    <>
      <HeadSection
        onClickPreview={onClickPreview}
        onClickNext={onClickNext}
        onClickDone={onClickNext}
        showSubmitBtn={false}
        selectedPassage={props?.selectedPassage}
        setSelectedPassage={props?.setSelectedPassage}
      />
      <Grid
        container
        spacing={2}
        className={`mt-4 ${props?.selectedThemeClass}`}
      >
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <RectangularCallout
            selected={selected}
            type="INTRODUCTION"
            onClickBtn={onClickBtn}
          />
          <Progress
            val={5 + props?.totalSections}
            totalSections={props?.totalSections}
          />
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>

      <Hint data={[]} showVolume={false} audio={audio} setAudio={setAudio} />
      <Volume
        content={selected?.content}
        type="MAIN"
        audio={audio}
        setAudio={setAudio}
      />
    </>
  );
};

export default RemindScreen;
