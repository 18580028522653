import "./styles.scss";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  boxShadow: "0px 4px 4px 0px #00000040",
  // margin: {margin},
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D9D9D9",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#008080" : "#D9D9D9",
  },
}));

const Progress = (props) => {
  const { val = 0, margin = "25px 0" } = props;
  return (
    <div style={{ margin, position: "relative" }}>
      <div
        className={`progress-label ${
          val > (7 + props?.totalSections) / 2 ? "progress-label-white" : ""
        }`}
      >
        <span>
          {val}/{7 + props?.totalSections}
        </span>
      </div>
      <BorderLinearProgress
        variant="determinate"
        value={(val / (7 + props?.totalSections)) * 100}
      />
    </div>
  );
};

export default Progress;
