import React, { useEffect, useState } from "react";
import "./styles.scss";
import HeadSection from "../../components/headSection";
import Hint from "../../components/hint";
import Progress from "../../components/progress";
import RectangularCallout from "../../components/rectangularCallout";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Volume from "../../components/volume";
import useDisableBrowserBack from "../../components/useDisableBrowserBack";
import sampleVideo from "../../assets/video/sample-video.mp4";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useSelector } from "react-redux";

const IntroductionScreen = (props) => {
  useDisableBrowserBack();
  const navigate = useNavigate();
  const { type } = useParams();

  const { dynamic_messages } = useSelector((state) => state.appStore);
  const [selected, setSelected] = useState();

  const [data, setData] = useState([]);
  const [audio, setAudio] = useState(null);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name:
          type === ""
            ? "Introduction"
            : type === "purpose"
            ? "Purpose"
            : type === "final"
            ? "Section Final"
            : "",
        properties: { username: props.userData?.username || "" },
      });
    }
  }, [appInsights]);

  useEffect(() => {
    if (!type) {
      setData([
        {
          id: 0,
          content: dynamic_messages?.intro1?.replace(
            "{USER_NAME}",
            `<b>${props?.userData?.first_name}</b>`
          ),
          btn_label: "Let's go!",
          is_speech: true,
        },
        {
          id: 2,
          content: dynamic_messages?.intro2,
          btn_label: "Skip",
        },
      ]);
      setSelected({
        id: 0,
        content: dynamic_messages?.intro1?.replace(
          "{USER_NAME}",
          `<b>${props?.userData?.first_name}</b>`
        ),
        btn_label: "Let's go!",
        is_speech: true,
      });
    } else if (type === "final") {
      setData([
        {
          id: 0,
          content: dynamic_messages?.section_end_intro1,
        },
      ]);
      setSelected({
        id: 0,
        content: dynamic_messages?.section_end_intro1,
      });
    } else {
      setData([
        {
          id: 0,
          content: dynamic_messages?.purpose_hint1
            ? dynamic_messages?.purpose_hint1.replace(
                "{APQ_PART}",
                `<b>${props?.data}</b>`
              )
            : "",
          btn_label: "Let’s go!",
        },
        {
          id: 1,
          content:
            "We're going to read an article and you will learn to do the following:<ul class='fit-content' style='margin: 0 auto'><li class='fit-content'>Identify <b>who or what</b> the paragraph is about.</li><li class='fit-content'>Identify the <b>most important information</b> about the who or what.</li><li class='fit-content'>Summarize this information by writing a <b>gist statement</b>.</li></ul></br>When you are finished, you can click next to move on.",
          btn_label: "Ok",
        },
      ]);
      setSelected({
        id: 0,
        content: dynamic_messages?.purpose_hint1
          ? dynamic_messages?.purpose_hint1.replace(
              "{APQ_PART}",
              `<b>${props?.data}</b>`
            )
          : "",
        btn_label: "Let’s go!",
      });
    }
  }, [props?.userData]);

  const onClickBtn = () => {
    let ind = data.findIndex((x) => x?.id === selected?.id);
    if (ind < data?.length - 1) {
      setSelected(data[ind + 1]);
    } else {
      onClickNext();
    }
  };

  const onClickPreview = () => {
    if (type === "purpose") {
      navigate("/vocab");
    } else if (type === "final") {
      navigate("/section");
    } else {
      navigate("/");
    }
  };

  const onClickNext = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    if (type === "purpose") {
      navigate("/section", { replace: true });
    } else if (type === "final") {
      navigate("/apq/remind");
    } else {
      navigate("/flashcard");
    }
  };

  return (
    <>
      {!type && (
        <video autoPlay loop muted className="bg-video">
          <source src={sampleVideo} type="video/mp4" />
          <source src={sampleVideo} type="video/ogg" />
        </video>
      )}

      <HeadSection
        onClickPreview={onClickPreview}
        onClickNext={onClickBtn}
        showPrevious={type ? true : false}
        showNext={true}
        onClickDone={onClickNext}
        showSubmitBtn={false}
        selectedPassage={props?.selectedPassage}
        setSelectedPassage={props?.setSelectedPassage}
        passageData={props?.passageData}
        showPassage={!type ? true : false}
      />
      <Grid
        container
        spacing={2}
        className={selected?.content.length > 400 ? "" : "mt-4"}
      >
        <Grid
          item
          xs={
            props?.selectedThemeClass === "large-font" ||
            selected?.content.length > 400
              ? 2
              : selected?.content.length > 250
              ? 3
              : 4
          }
        ></Grid>
        <Grid
          item
          xs={
            props?.selectedThemeClass === "large-font" ||
            selected?.content.length > 400
              ? 8
              : selected?.content.length > 250
              ? 6
              : 4
          }
        >
          <div
            className={props?.selectedThemeClass}
            style={{ minHeight: !type ? "300px" : "auto" }}
          >
            <RectangularCallout
              selected={selected}
              type="INTRODUCTION"
              onClickBtn={onClickBtn}
            />
          </div>
          <Hint
            data={[]}
            showVolume={false}
            from={"intro-page"}
            audio={audio}
            setAudio={setAudio}
          />
          <Progress
            val={type === "purpose" ? 4 : type === "final" ? 6 : 1}
            label={"test"}
            totalSections={props?.totalSections}
          />
        </Grid>
        <Grid
          item
          xs={
            props?.selectedThemeClass === "large-font" ||
            selected?.content.length > 400
              ? 2
              : selected?.content.length > 250
              ? 3
              : 4
          }
        ></Grid>
      </Grid>

      {/* {type && <Hint data={[]} showVolume={false} />} */}
      <Volume
        content={selected?.content}
        type="MAIN"
        audio={audio}
        setAudio={setAudio}
      />
    </>
  );
};

export default IntroductionScreen;
