import React, { useState } from "react";
import "./styles.scss";
import { Typography, Grid } from "@mui/material";
import GeneralSetting from "./general";
import AccessibilitySetting from "./accessibility";

const SettingDrawer = (props) => {
  const [selectedMenu, setSelectedMenu] = useState("Accessibility");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} className="setting-left-section">
        <Typography variant="h6" gutterBottom>
          Settings
        </Typography>
        <ul>
          <li
            className={selectedMenu === "General Setting" ? "active-li" : ""}
            onClick={() => setSelectedMenu("General Setting")}
          >
            General Setting
          </li>
          <li
            className={selectedMenu === "Accessibility" ? "active-li" : ""}
            onClick={() => setSelectedMenu("Accessibility")}
          >
            Accessibility
          </li>
        </ul>
      </Grid>
      <Grid item xs={8} className="setting-right-section">
        <Typography variant="h6" gutterBottom>
          {selectedMenu}
        </Typography>
        {selectedMenu === "General Setting" && <GeneralSetting />}

        {selectedMenu === "Accessibility" && <AccessibilitySetting />}
      </Grid>
    </Grid>
  );
};

export default SettingDrawer;
