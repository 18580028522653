import CryptoJS from "crypto-js";
const SECRET_KEY = process.env.REACT_APP_SERVER_KEY;

// Encrypt data using AES encryption
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// Decrypt data using AES decryption
export const decryptData = (encryptedData) => {
  if (!encryptedData) {
    return "";
  }
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
