import { Modal, Button } from "react-bootstrap";

const Confirmation = ({ data, hideModal, confirmModal }) => {
  return (
    <Modal show={data.show} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{data?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-success">{data?.message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={hideModal} style={{backgroundColor:"orange", borderColor: "orange"}}>
          Cancel
        </Button>
        <Button variant="success" onClick={() => confirmModal()} style={{backgroundColor:"#008080"}} >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirmation;
