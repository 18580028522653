import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import IntroductionScreen from "../pages/introduction";
import Flashcard from "../pages/flashcard";
import Vocab from "../pages/vocab";
import SectionScreen from "../pages/section";
import ResultScreen from "../pages/result";
import NotFoundPage from "../pages/404";
import api from "../apis/api";
import CircularProgress from "@mui/material/CircularProgress";
import RemindScreen from "../pages/apq/remind";
import LevelScreen from "../pages/apq/level";
import Register from "../pages/admin/register";
import { useDispatch } from "react-redux";
import { setDynamicMessages } from "../store/actions/appActions";
import { TimerContext } from "../components/timerContext";

const ProtectRoutes = (props) => {
  const dispatch = useDispatch();
  const timer = useContext(TimerContext);
  
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState(null);

  const [passageData, setPassageData] = useState([]);
  const [userData, setUserData] = useState(null);
  const selectedItem = window.localStorage.getItem("selectedPassage");
  if (!selectedItem) {
    window.localStorage.setItem("selectedPassage", "erosion");
    window.localStorage.setItem("selectedPassageName", "erosion");
  }
  const [selectedPassage, setSelectedPassage] = useState(
    selectedItem ? selectedItem : "erosion"
  );

  const getContentData = async () => {
    try {
      setSpinner(true);
      if (!userData) {
        const resUser = await api.get("/user/me");
        setUserData(resUser?.data?.data);
      }
      const response = await api.get(`/content?contentID=${selectedPassage}`);
      const responseMsg = await api.get(`/femessages/`);
      dispatch(setDynamicMessages(responseMsg?.data?.data));
      setData(response?.data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getContentData();
  }, [selectedPassage]);

  const getPassageData = async () => {
    try {
      const response = await api.get(`/content/all`);
      setPassageData(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getPassageData();
  }, []);

  if (spinner) {
    return (
      <div className="row dashboard-right-section">
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      </div>
    );
  }
  return (
    <div
      className={`row dashboard-right-section ${
        timer?.timer > 0 && !timer?.isRunning ? "disable-click" : ""
      }`}
    >
      <Routes>
        <Route
          path="/"
          element={
            <IntroductionScreen
              {...props}
              userData={userData}
              selectedPassage={selectedPassage}
              setSelectedPassage={setSelectedPassage}
              totalSections={data?.content?.body?.length || 0}
              selectedThemeClass={props?.selectedThemeClass}
              passageData={passageData?.contents || []}
            />
          }
        />
        <Route
          path="/intro/:type"
          element={
            <IntroductionScreen
              {...props}
              userData={userData}
              selectedPassage={selectedPassage}
              setSelectedPassage={setSelectedPassage}
              totalSections={data?.content?.body?.length || 0}
              selectedThemeClass={props?.selectedThemeClass}
              passageData={passageData?.contents || []}
              data={data?.content?.apq?.apq_question}
            />
          }
        />
        <Route
          path="/flashcard"
          element={
            <Flashcard
              {...props}
              userData={userData}
              data={data?.answers_and_clues?.content_answers}
              selectedPassage={selectedPassage}
              setSelectedPassage={setSelectedPassage}
              totalSections={data?.content?.body?.length || 0}
              selectedThemeClass={props?.selectedThemeClass}
            />
          }
        />
        <Route
          path="/vocab"
          element={
            <Vocab
              {...props}
              userData={userData}
              data={data?.answers_and_clues?.content_answers}
              selectedPassage={selectedPassage}
              setSelectedPassage={setSelectedPassage}
              totalSections={data?.content?.body?.length || 0}
              selectedThemeClass={props?.selectedThemeClass}
            />
          }
        />
        <Route
          path="/section"
          element={
            <SectionScreen
              {...props}
              userData={userData}
              data={data}
              selectedPassage={selectedPassage}
              setSelectedPassage={setSelectedPassage}
              totalSections={data?.content?.body?.length || 0}
              selectedThemeClass={props?.selectedThemeClass}
              id_do_mode={data?.content?.i_do_mode || []}
            />
          }
        />
        <Route
          path="/result"
          element={
            <ResultScreen
              {...props}
              userData={userData}
              totalSections={data?.content?.body?.length || 0}
              selectedThemeClass={props?.selectedThemeClass}
            />
          }
        />
        <Route
          path="/apq/remind"
          element={
            <RemindScreen
              {...props}
              userData={userData}
              data={data?.content?.apq?.apq_question}
              selectedPassage={selectedPassage}
              setSelectedPassage={setSelectedPassage}
              totalSections={data?.content?.body?.length || 0}
              selectedThemeClass={props?.selectedThemeClass}
            />
          }
        />
        <Route
          path="/apq/level"
          element={
            <LevelScreen
              {...props}
              userData={userData}
              question={data?.content?.apq}
              answer={data?.answers_and_clues?.content_answers?.apq}
              selectedPassage={selectedPassage}
              setSelectedPassage={setSelectedPassage}
              totalSections={data?.content?.body?.length || 0}
              selectedThemeClass={props?.selectedThemeClass}
            />
          }
        />

        <Route path="/register" element={<Register {...props} />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default ProtectRoutes;
