import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { Container, Draggable } from "react-smooth-dnd";

const Detail = (props) => {
  const { data, setData, handleDataOnDragEnd, onMissMatchMeaning } = props;
  const [start, setStart] = useState(false);

  const handleDragEnd = (result) => {
    if (!result.destination) return; // dropped outside the list
    if (
      data[result.source.index].right_item !=
      data[result.destination.index].meaning
    ) {
      data[result.destination.index].is_drag_running = false;
      data[result.source.index].is_drag_running = false;
      setData(data);
      onMissMatchMeaning();
      return;
    }
    setStart(true);
    const existing = data[result.destination.index].right_item;
    data[result.destination.index].right_item =
      data[result.source.index].right_item;
    data[result.destination.index].is_updated = true;
    data[result.destination.index].className = "";
    data[result.destination.index].is_drag_running = false;
    data[result.destination.index].className =
      data[result.destination.index].right_item ==
      data[result.destination.index].meaning
        ? "match-vocab-answer"
        : "miss-match-vocab-answer";

    if (result.source.index != result.destination.index) {
      data[result.source.index].right_item = existing;
      data[result.source.index].is_updated = false;
      data[result.source.index].className = "";
      data[result.source.index].is_drag_running = false;
      // data[result.source.index].className =
      //   data[result.source.index].right_item == data[result.source.index].meaning
      //     ? "match-vocab-answer"
      //     : "miss-match-vocab-answer";
    }

    setData(data);

    let arr = [data[result.destination.index]];
    // if (result.source.index < result.destination.index) {
    //   arr.push(data[result.source.index]);
    //   arr.push(data[result.destination.index]);
    // } else if (result.source.index > result.destination.index) {
    //   arr.push(data[result.destination.index]);
    //   arr.push(data[result.source.index]);
    // } else {
    //   arr.push(data[result.destination.index]);
    // }
    handleDataOnDragEnd(arr);
  };

  const handleDragStart = (result) => {
    data[result?.draggableId].is_drag_running = true;
    setData(data);
  };

  return (
    <Grid container spacing={2} className="main-box-vocab">
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {data.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Grid className="container" spacing={2}>
                      <Grid item xs={4}>
                        <div className={`left-textbox ${item?.className}`}>
                          <label className="inner-content">{item?.word}</label>
                        </div>
                      </Grid>
                      <Grid item xs={8}>
                        <div
                          className={`right-textbox ${
                            item?.is_updated ? "vocab_answer_changed" : ""
                          } ${item?.className} ${
                            item?.is_drag_running ? "drag-running" : ""
                          }`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <label className="inner-content">
                            {item?.right_item}
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};

export default Detail;
