import React, { createContext, useState, useEffect, useContext } from "react";
import moment from "moment";
import { decryptData } from "../../utils/crypto";
export const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 0) {
            clearInterval(intervalId);
            setIsRunning(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000); // Update timer every second
    }

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [isRunning]); // Run effect whenever isRunning changes

  useEffect(() => {
    const encryptedTime = localStorage.getItem("timer");
    const decryptedTime = decryptData(encryptedTime);
    const now = moment();
    const diff = moment.duration(
      moment(decryptedTime ? decryptedTime : "").diff(now)
    );
    if (decryptedTime && diff && (diff.minutes() > 0 || diff.seconds() > 0)) {
      startTimer(diff.minutes() * 60 + diff.seconds());
    }
  }, []);

  const startTimer = (duration) => {
    setTimer(duration);
    setIsRunning(true);
  };

  const stopTimer = () => {
    setTimer(0);
    setIsRunning(false);
  };

  const pauseTimer = () => {
    setTimer(timer);
    setIsRunning(false);
  };

  const resumeTimer = () => {
    setTimer(timer);
    setIsRunning(true);
  };

  const contextValue = {
    timer,
    startTimer,
    stopTimer,
    pauseTimer,
    resumeTimer,
    isRunning,
  };

  return (
    <TimerContext.Provider value={contextValue}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => {
  return useContext(TimerContext);
};
