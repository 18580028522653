import "./styles.scss";
import Button from "@mui/material/Button";
import StarIcon from "@mui/icons-material/Star";

const RectangularCallout = (props) => {
  const { selected, onClickBtn, type = "", score = 0, star = 0 } = props;
  return (
    <div className="callout-outer">
      <div dangerouslySetInnerHTML={{ __html: selected?.content }} />
      <br />
      {/* <p className="inner-content">{selected?.content}</p> */}
      {selected?.btn_label && (
        <Button
          className="btn-trans-n"
          variant="contained"
          size="small"
          onClick={onClickBtn}
          style={{
            float: "right",
            // backgroundColor: "#0164BD",
            padding: "5px 15px",
            borderRadius: 20,
            marginTop: "-20px",
            backgroundColor: "#008080",
          }}
        >
          {selected?.btn_label}
        </Button>
      )}
      {type === "RESULT" && (
        <>
          <label className="score-outer">
            <span className="score">{score}</span>
            {/* <br /> */}
            <span className="score-lab">score</span>
          </label>

          <label className="score-outer">
            <span className="score">
              {star} <StarIcon className="star" />
            </span>
            {/* <br /> */}
            <span className="score-lab" style={{ marginLeft: "-25px" }}>
              star
            </span>
          </label>
        </>
      )}
    </div>
  );
};

export default RectangularCallout;
