import React, { useEffect, useState } from "react";
import "./styles.scss";
import HeadSection from "../../../components/headSection";
import Hint from "../../../components/hint";
import Progress from "../../../components/progress";
import RectangularCallout from "../../../components/rectangularCallout";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Volume from "../../../components/volume";
import api from "../../../apis/api";
import { setLoader } from "../../../store/reducer/ui";
import { useDispatch, useSelector } from "react-redux";
import useDisableBrowserBack from "../../../components/useDisableBrowserBack";
import { useTimer } from "../../../components/timerContext";
import Typo from "typo-js";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  background: "#D9D9D9",
  boxShadow: 24,
  p: 4,
};

const questionEndContent = [
  {
    id: 0,
    content:
      "Good job answering part 1! Now let’s move on to part two of the purpose question: how does erosion change the land?",
    btn_label: "Lets Go",
    type: "next_question",
  },
];

const selectedContent = {
  id: 0,
  content:
    "Good job answering part 1! Now let’s move on to part two of the purpose question: how does erosion change the land?",
  btn_label: "Lets Go",
  type: "next_question",
};

const LevelScreen = (props) => {
  const gistList = window.localStorage.getItem("gistList");
  useDisableBrowserBack();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showLoading = useSelector((state) => state.ui.showLoading);
  const { pauseTimer, resumeTimer } = useTimer();

  const { dynamic_messages } = useSelector((state) => state.appStore);
  const { isAutoCorrect } = useSelector((state) => state.sectionStore);

  const [hintData, setHintData] = useState([
    {
      id: 0,
      content: dynamic_messages?.apq_hint1,
      btn_label: "OK",
      type: "start_answer",
    },
  ]);

  const [hintShow, setHintShow] = useState(true);
  const [step, setStep] = useState(0);
  const [selectedHint, setSelectedHint] = useState({
    id: 0,
    content: dynamic_messages?.apq_hint1,
    btn_label: "OK",
    type: "start_answer",
  });
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    answer: "",
  });
  const [questions, setQuestions] = useState();
  const [customSpeechContent, setCustomSpeechContent] = useState("");
  const [showCustomSpeech, setShowCustomSpeech] = useState(false);
  const [selected, setSelected] = useState();

  const [options, setOptions] = useState([]);

  const [showBothAnswerSection, setShowBothAnswerSection] = useState(false);
  const [answerError, setAnswerError] = useState("");
  const [highlightedAnswer, setHighlightedAnswer] = useState(false);
  const [showFinalScreen, setShowFinalScreen] = useState(false);

  const [audio, setAudio] = useState(null);
  const [apqAPiData, setApqApiData] = useState(null);

  const [typo, setTypo] = useState(null);
  const [modalHeading, setModalHeading] = useState("");
  const [isAnswer, setIsAnswer] = useState(0);
  const [spinner, setSpinner] = useState(true);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name: "APQ Level",
        properties: { username: props.userData?.username || "" },
      });
    }
  }, [appInsights]);

  useEffect(() => {
    const loadDictionary = async () => {
      const affResponse = await fetch("/path-to-dictionary/en_US.aff");
      // const affResponse = await fetch("/cali/path-to-dictionary/en_US.aff");
      const affData = await affResponse.text();

      const dicResponse = await fetch("/path-to-dictionary/en_US.dic");
      // const dicResponse = await fetch("/cali/path-to-dictionary/en_US.dic");
      const dicData = await dicResponse.text();

      const dictionary = new Typo("en_US", affData, dicData, {
        platform: "any",
      });
      setTypo(dictionary);
    };

    loadDictionary();
  }, []);

  useEffect(() => {
    if (showLoading) {
      pauseTimer();
    } else {
      resumeTimer();
    }
  }, [showLoading]);

  const getAPQData = async () => {
    try {
      if (!props?.question?.apq_question) {
        return;
      }
      dispatch(setLoader(true));
      setSpinner(true);
      const response = await api.post(`/apq`, {
        contentID: props.selectedPassage,
        apq_question: props?.question?.apq_question,
        gists: gistList ? JSON.parse(gistList) : [],
      });
      setApqApiData(response?.data);
      let arr = [];
      response?.data?.apq_parts?.map((x, index) => {
        arr.push({
          ...x,
          id: index,
          hint_options: x?.apq_gist_options.map((y) => {
            return {
              ...y,
              className: "",
            };
          }),
        });
      });
      setQuestions(arr);
      setSelected(arr[0]);
      dispatch(setLoader(false));
      setSpinner(false);
    } catch (error) {
      dispatch(setLoader(false));
      setSpinner(false);
    }
  };

  useEffect(() => {
    // let arr = [];
    // props?.answer?.parts?.map((x, index) => {
    //   arr.push({
    //     ...x,
    //     id: index,
    //     hint_options: x?.hint_options.map((y) => {
    //       return {
    //         ...y,
    //         className: "",
    //       };
    //     }),
    //   });
    // });
    // setQuestions(arr);
    // setSelected(arr[0]);
    getAPQData();
  }, [props?.answer]);

  const getPartAnswer = async (val) => {
    try {
      dispatch(setLoader(true));
      let ind = questions.findIndex((x) => x?.apq_part === selected?.apq_part);
      let payload = {
        contentID: props?.selectedPassage,
        sectionID: "APQ_PART",
        task_type: "APQ_PART",
        lineID: ind,
        answer: val,
        try_count: isAnswer,
        time_spent_seconds: 20,
        past_hint: "",
      };
      const response = await api.post("/ai/answer", payload);
      dispatch(setLoader(false));
      return response?.data;
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  const getFullAnswerBackground = async (val) => {
    try {
      let payload = {
        contentID: props?.selectedPassage,
        sectionID: "APQ",
        task_type: "APQ",
        lineID: 0,
        answer: val,
        try_count: 0,
        time_spent_seconds: 20,
        past_hint: "",
      };
      const response = await api.post("/ai/answer", payload);
      return response?.data;
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  // const getAnswer = async (val) => {
  //   try {
  //     dispatch(setLoader(true));
  //     let payload = {
  //       contentID: props?.selectedPassage,
  //       sectionID: "APQ",
  //       task_type: "APQ",
  //       lineID: -1,
  //       answer: val,
  //       try_count: 0,
  //       time_spent_seconds: 20,
  //       past_hint: "",
  //     };
  //     const response = await api.post("/ai/answer", payload);
  //     dispatch(setLoader(false));
  //     return response?.data;
  //   } catch (err) {
  //     dispatch(setLoader(false));
  //     return {};
  //   }
  // };

  const redirectNextStep = () => {
    setIsAnswer(0);
    let ind = questions.findIndex((x) => x?.apq_part === selected?.apq_part);
    // if (ind < questions.length - 1) {
    if (ind === 0) {
      if (!showFinalScreen) {
        setSelected(questions[ind + 1]);
        setStep(0);
        questions[ind].answer = formData?.answer;
        setQuestions(questions);
        setFormData({
          ...formData,
          answer: "",
        });
        setHintShow(false);
        setCustomSpeechContent(
          dynamic_messages?.apq_level_intro1.replace(
            "{APQ_PART2}",
            `<b>${questions[ind + 1]?.apq_part}</b>`
          )
        );
        setShowCustomSpeech(true);
        setShowFinalScreen(true);
        return;
      }
      setCustomSpeechContent("");
      setShowCustomSpeech(false);
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.apq_hint2,
          btn_label: "OK",
          type: "start_answer",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.apq_hint2,
        btn_label: "OK",
        type: "start_answer",
      });
      setHintShow(true);
      setShowFinalScreen(false);

      return;
    } else {
      setStep(0);
      questions[ind].answer = formData?.answer;
      setQuestions(questions);
      setFormData({
        ...formData,
        answer: "",
      });
      setShowBothAnswerSection(true);
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.apq_hint4,
          btn_label: "OK",
          type: "start_answer",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.apq_hint4,
        btn_label: "OK",
        type: "start_answer",
      });
      setHintShow(true);
    }
  };

  const onClickBtn = () => {
    let ind = questionEndContent.findIndex(
      (x) => x?.id === selectedContent?.id
    );
    if (ind < questionEndContent?.length - 1) {
      setSelected(questionEndContent[ind + 1]);
    } else {
      if (questionEndContent[ind].type === "next_question") {
        let ind1 = questions.findIndex(
          (x) => x.apq_part === selected?.apq_part
        );
        if (ind1 < questions.length - 1) {
          setSelected(questions[ind1 + 1]);
          setStep(0);
          questions[ind1].answer = formData?.answer;
          setQuestions(questions);
          setFormData({
            ...formData,
            answer: "",
          });
        } else {
          redirectNextStep();
        }
      }
      setHintShow(false);
    }
  };

  const handleApiError = () => {
    setHintData([
      {
        id: 0,
        content: "Something went wrong, please check your internet connection",
        btn_label: "Ok",
      },
    ]);
    setSelectedHint({
      id: 0,
      content: "Something went wrong, please check your internet connection",
      btn_label: "Ok",
    });
    setHintShow(true);
  };

  const getRandomSuccessAnswer = (from) => {
    let arr = [];
    if (from === "SUCCESS") {
      arr = [
        "Great job understanding the text. Keep it up!",
        "You're doing amazing with these tough readings!",
        "Your hard work on comprehension is paying off!",
        "Fantastic effort on breaking down the passages!",
        "Your progress in reading is impressive!",
        "You're getting really good at finding key details!",
        "Awesome job grasping the main ideas!",
        "You're excelling in understanding these texts!",
        "Great work on understanding each paragraph!",
        "You're really getting the hang of these readings!",
        "Impressive job on analyzing the text!",
        "Your comprehension skills are top-notch!",
      ];
    } else {
      arr = [
        "You worked hard! Let’s use some tips to get through this.",
        "Nice try! Here are some hints to guide you.",
        "Good attempt! Let’s use these hints to understand better.",
        "Well done for trying! Let’s use some scaffolds to assist.",
        "You gave it your best! Now, let’s try some hints.",
        "Awesome effort! Let’s use these tips to help you succeed.",
        "You did your best! Here are some hints to help you along.",
        "Great job trying! Let’s use some scaffolds to make it easier.",
        "You worked hard! These hints will help you understand more",
      ];
    }
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  };

  const checkAnswerValidations = async () => {
    if (showFinalScreen) {
      setCustomSpeechContent("");
      setShowCustomSpeech(false);
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.apq_hint2,
          btn_label: "OK",
          type: "start_answer",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.apq_hint2,
        btn_label: "OK",
        type: "start_answer",
      });
      setHintShow(true);
      setShowFinalScreen(false);
      return;
    }
    if (!formData?.answer) {
      highlightAnswerBox();
      setAnswerError("Please add answer!");
      setHintData([
        {
          id: 0,
          content: "Please add answer!",
          btn_label: "OK",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: "Please add answer!",
        btn_label: "OK",
      });
      setHintShow(true);
      return;
    }
    onFocusTextBox();
    if (step === 0 || step === 0.5) {
      const resAns = await getPartAnswer(formData?.answer);
      if (!resAns) {
        handleApiError();
        return false;
      }
      if (!resAns?.next_action?.repeat) {
        const randomMessage = getRandomSuccessAnswer("SUCCESS");
        setHintData([
          {
            id: 0,
            content: randomMessage,
            btn_label: "OK",
            type: "show_answer",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: randomMessage,
          btn_label: "OK",
          type: "show_answer",
        });
        setHintShow(true);
        return false;
      }
      setIsAnswer(isAnswer + 1);
      if (resAns?.hint_options?.length) {
        let arr = resAns?.hint_options?.map((x, index) => {
          return {
            ...x,
            id: index,
            className: "",
          };
        });

        setOptions(arr);
      }

      const randomMessage = getRandomSuccessAnswer("HINT");
      if (step === 0) {
        setStep(0.5);
        setHintData([
          {
            id: 0,
            content: randomMessage,
            btn_label: "OK",
          },
          {
            id: 1,
            content: "Use this sentence starter to write your answer.",
            btn_label: "OK",
            type: "hint_sentence_starter",
            hint_sentence_starter: resAns?.hint_sentence_starter || "",
          },
        ]);
      } else {
        setStep(1);
        setHintData([
          {
            id: 0,
            content: randomMessage,
            btn_label: "OK",
          },
        ]);
      }

      setSelectedHint({
        id: 0,
        content: randomMessage,
        btn_label: "OK",
      });
      setHintShow(true);
      return false;
    }
    if (step === 2) {
      if (
        selected?.hint_options.filter(
          (x) => x?.className === "container-selected"
        ).length === 0
      ) {
        setHintData([
          {
            id: 0,
            content: "Please select at least one option",
            btn_label: "OK",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: "Please select at least one option",
          btn_label: "OK",
        });
        setHintShow(true);
        return false;
      }
      const correct_count = selected?.hint_options.filter((x) => x?.is_correct);
      const selected_correct_count = selected?.hint_options.filter(
        (x) => x?.is_correct && x?.className === "container-selected"
      );
      const selected_incorrect_count = selected?.hint_options.filter(
        (x) => !x?.is_correct && x?.className === "container-selected"
      );
      if (
        correct_count?.length === selected_correct_count?.length &&
        selected_incorrect_count?.length === 0
      ) {
        return true;
      }
      setHintData([
        {
          id: 0,
          content: "That’s not exactly it, let’s give it another try",
          btn_label: "OK",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: "That’s not exactly it, let’s give it another try",
        btn_label: "OK",
      });
      setHintShow(true);
      setStep(3);
      return false;
    } else if (step === 4) {
      if (
        selected?.hint_options.filter(
          (x) => x?.className === "container-selected"
        ).length === 0
      ) {
        setHintData([
          {
            id: 0,
            content: "Please select at least one option",
            btn_label: "OK",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: "Please select at least one option",
          btn_label: "OK",
        });
        setHintShow(true);
        return false;
      }
      const correct_count = selected?.hint_options.filter((x) => x?.is_correct);
      const selected_correct_count = selected?.hint_options.filter(
        (x) => x?.is_correct && x?.className === "container-selected"
      );
      const selected_incorrect_count = selected?.hint_options.filter(
        (x) => !x?.is_correct && x?.className === "container-selected"
      );
      if (
        correct_count?.length === selected_correct_count?.length &&
        selected_incorrect_count?.length === 0
      ) {
        return true;
      }
      if (!options.length) {
        return true;
      }
      setHintData([
        {
          id: 0,
          content:
            "I’ve chosen the gist statements that will be most helpful for writing an answer and here’s a hint to get you started with your answer!",
          btn_label: "OK",
        },
      ]);
      setSelectedHint({
        id: 0,
        content:
          "I’ve chosen the gist statements that will be most helpful for writing an answer and here’s a hint to get you started with your answer!",
        btn_label: "OK",
      });
      setHintShow(true);
      setStep(5);
      return false;
    }
    if (step === 12) {
      return true;
    }
  };

  const onClickPreview = () => {
    setStep(12);
    if (showBothAnswerSection) {
      setShowBothAnswerSection(false);
      setFormData({
        ...formData,
        answer: selected.apq_part_answer,
      });
    } else {
      let ind = questions.findIndex((x) => x?.apq_part === selected?.apq_part);
      if (ind > 0) {
        setSelected(questions[ind - 1]);
        setFormData({
          ...formData,
          answer: questions[ind - 1].apq_part_answer,
        });
      } else {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
        }
        navigate("/apq/remind");
      }
    }
  };

  const onClickNext = async () => {
    setAnswerError("");
    if (showBothAnswerSection) {
      if (step === 0) {
        if (!formData?.answer) {
          highlightAnswerBox();
          setAnswerError("Please add answer!");
          setHintData([
            {
              id: 0,
              content: "Please add answer!",
              btn_label: "OK",
            },
          ]);
          setSelectedHint({
            id: 0,
            content: "Please add answer!",
            btn_label: "OK",
          });
          setHintShow(true);
          return;
        }
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
        }
        getFullAnswerBackground(formData?.answer);
        navigate("/result");
        return;
        // const resAns = await getAnswer(formData?.answer);
        // if (!resAns) {
        //   handleApiError();
        //   return false;
        // }
        // if (!resAns?.next_action?.repeat) {
        //   navigate("/result");
        // }
        // setHintData([
        //   {
        //     id: 0,
        //     content:
        //       "That’s not quite right, the full answer to the purpose question is ",
        //     btn_label_final_ans: props?.answer?.apq_answer,
        //     btn_label: "OK",
        //     type: "final_answer",
        //   },
        // ]);
        // setSelectedHint({
        //   id: 0,
        //   content:
        //     "That’s not quite right, the full answer to the purpose question is ",
        //   btn_label_final_ans: props?.answer?.apq_answer,
        //   btn_label: "OK",
        //   type: "final_answer",
        // });
        // setHintShow(true);
        // setStep(1);
      } else {
        navigate("/result");
      }
      return true;
    }
    const validRes = await checkAnswerValidations();
    if (validRes) {
      redirectNextStep();
    }

    // navigate("/result");
  };

  const highlightAnswerBox = () => {
    setHighlightedAnswer(true);
    // setTimeout(() => setHighlightedAnswer(false), 8000);
  };

  const onClickHintBtn = () => {
    if (!hintShow) {
      return;
    }
    if (
      selectedHint?.type === "start_answer" ||
      selectedHint?.type === "hint_sentence_starter"
    ) {
      highlightAnswerBox();
    }
    if (selectedHint?.type === "hint_sentence_starter") {
      onChangeInput(selectedHint?.hint_sentence_starter, "answer");
    }
    if (selectedHint?.id < hintData.length - 1) {
      setSelectedHint(hintData[selectedHint?.id + 1]);
    } else if (!showBothAnswerSection && step === 1) {
      let ind = questions.findIndex((x) => x?.apq_part === selected?.apq_part);
      setHintData([
        {
          id: 0,
          content: `Let’s select all of the gist statements relevant to the ${
            ind === 0 ? "first" : "second"
          } part of the answer purpose question.`,
          btn_label: "OK",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: `Let’s select all of the gist statements relevant to the ${
          ind === 0 ? "first" : "second"
        } part of the answer purpose question.`,
        btn_label: "OK",
      });
      setHintShow(true);
      setStep(2);
      return true;
    } else if (step === 3) {
      setStep(4);
      isAnswerCorrectSelected();
      setHintShow(false);
      return;
    } else if (step === 5) {
      let temporaryArray = options.map((x) => {
        return {
          ...x,
          className: "",
        };
      });
      setOptions(temporaryArray);
      let ind = questions.findIndex((x) => x?.apq_part === selected?.apq_part);

      let pop_content = "";
      temporaryArray?.map((x, index) => {
        if (index === 0) {
          pop_content += ` . . '${x?.hint}'. . or . . `;
        } else if (index < temporaryArray?.length - 1) {
          pop_content += ` . . '${x?.hint}'. . or . . `;
        } else {
          pop_content += ` '${x?.hint}'`;
        }
        return true;
      });
      setModalHeading(
        `Which of these two options do you think answers the ${
          ind === 0 ? "first" : "second"
        } part of the purpose question: ${selected?.apq_part || ""}`
      );
      setCustomSpeechContent(
        `Which of these two options do you think answers the ${
          ind === 0 ? "first" : "second"
        } part of the purpose question: ${
          selected?.apq_part || ""
        } ${pop_content}`
      );
      setShowCustomSpeech(true);
      setShowModal(true);
      setHintShow(false);
      setStep(6);
      return true;
    } else if (step === 7) {
      setStep(8);
    } else if (step === 8) {
      redirectNextStep();
    } else if (step === 9) {
    } else if (step === 10) {
    } else if (questions?.length) {
      let ind1 = questions.findIndex((x) => x.apq_part === selected?.apq_part);
      if (selectedHint?.type === "show_answer" && ind1 === 0) {
        redirectNextStep();
        return;
      } else if (selectedHint?.type === "show_answer" && ind1 === 1) {
        redirectNextStep();
        return;
      } else if (selectedHint?.type === "final_answer") {
        navigate("/result");
      }
      setHintShow(false);
    } else {
      setHintShow(false);
    }
  };

  const isAnswerCorrectSelected = () => {
    const newArray = selected?.hint_options.map((item) => {
      return {
        ...item,
        isCorrectAnswer:
          item.className === "container-selected" && item?.is_correct,
        className:
          item.className === "container-selected" && item?.is_correct
            ? "container-selected"
            : item.className === "container-selected"
            ? "container-wrong"
            : "",
      };
    });
    setSelected({
      ...selected,
      hint_options: newArray,
    });
    // setStep(4);
  };

  const onChangeInput = (val, key, type = "String") => {
    val = val.replace("  ", " ");
    if (val === " ") {
      val = "";
    }
    setFormData({
      ...formData,
      [key]: val,
    });
  };

  const handleSelected = (index) => {
    if (step === 0 || step === 0.5 || step === 12) {
      return;
    } else if (step === 11) {
      setHintData([
        {
          id: 0,
          content:
            "That’s not quite right, the full answer to the purpose question is ",
          btn_label_final_ans: apqAPiData?.apq_answer,
          btn_label: "OK",
        },
      ]);
      setSelectedHint({
        id: 0,
        content:
          "That’s not quite right, the full answer to the purpose question is",
        btn_label_final_ans: apqAPiData?.apq_answer,
        btn_label: "OK",
      });
      setHintShow(true);
      setStep(11);
      return true;
    }
    let temporaryArray = selected?.hint_options.slice();
    if (
      selected?.hint_options[index].className === "container-selected" ||
      selected?.hint_options[index].className === "container-wrong"
    ) {
      selected.hint_options[index].className = "";
    } else {
      selected.hint_options[index].className = "container-selected";
    }
    setSelected({
      ...selected,
      hint_options: temporaryArray,
    });
  };

  const handleSelectedOption = (index) => {
    let temporaryArray = options.slice();
    if (options[index].className === "container-selected") {
      options[index].className = "";
    } else {
      options[index].className = "container-selected";
    }
    setOptions(temporaryArray);
  };

  const handleDone = () => {
    if (step === 6) {
      const correct_count = options.filter((x) => x?.is_correct === "true");
      const selected_correct_count = options?.filter(
        (x) => x?.is_correct === "true" && x?.className === "container-selected"
      );

      if (correct_count?.length === selected_correct_count?.length) {
        const randomMessage = getRandomSuccessAnswer("SUCCESS");
        setHintData([
          {
            id: 0,
            content: randomMessage,
            btn_label: "OK",
            type: "show_answer",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: randomMessage,
          btn_label: "OK",
          type: "show_answer",
        });
      } else {
        setHintData([
          {
            id: 0,
            content: "That’s not quite right, here’s the correct answer",
            btn_label1: selected?.apq_part_answer,
            btn_label: "OK",
            type: "show_answer",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: "That’s not quite right, here’s the correct answer",
          btn_label1: selected?.apq_part_answer,
          btn_label: "OK",
          type: "show_answer",
        });
      }
      setHintShow(true);
      setShowCustomSpeech(false);
      setCustomSpeechContent("");
      setShowModal(false);
      setStep(8);
      return true;
    }
    //     else if(step === 8){
    // setStep(9);
    //     }
  };

  const onClickBear = () => {
    setHintShow(true);
  };

  const checkIsDoneValid = () => {
    const selected_count = options.filter(
      (x) => x?.className === "container-selected"
    );
    return selected_count?.length === 0;
  };

  const handleCorrection = (value) => {
    const words = value.split(" ");
    const lastWord = words.pop();

    if (typo && !typo.check(lastWord)) {
      const suggestions = typo.suggest(lastWord);
      const correctedWord = suggestions.length > 0 ? suggestions[0] : lastWord;
      return [...words, correctedWord].join(" ");
    }

    return value;
  };

  const handleCorrectionOnChange = (key) => {
    if (!isAutoCorrect) {
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      [key]: handleCorrection(prevState[key]),
    }));
  };

  const onFocusTextBox = () => {
    setHighlightedAnswer(false);
  };

  if (spinner) {
    return (
      <div className="row dashboard-right-section">
        <div style={{ textAlign: "center" }}></div>
      </div>
    );
  }

  return (
    <>
      <HeadSection
        onClickPreview={onClickPreview}
        onClickNext={onClickNext}
        onClickDone={onClickNext}
        showSubmitBtn={
          !showFinalScreen && step !== 7 && step !== 9 ? true : false
        }
        selectedPassage={props?.selectedPassage}
        setSelectedPassage={props?.setSelectedPassage}
        hintShow={hintShow}
      />
      <Grid
        container
        spacing={2}
        className={`mt-0 main-box-level ${props?.selectedThemeClass}`}
      >
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={8}
          className="inner-box-level"
          style={{ overflowX: "hidden" }}
        >
          {!showFinalScreen &&
            !showBothAnswerSection &&
            step !== 7 &&
            step !== 9 && (
              <>
                <label className={`level-ques`}>{selected?.apq_part}</label>
                {selected?.hint_options.map((item, index) => (
                  <div
                    key={item.id}
                    onClick={() => handleSelected(index)}
                    className={`level-ans-container ${item?.className} ${
                      step === 0 || step === 0.5 || step === 11 || step === 12
                        ? ""
                        : "level-ans-container-select"
                    }`}
                  >
                    <p className={`level-ans`}>{item?.gist_option}</p>
                  </div>
                ))}
                <TextField
                  type="text"
                  multiline
                  placeholder="Answer here"
                  className={`${
                    highlightedAnswer ? "highlighted-textfield" : ""
                  }`}
                  id="Erosion"
                  label=""
                  value={formData?.answer}
                  size="small"
                  fullWidth
                  style={{
                    background: step > 1 ? "#d9d9d9" : "white",
                    pointerEvents: hintShow ? "none" : null,
                  }}
                  disabled={step > 1 || hintShow}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onClickNext();
                    }
                    if (e.key === " ") {
                      handleCorrectionOnChange("answer");
                    }
                  }}
                  onBlur={() => {
                    handleCorrectionOnChange("answer");
                  }}
                  onChange={(e) => {
                    onChangeInput(e.target.value, "answer");
                  }}
                  onFocus={onFocusTextBox}
                  error={answerError ? true : false}
                  variant="outlined"
                  autoComplete="off"
                />
                <Hint
                  show={hintShow}
                  setShow={setHintShow}
                  onClickBtn={onClickHintBtn}
                  selected={selectedHint}
                  onClickBear={onClickBear}
                  showVolume={false}
                />
              </>
            )}

          {!showFinalScreen && !showBothAnswerSection && step === 7 && (
            <>
              <RectangularCallout
                selected={{
                  id: 0,
                  content: dynamic_messages?.apq_level_intro1.replace(
                    "{APQ_PART2}",
                    `<b>${selected?.apq_part}</b>`
                  ),
                  btn_label: "Ok",
                  type: "next_question",
                }}
                type="APQ"
                onClickBtn={onClickBtn}
              />
              <Hint data={[]} showVolume={false} from={"gist-page"} />
            </>
          )}

          {!showFinalScreen && showBothAnswerSection && (
            <>
              <label className={`level-ques`}>
                {props?.question?.apq_question}
              </label>

              <div className={`level-ans-container`}>
                <p className={`level-ans`}>{apqAPiData?.apq_answer}</p>
              </div>
              <TextField
                type="text"
                multiline
                placeholder="Answer here"
                id="Erosion"
                label=""
                className={`${
                  highlightedAnswer ? "highlighted-textfield" : ""
                }`}
                value={formData?.answer}
                size="small"
                fullWidth
                style={{
                  background: step > 1 ? "#d9d9d9" : "white",
                  pointerEvents: hintShow ? "none" : null,
                }}
                disabled={step > 1 || hintShow}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickNext();
                  }
                  if (e.key === " ") {
                    handleCorrectionOnChange("answer");
                  }
                }}
                onBlur={() => {
                  handleCorrectionOnChange("answer");
                }}
                onChange={(e) => {
                  onChangeInput(e.target.value, "answer");
                }}
                onFocus={onFocusTextBox}
                autoComplete="off"
              />
              <Hint
                show={hintShow}
                setShow={setHintShow}
                onClickBtn={onClickHintBtn}
                selected={selectedHint}
                onClickBear={onClickBear}
                showVolume={false}
              />
            </>
          )}

          {showFinalScreen && (
            <>
              <RectangularCallout
                selected={{
                  id: 0,
                  content: dynamic_messages?.apq_level_intro1.replace(
                    "{APQ_PART2}",
                    `<b>${selected?.apq_part}</b>`
                  ),
                  btn_label: "Ok",
                }}
                type="APQ"
                onClickBtn={onClickNext}
              />
              <Hint data={[]} showVolume={false} from={"gist-page"} />
            </>
          )}

          <Progress
            val={6 + props?.totalSections}
            totalSections={props?.totalSections}
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Volume
        content={
          hintShow
            ? selectedHint?.content
            : showCustomSpeech
            ? customSpeechContent
            : ""
        }
        audio={audio}
        setAudio={setAudio}
        onClickBtn={onClickHintBtn}
      />

      {showModal && (
        <Modal
          open={showModal}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="show-hint-modal-apq options-modal" sx={style}>
            <label>{modalHeading}</label>
            {options.map((item, index) => (
              <div
                key={item.id}
                onClick={() => handleSelectedOption(index)}
                className={`level-ans-container ${item.className}`}
                style={{ width: "80%", margin: "10px auto" }}
              >
                <p className={`level-ans`}>{item.hint}</p>
              </div>
            ))}
            <Button
              className="hint-done-btn"
              variant="contained"
              size="small"
              style={{ backgroundColor: "#008080" }}
              onClick={handleDone}
              disabled={checkIsDoneValid()}
            >
              Done
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

// const mapStateToProps = (state) => ({
//   gistList: state.gistStore
// });

// export default connect(mapStateToProps)(LevelScreen);
export default LevelScreen;
