import "./styles.scss";
import Button from "@mui/material/Button";

const MuiButton = ({
  text,
  className = "",
  type = "normal",
  icon = "",
  loading = false,
  onClick
}) => {
  return (
    <Button
      variant="contained"
      size="small"
      className={className}
      endIcon={icon ? icon : null}
      fullWidth
      onClick={onClick}
      style={{backgroundColor:"#008080"}}
    >
      {text}
    </Button>
  );
};

export default MuiButton;
