import React, { useEffect } from "react";
import volumeOnIcon from "../../assets/icons/sound-on.svg";
import volumeOffIcon from "../../assets/icons/sound-off.svg";
import volumeWhiteOnIcon from "../../assets/icons/sound-on-white.svg";
import volumeWhiteOffIcon from "../../assets/icons/sound-off-white.svg";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import api from "../../apis/api";
import { setLoader } from "../../store/reducer/ui";
import { setSpeakingValue } from "../../store/reducer/speaking";
import {
  setAudio,
  setIsHintAudioPlaying,
  setSpeaking,
  setCaptionAudio,
} from "../../store/reducer/hintAudio";
import { setAudio as setParagraphAudio } from "../../store/reducer/paragraphAudio";
import { setPause } from "../../store/actions/appActions";

const Volume = (props) => {
  const {
    content,
    stopAudio = () => {},
    onClickBtn = () => {},
    selected,
  } = props;
  const { audio, speaking, captionAudio } = useSelector(
    (state) => state.hintAudioStore
  );
  const { audio: paragraphAudio } = useSelector(
    (state) => state.paragraphAudioStore
  );
  const { isDarkMode } = useSelector((state) => state.themeStore);
  let spk = localStorage.getItem("speaking");
  const dispatch = useDispatch();
  dispatch(setSpeaking(spk === "true"));

  useEffect(() => {
    stopAudio();
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      dispatch(setAudio(audio));
    }
    if (paragraphAudio) {
      paragraphAudio.currentTime = 0;
      setParagraphAudio(paragraphAudio);
    }
    if (content && speaking) {
      const str = removeHtmlTags(content);
      getSpeech(str);
    }
  }, [content]);

  useEffect(() => {
    if (captionAudio) {
      captionAudio.pause();
      captionAudio.currentTime = 0;
      dispatch(setCaptionAudio(null));
    }
  }, [audio?.currentTime]);

  const stopSpeaking = () => {
    stopAudio();
    dispatch(setIsHintAudioPlaying(false));
    if (speaking) {
      dispatch(setSpeaking(false));
      localStorage.setItem("speaking", false);
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
        dispatch(setAudio(audio));
      }
      // if (paragraphAudio) {
      //   paragraphAudio.currentTime = 0;
      //   setParagraphAudio(paragraphAudio);
      // }
    }
  };

  function removeHtmlTags(input) {
    let text = input.replace(
      /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F1E0}-\u{1F1FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}]/gu,
      ""
    );
    return text.replace(/<[^>]*>/g, "");
  }

  const speakContent = (from = "") => {
    if (speaking && from === "") {
      dispatch(setSpeaking(false));
      localStorage.setItem("speaking", false);
      return;
    }
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    const str = removeHtmlTags(content);
    getSpeech(str);
    dispatch(setSpeaking(true));
    localStorage.setItem("speaking", true);
  };

  const getSpeech = async (text) => {
    try {
      if (!text) {
        return;
      }
      if (captionAudio) {
        captionAudio.pause();
        captionAudio.currentTime = 0;
        dispatch(setCaptionAudio(null));
      }
      dispatch(setLoader(true));
      let payload = {
        text,
      };
      const response = await api.post("/aispeech", payload);
      const audioBlob = base64toBlob(
        response.data.audio.replace("b'", "").replace("'", "")
      );
      const audioBlobUrl = URL.createObjectURL(audioBlob);
      playAudio(audioBlobUrl);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  const base64toBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "audio/mpeg" });
    return blob;
  };

  const playAudio = (audioUrl) => {
    try {
      const audio1 = new Audio(audioUrl);
      audio1.currentTime = 0;
      dispatch(setAudio(audio1));
      if (paragraphAudio) {
        paragraphAudio.pause();
        dispatch(setPause(true));
      }
      dispatch(setSpeakingValue(true));
      setTimeout(() => {
        dispatch(setSpeakingValue(false));
      }, 1000);

      const handleAudioEnd = () => {
        console.log("Audio has finished playing");
        if (audio) {
          audio.currentTime = 0;
          dispatch(setAudio(audio));
        }
        dispatch(setIsHintAudioPlaying(false));
        if (paragraphAudio) {
          paragraphAudio.currentTime = 0;
          setParagraphAudio(paragraphAudio);
        }
        if (selected && selected?.content) {
          if (!selected?.is_disabled_allow && !selected?.is_last_hint) {
            onClickBtn();
          }
        } else {
          onClickBtn();
        }
      };
      dispatch(setIsHintAudioPlaying(true));
      audio1
        .play()
        .catch((error) => console.error("Error playing audio:", error));
      audio1.addEventListener("ended", handleAudioEnd);
    } catch (err) {
      console.error("Error playing audio:", err);
    }
  };

  return (
    <img
      className={`volume-outer ${!speaking ? "flashing-volume-icon" : ""}`}
      id="volume-icon"
      src={
        isDarkMode
          ? speaking
            ? volumeWhiteOnIcon
            : volumeWhiteOffIcon
          : speaking
          ? volumeOnIcon
          : volumeOffIcon
      }
      alt="bear-icon"
      onClick={() => {
        if (speaking) {
          stopSpeaking();
        } else {
          speakContent();
        }
      }}
    />
  );
};

export default Volume;
