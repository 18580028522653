import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRouter from "../components/privateRouter";
import Login from "../pages/login";
import Forgot from "../pages/forgot";
import Universal from "../pages/universal";
import { useSelector } from "react-redux";

const MainRoutes = () => {
  const { isDarkMode, textSize, isTextBold, buttonSize } = useSelector((state) => state.themeStore);

  useEffect(() => {
    const loadStylesheet = (href) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;
      // link.href = "/cali/" + href;
      link.id = "theme-stylesheet";
      document.head.appendChild(link);
    };

    const removeCurrentStylesheet = () => {
      const existingLink = document.getElementById("theme-stylesheet");
      if (existingLink) {
        existingLink.parentNode.removeChild(existingLink);
      }
    };

    removeCurrentStylesheet();

    if (!isDarkMode) {
      loadStylesheet("/css/light.css");
    } else {
      loadStylesheet("/css/dark.css");
    }

    // Cleanup function to remove stylesheet on component unmount
    return () => {
      removeCurrentStylesheet();
    };
  }, [isDarkMode]);

  useEffect(() => {
    const loadStylesheet = (href) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;
      // link.href = "/cali/" + href;
      link.id = "text-size-stylesheet";
      document.head.appendChild(link);
    };

    const removeCurrentStylesheet = () => {
      const existingLink = document.getElementById("text-size-stylesheet");
      if (existingLink) {
        existingLink.parentNode.removeChild(existingLink);
      }
    };

    removeCurrentStylesheet();

    if (textSize === "small") {
      loadStylesheet("/css/small-text-size.css");
    } else if(textSize === "large") {
      loadStylesheet("/css/large-text-size.css");
    }

    return () => {
      removeCurrentStylesheet();
    };
  }, [textSize]);

  useEffect(() => {
    const loadStylesheet = (href) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;
      // link.href = "/cali/" + href;
      link.id = "text-bold-stylesheet";
      document.head.appendChild(link);
    };

    const removeCurrentStylesheet = () => {
      const existingLink = document.getElementById("text-bold-stylesheet");
      if (existingLink) {
        existingLink.parentNode.removeChild(existingLink);
      }
    };

    removeCurrentStylesheet();

    if (isTextBold) {
      loadStylesheet("/css/bold-text.css");
    }

    return () => {
      removeCurrentStylesheet();
    };
  }, [isTextBold]);

  useEffect(() => {
    const loadStylesheet = (href) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;
      // link.href = "/cali/" + href;
      link.id = "btn-size-stylesheet";
      document.head.appendChild(link);
    };

    const removeCurrentStylesheet = () => {
      const existingLink = document.getElementById("btn-size-stylesheet");
      if (existingLink) {
        existingLink.parentNode.removeChild(existingLink);
      }
    };

    removeCurrentStylesheet();

    if (buttonSize === "small") {
      loadStylesheet("/css/small-btn-size.css");
    } else if(buttonSize === "large") {
      loadStylesheet("/css/large-btn-size.css");
    } else if(buttonSize === "medium"){
      loadStylesheet("/css/medium-btn-size.css");
    }

    return () => {
      removeCurrentStylesheet();
    };
  }, [buttonSize]);

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="/" element={<PrivateRouter children={<Universal />} />} />
        <Route path="*" element={<PrivateRouter children={<Universal />} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
