import React from "react";
import { Box, Typography, styled, Switch, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsDarkMode,
  setIsTextBold,
  setTextSize,
  setButtonSize,
} from "../../../store/reducer/themeReducer";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FormatSizeOutlinedIcon from "@mui/icons-material/FormatSizeOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#008080",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const AccessibilitySetting = (props) => {
  const dispatch = useDispatch();
  const { isDarkMode, isTextBold, textSize, buttonSize } = useSelector(
    (state) => state.themeStore
  );

  // Handler for dark mode toggle
  const handleDarkModeToggle = () => {
    dispatch(setIsDarkMode(!isDarkMode));
  };

  const handleTextSizeToggle = (val) => {
    dispatch(setTextSize(val));
  };

  const handleButtonSizeToggle = (val) => {
    dispatch(setButtonSize(val));
  };

  return (
    <>
      <Box p={2}>
        <Typography variant="body1" gutterBottom>
          Choose Theme
        </Typography>
        {/* Light/Dark Mode Options */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {/* Light Mode Option */}
          <Box
            className={`mode-toggle-left ${
              isDarkMode ? "" : "active-mode-left"
            }`}
            onClick={() => handleDarkModeToggle()}
          >
            <WbSunnyOutlinedIcon
              sx={{
                fontSize: 20,
                marginRight: "5px",
              }}
            />
            <Typography variant="caption">Light Mode</Typography>
          </Box>

          {/* Dark Mode Option */}
          <Box
            className={`mode-toggle-right ${
              isDarkMode ? "active-mode-right" : ""
            }`}
            onClick={() => handleDarkModeToggle()}
          >
            <DarkModeOutlinedIcon
              sx={{
                fontSize: 20,
                marginRight: "5px",
              }}
            />
            <Typography variant="caption">Dark Mode</Typography>
          </Box>
        </Box>
      </Box>

      <Box p={2}>
        <Typography variant="body1" gutterBottom>
          Text Bold(Weight)
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          className="loader-toggle"
        >
          <AntSwitch
            checked={isTextBold}
            onChange={(e) => {
              dispatch(setIsTextBold(e.target.checked));
            }}
            inputProps={{ "aria-label": "ant design" }}
          />
        </Stack>
      </Box>

      <Box p={2}>
        <Typography variant="body1" gutterBottom>
          Text Size
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box
            className={`text-toggle-left ${
              textSize === "small" ? "active-text-left" : ""
            }`}
            onClick={() => handleTextSizeToggle("small")}
          >
            <FormatSizeOutlinedIcon
              sx={{
                fontSize: 20,
                marginRight: "5px",
              }}
            />
            <Typography variant="caption">Small</Typography>
          </Box>

          <Box
            className={`text-toggle-center ${
              textSize === "medium"
                ? "active-text-center"
                : textSize === "small"
                ? "right-border"
                : "left-border"
            }`}
            onClick={() => handleTextSizeToggle("medium")}
          >
            <FormatSizeOutlinedIcon
              sx={{
                fontSize: 20,
                marginRight: "5px",
              }}
            />
            <Typography variant="caption">Medium</Typography>
          </Box>

          <Box
            className={`text-toggle-right ${
              textSize === "large" ? "active-text-right" : ""
            }`}
            onClick={() => handleTextSizeToggle("large")}
          >
            <FormatSizeOutlinedIcon
              sx={{
                fontSize: 20,
                marginRight: "5px",
              }}
            />
            <Typography variant="caption">Large</Typography>
          </Box>
        </Box>
      </Box>

      <Box p={2}>
        <Typography variant="body1" gutterBottom>
          Button Size
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box
            className={`text-toggle-left ${
              buttonSize === "small" ? "active-text-left" : ""
            }`}
            onClick={() => handleButtonSizeToggle("small")}
          >
            <AspectRatioOutlinedIcon
              sx={{
                fontSize: 20,
                marginRight: "5px",
              }}
            />
            <Typography variant="caption">Small</Typography>
          </Box>

          <Box
            className={`text-toggle-center ${
              buttonSize === "medium"
                ? "active-text-center"
                : buttonSize === "small"
                ? "right-border"
                : "left-border"
            }`}
            onClick={() => handleButtonSizeToggle("medium")}
          >
            <AspectRatioOutlinedIcon
              sx={{
                fontSize: 20,
                marginRight: "5px",
              }}
            />
            <Typography variant="caption">Medium</Typography>
          </Box>

          <Box
            className={`text-toggle-right ${
              buttonSize === "large" ? "active-text-right" : ""
            }`}
            onClick={() => handleButtonSizeToggle("large")}
          >
            <AspectRatioOutlinedIcon
              sx={{
                fontSize: 20,
                marginRight: "5px",
              }}
            />
            <Typography variant="caption">Large</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AccessibilitySetting;
