import { createAsyncThunk } from "@reduxjs/toolkit";

import auth from "../../../apis/auth";
import authEndpoint from "../../../apis/auth/config";

export const loginUser = createAsyncThunk(
  `${authEndpoint.loginUser}Post`,
  async (payload) => {
    // return {
    //   status: 200,
    //   data: {
    //     accessToken:
    //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiUm9oaX",
    //     status: true,
    //     user_data: {
    //       name: "Teja Ram",
    //       email: "taranzansihag1400@gmai.com",
    //       mobile_no: "919636631400",
    //     },
    //   },
    // };
    const { status, data } = await auth.loginUser(payload);
    return { status, data };
  }
);

export const forgotPassword = createAsyncThunk(
  `${authEndpoint.loginUser}Post`,
  async (payload) => {
    const { status, data } = await auth.forgotPassword(payload);
    return { status, data };
  }
);

// export const logoutUser = createAsyncThunk(
//   `${authEndpoint.logoutUser}Get`,
//   async () => {
//     const { status, data } = await auth.logoutUser();
//     return { status, data };
//   }
// );
