import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import api from "../../../../apis/api";
import { useDispatch, useSelector } from "react-redux";
import { setPause } from "../../../../store/actions/appActions";
import { setLoader } from "../../../../store/reducer/ui";
import { setAudio } from "../../../../store/reducer/paragraphAudio";
import { setCaptionAudio } from "../../../../store/reducer/hintAudio";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import noImage from "../../../../assets/images/no-image.svg";

const maxLoadingTime = 10;

const ProgressChip = styled(Chip)(({ theme, progress }) => ({
  background: `linear-gradient(90deg, teal, teal ${progress}%, #30cfcf ${progress}%, teal 100%)`,
  "& .MuiChip-label": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const Detail = (props) => {
  const {
    selected,
    formData,
    onChangeInput,
    handleCorrectionOnChange,
    selectedType,
    onKeyDownHandler,
    gistError,
    highlightGist,
    sectionImages,
    hintShow,
    currentMode,
    setIsPlaying,
    speaking,
    setSpeaking,
    highlightSubmitButton,
    answers_and_clues,
    selectedHint,
    onCompleteParagraphReading,
    handleIDoStart,
    onFocusTextBox,
  } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { audio } = useSelector((state) => state.paragraphAudioStore);
  const {
    audio: audioHint,
    captionAudio,
    speaking: speakingVolume,
  } = useSelector((state) => state.hintAudioStore);
  const speakingVolumeRef = useRef(speakingVolume);

  const { I_do_json, isIDoEnable } = useSelector((state) => state.sectionStore);
  const { showLoading } = useSelector((state) => state.ui);
  const { readImageCaption } = useSelector((state) => state.themeStore);

  const [selectedJson, setSelectedJson] = useState({});
  const [highlightedVariousPlay, setHighlightedVariousPlay] = useState(false);

  const [spinner, setSpinner] = useState(false);
  const [seconds, setSeconds] = useState(maxLoadingTime);
  const [progress, setProgress] = useState(0);

  const variousRef = useRef(null);
  const captionRef = useRef(false);

  useEffect(() => {
    if (currentMode === "I_DO" && isIDoEnable) {
      highlightVariousButton();
    } else if (currentMode === "I_DO" && !isIDoEnable && variousRef?.current) {
      audio.pause();
      audio.currentTime = 0;
      variousRef.current = false;
    }
  }, [isIDoEnable]);

  useEffect(() => {
    if (selectedHint?.highlight_type === "lesion_btn") {
      highlightVariousButton();
    }
  }, [selectedHint]);

  useEffect(() => {
    setSelectedJson(
      I_do_json?.talk_tracks && I_do_json?.talk_tracks.length
        ? I_do_json.talk_tracks[0]
        : {}
    );
  }, [I_do_json]);

  useEffect(() => {
    speakingVolumeRef.current = speakingVolume;
    if (variousRef?.current && audio && audio?.currentTime > 0) {
      audio.volume = speakingVolume ? 1 : 0;
      dispatch(setAudio(audio));
    }
  }, [speakingVolume]);

  useEffect(() => {
    if (
      !hintShow &&
      selectedHint?.type === "i_do_mode_content" &&
      !speakingVolume &&
      currentMode === "I_DO" &&
      isIDoEnable
    ) {
      audio.pause();
      audio.currentTime = 0;
      variousRef.current = false;
      redirectNextVarious();
    }
  }, [hintShow]);

  const renderContent = (content) => {
    let html = content.replaceAll(";", ";</br>");
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const redirectNextVarious = () => {
    variousRef.current = false;
    let talk_tracks =  I_do_json.talk_tracks.filter(x => x.talk_track);
    let ind = talk_tracks.findIndex(
      (x) => x?.talk_track === selectedJson?.talk_track
    );
    if (ind < talk_tracks.length - 1) {
      setSelectedJson(talk_tracks[ind + 1]);
      highlightVariousButton();
      onCompleteParagraphReading("END");
    } else if (ind === talk_tracks.length - 1) {
      const sectionInd = answers_and_clues.findIndex(
        (x) => x.section_id === selected?.id
      );
      if (sectionInd > -1) {
        onChangeInput(
          I_do_json?.gist_statement
            ? I_do_json?.gist_statement
            : answers_and_clues[sectionInd].gist_statement?.answer,
          "GIST"
        );
        highlightSubmitButton();
      }
    }
  };

  const stopAudio = () => {
    setIsPlaying(false);
    setSpeaking(false);
    dispatch(setPause(false));
    dispatch(setCaptionAudio(null));
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      dispatch(setAudio(audio));
    }
    if (
      currentMode === "I_DO" &&
      variousRef?.current &&
      I_do_json?.talk_tracks &&
      speakingVolumeRef.current
    ) {
      redirectNextVarious();
    }
  };

  // online speech
  const getSpeech = async (text) => {
    try {
      setHighlightedVariousPlay(false);
      if (!text) {
        return;
      }
      dispatch(setLoader(true));
      let payload = {
        text,
      };
      const response = await api.post("/aispeech", payload);
      const audioBlob = base64toBlob(
        response.data.audio.replace("b'", "").replace("'", "")
      );
      const audioBlobUrl = URL.createObjectURL(audioBlob);
      playAudio(audioBlobUrl);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  const base64toBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "audio/mpeg" });
    return blob;
  };

  const playAudio = (audioUrl) => {
    const audio1 = new Audio(audioUrl);
    if (
      currentMode === "I_DO" &&
      isIDoEnable &&
      variousRef?.current &&
      !speakingVolume
    ) {
      audio1.volume = 0;
    }
    // captionRef.current = audio1;
    dispatch(setAudio(audio1));
    if (audioHint) {
      audioHint.pause();
    }
    audio1
      .play()
      .catch((error) => console.error("Error playing audio:", error));
    audio1.onended = function () {
      dispatch(setCaptionAudio(null));
      stopAudio();
    };
  };

  const playIDoVarious = () => {
    setSpinner(false);
    if (speaking) {
      setIsPlaying(false);
      setSpeaking(false);
      dispatch(setPause(false));
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    }
    if (audio && audio?.currentTime > 0) {
      audio.pause();
      audio.currentTime = 0;
    }
    if (audioHint && audioHint?.currentTime > 0) {
      audioHint.pause();
      audioHint.currentTime = 0;
    }
    let content = "";
    if (currentMode === "I_DO" && selectedJson?.talk_track) {
      content = selectedJson?.talk_track;
    }
    if (content) {
      variousRef.current = true;
      handleIDoStart(content);
      getSpeech(content);
    }
  };

  const handlePopup = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let timer;
    if (spinner && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setProgress((prevProgress) => prevProgress + maxLoadingTime);
      }, 1000);
    } else if (seconds === 0) {
      setSpinner(false);
      setSeconds(maxLoadingTime);
      setProgress(0);
      if (highlightedVariousPlay && isIDoEnable) {
        playIDoVarious();
        return;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [spinner, seconds]);

  const highlightVariousButton = () => {
    setHighlightedVariousPlay(true);
    setSeconds(maxLoadingTime);
    setProgress(0);
    setSpinner(true);
    // setTimeout(() => setHighlightedVariousPlay(false), 8000);
  };

  useEffect(() => {
    if (captionAudio && captionAudio.currentTime > 0) {
      captionAudio.pause();
      captionAudio.currentTime = 0;
      dispatch(setCaptionAudio(null));
    }
  }, [audio, audioHint]);

  const onHoverImage = async (text) => {
    try {
      if (
        !text ||
        captionAudio ||
        showLoading ||
        !readImageCaption ||
        hintShow ||
        captionRef.current
      ) {
        return;
      }
      if (captionAudio) {
        captionAudio.pause();
        captionAudio.currentTime = 0;
      }
      if (audioHint && audioHint?.currentTime > 0) {
        return;
      }
      if (audio && audio?.currentTime > 0) {
        return;
      }
      captionRef.current = true;
      setTimeout(() => {
        captionRef.current = false;
      }, 2000);
      dispatch(setLoader(true));
      let payload = {
        text,
      };
      const response = await api.post("/aispeech", payload);
      const audioBlob = base64toBlob(
        response.data.audio.replace("b'", "").replace("'", "")
      );
      const audioBlobUrl = URL.createObjectURL(audioBlob);
      const audio1 = new Audio(audioBlobUrl);
      dispatch(setCaptionAudio(audio1));
      audio1
        .play()
        .catch((error) => console.error("Error playing audio:", error));
      audio1.onended = function () {
        dispatch(setCaptionAudio(null));
      };
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  return (
    <Grid container spacing={2} className="mt-4">
      <Grid
        container
        style={{ maxHeight: "45vh", overflow: "auto", padding: "0 15px" }}
      >
        {sectionImages?.length > 0 && (
          <>
            <Grid item xs={4}>
              <Grid>
                {sectionImages?.map((img) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handlePopup();
                        setSelectedImage(img);
                      }}
                    >
                      <img
                        src={img?.url}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = noImage;
                        }}
                        onMouseEnter={() => {
                          onHoverImage(img.caption);
                        }}
                        width="100%"
                        alt="passage"
                      />
                      <br />
                      <p style={{ fontSize: "14px" }}>{img.caption}</p>
                    </div>
                  );
                })}
              </Grid>
              <Grid></Grid>
            </Grid>
            <br />
          </>
        )}

        <Grid
          item
          xs={sectionImages?.length > 0 ? 8 : 10}
          style={{ paddingLeft: sectionImages?.length > 0 ? "4rem" : "1rem" }}
        >
          <label>
            <b>Who/What</b>
          </label>
          <div className="item-list" style={{ marginTop: "0" }}>
            <p style={{ margin: 0 }}>{formData?.WHO_OR_WHAT}</p>
          </div>
          <label style={{ marginTop: "15px" }}>
            <b>Most Important Information</b>
          </label>
          <div className="item-list" style={{ marginTop: "0" }}>
            <p style={{ margin: 0 }}>
              {renderContent(formData?.MOST_IMPORTANT_INFO)}
            </p>
          </div>
          {currentMode === "I_DO" &&
            isIDoEnable &&
            I_do_json?.talk_tracks &&
            (!spinner ? (
              <Tooltip title="Read Lesson" placement="top" arrow>
                <>
                  <label
                    className={`${
                      highlightedVariousPlay
                        ? "active-speech-btn highlighted-play"
                        : "active-speech-btn"
                    }`}
                    onClick={() => {
                      // if (!variousRef.current) {
                      playIDoVarious();
                      // }
                    }}
                    style={{
                      float: "left",
                      paddingRight: "6px",
                      marginLeft: 0,
                    }}
                  >
                    Show Me &nbsp;
                    <SmartDisplayIcon />
                  </label>
                </>
              </Tooltip>
            ) : (
              <div
                disabled={true}
                variant="contained"
                style={{
                  position: "relative",
                  marginTop: "15px",
                }}
              >
                <Box>
                  <ProgressChip
                    label="Show Me"
                    deleteIcon={<SmartDisplayIcon />}
                    onDelete={() => {}}
                    color="primary"
                    style={{
                      fontSize: "1.2rem",
                      padding: "10px",
                      border: "1px solid gray",
                      cursor: "pointer",
                    }}
                    progress={progress}
                    component="span"
                    className={`${
                      highlightedVariousPlay ? "highlighted-btn" : ""
                    }`}
                    onClick={() => {
                      if (!variousRef.current) {
                        playIDoVarious();
                      }
                    }}
                  />
                </Box>
              </div>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid>
          <label>
            <b>Gist Statement</b>
          </label>
          <TextField
            type="text"
            multiline
            placeholder="what's the gist?"
            className={`${highlightGist ? "highlighted-textfield" : ""}`}
            id="Erosion"
            label=""
            value={formData?.GIST}
            size="small"
            fullWidth
            // style={{ background: "#d9d9d9" }}
            style={{
              background: selectedType === "GIST" ? "white" : "#d9d9d9",
              pointerEvents: hintShow ? "none" : null,
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            disabled={
              (currentMode === "I_DO" && isIDoEnable) ||
              selectedType !== "GIST" ||
              hintShow
            }
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              onChangeInput(e.target.value, "GIST");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onKeyDownHandler();
              if (e.key === " ") handleCorrectionOnChange("GIST");
            }}
            onBlur={() => {
              handleCorrectionOnChange("GIST");
            }}
            onFocus={onFocusTextBox}
            error={gistError ? true : false}
            autoComplete="off"
          />
        </Grid>
      </Grid>
      <Modal
        open={open}
        // onClose={handlePopup}
        aria-labelledby="modal-modal-title options-modal"
        aria-describedby="modal-modal-description"
      >
        <Box className="show-image-detail-modal" sx={style}>
          <img
            src={selectedImage?.url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = noImage;
            }}
            alt="who what"
            className="who-what-img"
            style={{ maxHeight: "350px", maxWidth: "100%" }}
          />
          <br />
          <span className="img-modal-caption">{selectedImage?.caption}</span>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#008080",
              float: "right",
              marginTop: "10px",
            }}
            onClick={() => {
              handlePopup();
            }}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
};

export default Detail;
