import React, { useRef, useEffect } from 'react';
import blubIcon from "../../assets/icons/blub.svg";
import "./styles.scss";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

const Task = (props) => {
  const { show = false, selected, onClickBtn, onClickIcon } = props;
  const { isHintAudioPlaying, speaking } = useSelector(
    (state) => state.hintAudioStore
  );

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (show && selected?.type !== "i_do_mode_content" && popupRef.current && !popupRef.current.contains(event.target)) {
        onClickBtn();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef, show, selected]);

  useEffect(() => {
    if (!isHintAudioPlaying && show) {
      onClickBtn();
    }    
  }, [isHintAudioPlaying]);

  return (
    <div ref={popupRef}>
      <span className="task-outer">
        <img
          src={blubIcon}
          alt="task-icon"
          onClick={() => {
            onClickIcon();
          }}
        />
      </span>

      {show && (
        <div className="task-callout-outer">
          <p className="inner-content">{selected?.content}</p>
          {selected?.btn_label && (
            <Button
              className="btn-trans-n"
              variant="contained"
              size="small"
              onClick={onClickBtn}
              style={{ float: "right", borderRadius: 20, marginTop: "-30px", backgroundColor: "#008080" }}

            >
              {selected?.btn_label}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Task;
