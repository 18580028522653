import React, { useEffect, useState } from "react";
import "./styles.scss";
import HeadSection from "../../components/headSection";
import Hint from "../../components/hint";
import Task from "../../components/task";
import Volume from "../../components/volume";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { encryptData, decryptData } from "../../utils/crypto";
import { useTimer } from "../../components/timerContext";
import WhoWhatScreen from "./whoWhat";
import GistScreen from "./gist";
import SentenceScreen from "./sentence";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import RectangularCallout from "../../components/rectangularCallout";
import Grid from "@mui/material/Grid";
import Progress from "../../components/progress";
import api from "../../apis/api";
import { setLoader } from "../../store/reducer/ui";
import { useDispatch, useSelector } from "react-redux";
import useDisableBrowserBack from "../../components/useDisableBrowserBack";
import { setIDoJson, setIsIDoEnable } from "../../store/reducer/sectionReducer";
import Typo from "typo-js";
import useInactivityTimer from "../../components/useInactivityTimer";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SectionScreen = (props) => {
  useDisableBrowserBack();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showLoading = useSelector((state) => state.ui.showLoading);
  const [selected, setSelected] = useState();
  const [selectedSentence, setSelectedSentence] = useState();
  const [show, setShow] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [hintCount, setHintCount] = useState(0);
  const [selectedTask, setSelectedTask] = useState(null);

  const [hintData, setHintData] = useState([]);
  const [hintShow, setHintShow] = useState(true);
  const [data, setData] = useState(null);
  const [selectedHint, setSelectedHint] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedTypeSentence, setSelectedTypeSentence] =
    useState("WHO_OR_WHAT");
  const [formData, setFormData] = useState({
    WHO_OR_WHAT: "",
    MOST_IMPORTANT_INFO: "",
    GIST: "",
  });

  const [formDataSentence, setFormDataSentence] = useState([]);
  const { startTimer, isRunning, pauseTimer, resumeTimer } = useTimer();

  const [isAnswer, setIsAnswer] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [showHintWhoWhatPopup, setShowHintWhoWhatPopup] = useState(false);
  const [showHintGistPopup, setShowHintGistPopup] = useState(false);
  const [showHint3Popup, setShowHintImportantPopup] = useState(false);
  const [sentenceOverview, setSentenceOverview] = useState("sentence-intro");

  const [showRectangle, setShowRectangle] = useState(false);
  const [rectangleContent, setRectangleContent] = useState("");

  const [options, setOptions] = useState([
    {
      id: 0,
      content: "Causes of erosion are water, wind, ice",
      className: "",
    },
    {
      id: 1,
      content: "Weathering is breaking down rock",
      className: "",
    },
    {
      id: 2,
      content: "Erosion changes land",
      className: "",
    },
    {
      id: 3,
      content: "Rocks can resist erosion",
      className: "",
    },
  ]);

  const [whoWhatError, setWhoWhatError] = useState("");
  const [mostImportantInfoError, setMostImportantInfoError] = useState("");
  const [gistError, setGistError] = useState("");

  const [highlightedWhoWhat, setHighlightedWhoWhat] = useState(false);
  const [highlightMostImportant, setHighlightedMostImportant] = useState(false);
  const [highlightGist, setHighlightedGist] = useState(false);
  const [highlightedSubmitButton, setHighlightedSubmitButton] = useState(false);

  const [customSpeechContent, setCustomSpeechContent] = useState("");
  const [showCustomSpeech, setShowCustomSpeech] = useState(false);

  const [showMostImpInfo, setShowMostImpInfo] = useState(false);
  const [sectionGistType, setSectionGistType] = useState("intro");
  const [highlightWord, setHighlightWord] = useState("");
  const [circleWord, setCircleWord] = useState("");
  const [underlineWord, setUnderlineWord] = useState([]);
  const [usedHintSection, setUsedHintSection] = useState({
    WHO_OR_WHAT: 0,
    MOST_IMPORTANT_INFO: 0,
    GIST: 0,
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const [selectedSentenceArr, setSelectedSentenceArr] = useState([]);
  const [showParagraphHighlightAndCircle, setShowParagraphHighlightAndCircle] =
    useState(true);
  const [showUnderline, setShowUnderline] = useState(false);
  const [currentMode, setCurrentMode] = useState("I_DO");
  const [showFirstFinalScreen, setShowFirstFinalScreen] = useState(false);

  const [audio, setAudio] = useState(null);

  const [typo, setTypo] = useState(null);
  const [previousAnswerHint, setPreviousAnswerHint] = useState([]);

  const { isIDoEnable } = useSelector((state) => state.sectionStore);
  const { dynamic_messages } = useSelector((state) => state.appStore);
  const { isAutoCorrect } = useSelector((state) => state.sectionStore);
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name: "Section",
        properties: {
          username: props.userData?.username || "",
          contentID: props?.selectedPassage,
          sectionID: selected?.id,
          task_type: selectedType,
          try_count:
            currentMode === "WE_DO" && isAnswer > 0 ? isAnswer - 1 : isAnswer,
          mode: getModeForAnswerAPI(),
        },
      });
    }
  }, [appInsights, selectedType, selected, isAnswer, props?.selectedPassage]);

  const handleInactivity = async () => {
    try {
      if(hintShow){
        return;
      }
      // if (!show) {
      dispatch(setLoader(true));
      let payload = {
        contentID: props?.selectedPassage,
        sectionID: selected?.id,
        lineID: -1,
        task_type:
          selectedType === "sentence"
            ? selectedTypeSentence
            : selectedType === ""
            ? "WHO_OR_WHAT"
            : selectedType,
        current_answer:
          selectedType === "WHO_OR_WHAT"
            ? formData?.WHO_OR_WHAT
            : selectedType === "MOST_IMPORTANT_INFO"
            ? formData?.MOST_IMPORTANT_INFO
            : selectedType === "GIST"
            ? formData?.GIST
            : "",
        hint_count: hintCount,
        time_spent_seconds: 20,
        past_hint: previousAnswerHint,
        mode: getModeForAnswerAPI(),
      };
      const response = await api.post("/ai/hint", payload);
      dispatch(setLoader(false));
      setTaskData([
        {
          id: 0,
          content: response?.data?.hint_text,
          btn_label: "Ok",
        },
      ]);
      setSelectedTask({
        id: 0,
        content: response?.data?.hint_text,
        btn_label: "Ok",
      });
      setShow(true);
      // }
    } catch (err) {
      dispatch(setLoader(false));
    }
  };

  // useInactivityTimer(handleInactivity, 15);

  useEffect(() => {
    dispatch(setIsIDoEnable(currentMode === "I_DO" ? true : false));
  }, [currentMode]);

  useEffect(() => {
    setPreviousAnswerHint([]);
    if (props?.id_do_mode.length) {
      let ind = -1;
      if (selectedType === "WHO_OR_WHAT") {
        ind = props?.id_do_mode?.findIndex((x) => x.gs_type === "who_what");
      } else if (selectedType === "MOST_IMPORTANT_INFO") {
        ind = props?.id_do_mode?.findIndex(
          (x) => x.gs_type === "most_important_info"
        );
      } else if (selectedType === "GIST") {
        ind = props?.id_do_mode?.findIndex(
          (x) => x.gs_type === "gist_statement"
        );
      }
      if (ind > -1) {
        dispatch(setIDoJson(props?.id_do_mode[ind]));
        if (!props?.id_do_mode[ind]?.talk_tracks) {
          dispatch(setIsIDoEnable(false));
        }
      }
    }
  }, [selectedType]);

  useEffect(() => {
    const loadDictionary = async () => {
      const affResponse = await fetch("/path-to-dictionary/en_US.aff");
      // const affResponse = await fetch("/cali/path-to-dictionary/en_US.aff");
      const affData = await affResponse.text();

      const dicResponse = await fetch("/path-to-dictionary/en_US.dic");
      // const dicResponse = await fetch("/cali/path-to-dictionary/en_US.dic");
      const dicData = await dicResponse.text();

      const dictionary = new Typo("en_US", affData, dicData, {
        platform: "any",
      });
      setTypo(dictionary);
    };

    loadDictionary();
  }, []);

  useEffect(() => {
    window.localStorage.setItem("gistList", JSON.stringify([]));
  }, []);

  useEffect(() => {
    if (showLoading) {
      pauseTimer();
    } else {
      resumeTimer();
    }
  }, [showLoading]);

  useEffect(() => {
    setData(props?.data?.content);
    setSelected(props?.data?.content?.body[0]);
    let clues = props?.data?.answers_and_clues?.content_answers?.gists.find(
      (x) => x.section_id === props?.data?.content?.body[0]?.id
    );
    setHighlightWord(clues?.section_level?.gists_clues?.highlight_word || "");
    setCircleWord(clues?.section_level?.gists_clues?.circle_word || "");
    setUnderlineWord(
      clues?.section_level?.gists_clues?.underscore_phrases || []
    );
    if (currentMode === "I_DO" && isIDoEnable) {
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.section_hint1,
          btn_label: "Ok",
          type: "start_who_what",
          highlight_type: "play_btn",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.section_hint1,
        btn_label: "Ok",
        type: "start_who_what",
        highlight_type: "play_btn",
      });
      setSelectedType("WHO_OR_WHAT");
      setHintShow(true);
    } else {
      setHintData([
        {
          id: 0,
          content: `I highlighted the word that appears most often in the paragraph---that’s <b>${clues?.section_level?.gists_clues?.highlight_word}!</b><br/><br/> I also drew red circles on the word that appears the second most often in the paragraph--that’s <b>${clues?.section_level?.gists_clues?.circle_word}</b>!`,
          btn_label: "Ok",
          type: "start",
          highlight_type: "who_what_section",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: `I highlighted the word that appears most often in the paragraph---that’s <b>${clues?.section_level?.gists_clues?.highlight_word}!</b><br/><br/> I also drew red circles on the word that appears the second most often in the paragraph--that’s <b>${clues?.section_level?.gists_clues?.circle_word}</b>!`,
        btn_label: "Ok",
        type: "start",
        highlight_type: "who_what_section",
      });
      setHintShow(true);
    }
    getStarted();
  }, [props?.data]);

  useEffect(() => {
    if (
      !showHintWhoWhatPopup &&
      isAnswer > 1 &&
      (selectedType === "WHO_OR_WHAT" || selectedTypeSentence === "WHO_OR_WHAT")
    ) {
      onClickNext();
    }
  }, [showHintWhoWhatPopup]);

  useEffect(() => {
    if (
      !showHint3Popup &&
      isAnswer > 1 &&
      (selectedType === "MOST_IMPORTANT_INFO" ||
        selectedTypeSentence === "MOST_IMPORTANT_INFO")
    ) {
      onClickNext();
    }
  }, [showHint3Popup]);

  useEffect(() => {
    if (
      !showHintGistPopup &&
      isAnswer > 1 &&
      (selectedType === "GIST" || selectedTypeSentence === "GIST")
    ) {
      setIsAnswer(isAnswer + 1);
      onClickNext();
    }
  }, [showHintGistPopup]);

  useEffect(() => {
    if (!selectedType) {
      return;
    }
    if (currentMode === "I_DO" && isIDoEnable) {
      setHighlightedWhoWhat(false);
      setHighlightedMostImportant(false);
      setHighlightedGist(false);
    } else if (currentMode === "I_DO") {
      setHintShow(false);
    }
  }, [isIDoEnable]);

  const getStarted = () => {
    if (!isRunning) {
      const encryptedTime = localStorage.getItem("timer");
      const decryptedTime = decryptData(encryptedTime);
      const now = moment();
      const diff = moment.duration(
        moment(decryptedTime ? decryptedTime : "").diff(now)
      );
      if (decryptedTime && diff && (diff.minutes() > 0 || diff.seconds() > 0)) {
        // startTimer(diff.minutes() * 60 + diff.seconds());
      } else {
        localStorage.setItem(
          "timer",
          encryptData(moment().add(45, "minutes").toString())
          // encryptData(moment().add(process.env?.REACT_APP_TIMER_LIMIT || 45, "minutes").toString())
        );
        startTimer(45 * 60);
      }
    }
  };

  const onClickBtn = () => {
    if (selectedTask?.id < taskData.length - 1) {
      setSelectedTask(taskData[selectedTask?.id + 1]);
    } else {
      setShow(false);
    }
  };

  const highlightWhoWhatBox = (from = "") => {
    if (currentMode === "I_DO" && isIDoEnable && from === "") {
      return;
    }
    setHighlightedWhoWhat(true);
    // setTimeout(() => setHighlightedWhoWhat(false), 8000);
  };

  const highlightMostImportantBox = (from = "") => {
    if (currentMode === "I_DO" && isIDoEnable && from === "") {
      return;
    }
    setHighlightedMostImportant(true);
    // setTimeout(() => setHighlightedMostImportant(false), 8000);
  };

  const highlightGistBox = (from = "") => {
    if (currentMode === "I_DO" && isIDoEnable && from === "") {
      return;
    }
    setHighlightedGist(true);
    // setTimeout(() => setHighlightedGist(false), 8000);
  };

  const highlightSubmitButtonBox = () => {
    setHighlightedSubmitButton(true);
    // setTimeout(() => setHighlightedSubmitButton(false), 8000);
  };

  const highlightSubmitButton = () => {
    if (selectedType === "WHO_OR_WHAT") {
      setHintData([
        {
          id: 0,
          content: "Kindly press the submit button to proceed",
          btn_label: "Ok",
          type: "highlight_submit",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: "Kindly press the submit button to proceed",
        btn_label: "Ok",
        type: "highlight_submit",
      });
      setHintShow(true);
      highlightWhoWhatBox("ALLOW_HIGHLIGHT");
    } else if (selectedType === "MOST_IMPORTANT_INFO") {
      // highlightMostImportantBox("ALLOW_HIGHLIGHT");
      highlightSubmitButtonBox();
      setHintShow(false);
    } else if (selectedType === "GIST") {
      // highlightGistBox("ALLOW_HIGHLIGHT");
      highlightSubmitButtonBox();
      setHintShow(false);
    }
  };

  const onClickHintBtn = () => {
    if (!hintShow) {
      return;
    }
    if (selectedHint?.type === "highlight-textbox") {
      if (selectedType === "WHO_OR_WHAT") {
        highlightWhoWhatBox();
      }
      if (selectedType === "MOST_IMPORTANT_INFO") {
        highlightMostImportantBox();
      }
      if (selectedType === "GIST") {
        highlightGistBox();
      }
    }
    if (selectedHint?.type === "highlight_submit") {
      highlightSubmitButtonBox();
      setHighlightedWhoWhat(false);
      setHighlightedMostImportant(false);
      setHighlightedGist(false);
    }
    if (selectedHint?.highlight_type === "who_what_sentence") {
      highlightWhoWhatBox();
    }
    if (selectedHint?.type === "start_most_important_info") {
      if (currentMode === "WE_DO") {
        onLoadBox();
        return;
      } else {
        highlightMostImportantBox();
      }
    }
    if (selectedHint?.type === "highlight_circle") {
      setShowParagraphHighlightAndCircle(true);
    }
    if (
      selectedHint?.type === "most_important_info_final" ||
      selectedHint?.type === "section_gist_final" ||
      selectedHint?.type === "sentence_who_what_final" ||
      selectedHint?.type === "sentence_important_info_final" ||
      selectedHint?.type === "sentence_gist_final"
    ) {
      handleNextStepAfterValidation();
    }
    if (selectedType === "MOST_IMPORTANT_INFO" && selectedHint?.short_notes) {
      onChangeInput(selectedHint?.short_notes, "MOST_IMPORTANT_INFO", "String");
    }
    if (
      selectedTypeSentence === "MOST_IMPORTANT_INFO" &&
      selectedHint?.short_notes
    ) {
      onChangeInputSentence(
        selectedHint?.short_notes,
        "MOST_IMPORTANT_INFO",
        "String"
      );
    }
    if (selectedHint?.type === "section_gist_start_sentence") {
      onChangeInput(
        selectedHint?.hint_sentence_starter || "",
        "GIST",
        "String"
      );
      highlightGistBox();
    }
    if (selectedHint?.id < hintData.length - 1) {
      setSelectedHint(hintData[selectedHint?.id + 1]);
    } else {
      if (selectedHint?.redirect_type === "who_what_identify") {
        setRectangleContent(
          currentMode === "I_DO" && isIDoEnable
            ? `We have identified the who or what in that paragraph! Great job! Now let’s try to identify the <b class='underline-word'>most important information</b> about <b>${formData?.WHO_OR_WHAT}</b> in the paragraph.`
            : usedHintSection?.WHO_OR_WHAT > 0
            ? `You identified the who or what in that paragraph! We used a few hints to get to the right answer. Now let’s try to identify the <b class='underline-word'>most important information</b> about <b>${formData?.WHO_OR_WHAT}</b> in the paragraph.`
            : dynamic_messages?.most_imp_intro2.replace(
                "{WHO_WHAT_ANSWER}",
                `<b>${formData?.WHO_OR_WHAT}</b>`
              )
        );
        setShowRectangle(true);
        setCustomSpeechContent({
          id: 0,
          content:
            currentMode === "I_DO" && isIDoEnable
              ? `We have identified the who or what in that paragraph! Great job! Now let’s try to identify the <b class='underline-word'>most important information</b> about <b>${formData?.WHO_OR_WHAT}</b> in the paragraph.`
              : usedHintSection?.WHO_OR_WHAT > 0
              ? dynamic_messages?.most_imp_intro1.replace(
                  "{WHO_WHAT_ANSWER}",
                  `<b>${formData?.WHO_OR_WHAT}</b>`
                )
              : dynamic_messages?.most_imp_intro2.replace(
                  "{WHO_WHAT_ANSWER}",
                  `<b>${formData?.WHO_OR_WHAT}</b>`
                ),
        });
        setShowCustomSpeech(true);
      }
      if (selectedHint?.type === "start") {
        setHintData([
          {
            id: 0,
            content: dynamic_messages?.section_hint1,
            btn_label: "Ok",
            type: "start_who_what",
            highlight_type: "play_btn",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: dynamic_messages?.section_hint1,
          btn_label: "Ok",
          type: "start_who_what",
          highlight_type: "play_btn",
        });
        setSelectedType("WHO_OR_WHAT");
        return;
      }
      if (selectedHint?.type === "sentence_gist_start_sentence") {
        onChangeInputSentence(
          selectedHint?.hint_sentence_starter || "",
          "GIST",
          "String"
        );
      }
      if (selectedHint?.type === "sentence_who_what_final") {
        setHintData([
          {
            id: 0,
            content: `Now let’s identify the most important words about <b>${formDataSentence[index2]?.WHO_OR_WHAT}</b>`,
            btn_label: "Ok",
            type: "sentence_info_start_we_do",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: `Now let’s identify the most important words about <b>${formDataSentence[index2]?.WHO_OR_WHAT}</b>`,
          btn_label: "Ok",
          type: "sentence_info_start_we_do",
        });
        return;
      }
      if (selectedHint?.type === "sentence_info_start_we_do") {
        if (currentMode === "WE_DO") {
          onLoadBox();
          return;
        }
      }
      setHintShow(false);
    }
  };

  const checkValidations = () => {
    if (selectedType === "") {
      setHintData([
        {
          id: 0,
          content:
            "I’ll read the paragraph for you first. Click on the volume button next to me anytime you want me to reread it. ",
          btn_label: "Ok",
        },
      ]);
      setSelectedType("WHO_OR_WHAT");
      return;
    }
    if (selectedType === "WHO_OR_WHAT" && !formData?.WHO_OR_WHAT) {
      if (currentMode === "I_DO" && isIDoEnable) {
        setHintData([
          {
            id: 0,
            content: dynamic_messages?.i_do_hint_for_who_what,
            btn_label: "Ok",
            highlight_type: "lesion_btn",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: dynamic_messages?.i_do_hint_for_who_what,
          btn_label: "Ok",
          highlight_type: "lesion_btn",
        });
        setHintShow(true);
        return;
      }
      highlightWhoWhatBox();
      setWhoWhatError(dynamic_messages?.who_what_error_hint);
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.who_what_error_hint,
          btn_label: "Ok",
          highlight_type: "who_what_section",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.who_what_error_hint,
        btn_label: "Ok",
        highlight_type: "who_what_section",
      });
      setHintShow(true);
      return false;
    }
    if (
      selectedType === "MOST_IMPORTANT_INFO" &&
      !formData?.MOST_IMPORTANT_INFO
    ) {
      if (currentMode === "I_DO" && isIDoEnable) {
        setHintData([
          {
            id: 0,
            content: dynamic_messages?.i_do_hint_for_most_imp_info,
            btn_label: "Ok",
            highlight_type: "lesion_btn",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: dynamic_messages?.i_do_hint_for_most_imp_info,
          btn_label: "Ok",
          highlight_type: "lesion_btn",
        });
        setHintShow(true);
        return;
      }
      if (!showMostImpInfo) {
        setShowMostImpInfo(true);
        return false;
      }
      highlightMostImportantBox();
      setMostImportantInfoError(
        dynamic_messages?.most_imp_error_hint.replace(
          "{WHO_WHAT_ANSWER}",
          `<b>${formData?.WHO_OR_WHAT}</b>`
        )
      );
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.most_imp_error_hint.replace(
            "{WHO_WHAT_ANSWER}",
            `<b>${formData?.WHO_OR_WHAT}</b>`
          ),
          btn_label: "Ok",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.most_imp_error_hint.replace(
          "{WHO_WHAT_ANSWER}",
          `<b>${formData?.WHO_OR_WHAT}</b>`
        ),
        btn_label: "Ok",
      });
      setHintShow(true);
      return false;
    }
    if (selectedType === "GIST" && !formData?.GIST) {
      if (currentMode === "I_DO" && isIDoEnable) {
        setHintData([
          {
            id: 0,
            content: dynamic_messages?.i_do_hint_for_gist,
            btn_label: "Ok",
            highlight_type: "lesion_btn",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: dynamic_messages?.i_do_hint_for_gist,
          btn_label: "Ok",
          highlight_type: "lesion_btn",
        });
        setHintShow(true);
        return;
      }
      highlightGistBox();
      setGistError(dynamic_messages?.gist_error_hint);
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.gist_error_hint,
          btn_label: "Ok",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.gist_error_hint,
        btn_label: "Ok",
      });
      setHintShow(true);
      return false;
    }
    if (selectedType === "sentence") {
      if (
        selectedTypeSentence === "WHO_OR_WHAT" &&
        !formDataSentence[index2]?.WHO_OR_WHAT
      ) {
        highlightWhoWhatBox();
        setWhoWhatError(dynamic_messages?.who_what_error_hint);
        setHintData([
          {
            id: 0,
            content: dynamic_messages?.who_what_error_hint,
            btn_label: "Ok",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: dynamic_messages?.who_what_error_hint,
          btn_label: "Ok",
        });
        setHintShow(true);
        return false;
      }
      if (
        selectedTypeSentence === "MOST_IMPORTANT_INFO" &&
        !formDataSentence[index2]?.MOST_IMPORTANT_INFO
      ) {
        highlightMostImportantBox();
        setMostImportantInfoError(
          dynamic_messages?.most_imp_error_hint.replace(
            "{WHO_WHAT_ANSWER}",
            `<b>${formData?.WHO_OR_WHAT}</b>`
          )
        );
        setHintData([
          {
            id: 0,
            content: dynamic_messages?.most_imp_error_hint.replace(
              "{WHO_WHAT_ANSWER}",
              `<b>${formData?.WHO_OR_WHAT}</b>`
            ),
            btn_label: "Ok",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: dynamic_messages?.most_imp_error_hint.replace(
            "{WHO_WHAT_ANSWER}",
            `<b>${formData?.WHO_OR_WHAT}</b>`
          ),
          btn_label: "Ok",
        });
        setHintShow(true);
        return false;
      }

      if (selectedTypeSentence === "GIST" && !formDataSentence[index2]?.GIST) {
        highlightGistBox();
        setGistError(dynamic_messages?.gist_error_hint);
        setHintData([
          {
            id: 0,
            content: dynamic_messages?.gist_error_hint,
            btn_label: "Ok",
          },
        ]);
        setSelectedHint({
          id: 0,
          content: dynamic_messages?.gist_error_hint,
          btn_label: "Ok",
        });
        setHintShow(true);
        return false;
      }
    }
    return true;
  };

  const getModeForAnswerAPI = () => {
    let mode = "";
    switch (currentMode) {
      case "I_DO":
        mode = "i-do";
        break;
      case "WE_DO":
        mode = "we-do";
        break;
      case "YOU_DO":
        mode = "you-do";
        break;
      default:
        mode = "";
    }
    return mode;
  };

  const getAnswer = async (val) => {
    try {
      dispatch(setLoader(true));
      let payload = {
        contentID: props?.selectedPassage,
        sectionID: selected?.id,
        task_type:
          selectedType === "sentence" ? selectedTypeSentence : selectedType,
        answer: val,
        try_count:
          currentMode === "WE_DO" && isAnswer > 0 ? isAnswer - 1 : isAnswer,
        time_spent_seconds: 20,
        past_hint: isAnswer > 0 ? previousAnswerHint : [],
        mode: getModeForAnswerAPI(),
      };
      const response = await api.post("/ai/answer", payload);
      setPreviousAnswerHint(response?.data?.hint_options || []);
      dispatch(setLoader(false));
      return response?.data;
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  const getAnswerInBackground = async (val) => {
    try {
      let payload = {
        contentID: props?.selectedPassage,
        sectionID: selected?.id,
        task_type:
          selectedType === "sentence" ? selectedTypeSentence : selectedType,
        answer: val,
        try_count:
          currentMode === "WE_DO" && isAnswer > 0 ? isAnswer - 1 : isAnswer,
        time_spent_seconds: 20,
        past_hint: isAnswer > 0 ? previousAnswerHint : [],
        mode: getModeForAnswerAPI(),
      };
      const response = await api.post("/ai/answer", payload);
      setPreviousAnswerHint(response?.data?.hint_options || []);
      return response?.data;
    } catch (err) {
      return {};
    }
  };

  const getSentenceAnswer = async (val) => {
    try {
      dispatch(setLoader(true));
      let payload = {
        contentID: props?.selectedPassage,
        sectionID: selected?.id,
        lineID: index2,
        task_type: selectedTypeSentence,
        answer: val,
        try_count: isAnswer,
        time_spent_seconds: 20,
        past_hint: "",
      };
      const response = await api.post("/ai/answer", payload);
      dispatch(setLoader(false));
      return response?.data;
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  const handleApiError = () => {
    setHintData([
      {
        id: 0,
        content: "Something went wrong, please check your internet connection",
        btn_label: "Ok",
      },
    ]);
    setSelectedHint({
      id: 0,
      content: "Something went wrong, please check your internet connection",
      btn_label: "Ok",
    });
    setHintShow(true);
  };

  const handleGistStatementForApq = (val) => {
    const gistListEnc = window.localStorage.getItem("gistList");
    let gistList = [];
    if (gistListEnc) {
      const selectedSection =
        props?.data?.answers_and_clues?.content_answers?.gists || [];
      const sectionInd = selectedSection.findIndex(
        (x) => x.section_id === selected?.id
      );
      if (sectionInd > 0) {
        gistList = JSON.parse(gistListEnc);
      }
      gistList.push(val);
      window.localStorage.setItem("gistList", JSON.stringify(gistList));
    } else {
      gistList = [];
      gistList.push(val);
      window.localStorage.setItem("gistList", JSON.stringify(gistList));
    }
  };

  const getRandomSuccessAnswer = (from) => {
    let arr = [];
    if (from === "SUCCESS") {
      arr = [
        "Great job understanding the text. Keep it up!",
        "You're doing amazing with these tough readings!",
        "Your hard work on comprehension is paying off!",
        "Fantastic effort on breaking down the passages!",
        "Your progress in reading is impressive!",
        "You're getting really good at finding key details!",
        "Awesome job grasping the main ideas!",
        "You're excelling in understanding these texts!",
        "Great work on understanding each paragraph!",
        "You're really getting the hang of these readings!",
        "Impressive job on analyzing the text!",
        "Your comprehension skills are top-notch!",
      ];
    } else {
      arr = [
        "You worked hard! Let’s use some tips to get through this.",
        "Nice try! Here are some hints to guide you.",
        "Good attempt! Let’s use these hints to understand better.",
        "Well done for trying! Let’s use some scaffolds to assist.",
        "You gave it your best! Now, let’s try some hints.",
        "Awesome effort! Let’s use these tips to help you succeed.",
        "You did your best! Here are some hints to help you along.",
        "Great job trying! Let’s use some scaffolds to make it easier.",
        "You worked hard! These hints will help you understand more",
      ];
    }
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  };

  const checkAnswerValidations = async (fromMode = "") => {
    const selectedSection =
      props?.data?.answers_and_clues?.content_answers?.gists || [];
    const sectionInd = selectedSection.findIndex(
      (x) => x.section_id === selected?.id
    );
    if (selectedType !== "sentence") {
      if (selectedType === "WHO_OR_WHAT" && isAnswer === 2) {
        const is_correct = options.find((x) => x.is_correct === "true");
        if (is_correct && formData?.WHO_OR_WHAT === is_correct?.hint) {
          getAnswerInBackground(formData?.WHO_OR_WHAT);
          const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          setHintData([
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "OK",
              redirect_type: "who_what_identify",
              is_disabled_allow: true,
            },
          ]);
          setSelectedHint({
            id: 0,
            content: randomHintMsg,
            btn_label: "OK",
            redirect_type: "who_what_identify",
            is_disabled_allow: true,
          });
          setHintShow(true);
          return true;
        }
      }
      if (selectedType === "MOST_IMPORTANT_INFO" && isAnswer === 2) {
        const is_correct = options.filter((x) => x.is_correct === "true");
        const selected = options.filter(
          (x) => x.className === "container-selected-important"
        );
        if (selected.length === is_correct.length) {
          let ansArr = selected.map((x) => {
            return x?.hint;
          });
          let flag = true;
          selected.map((x) => {
            if (x.is_correct !== "true") {
              flag = false;
            }
            return true;
          });
          if (flag) {
            getAnswerInBackground(JSON.stringify(ansArr));
            const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
            setHintData([
              {
                id: 0,
                content:
                  "Great Job in identifying the most important information, I have generated short notes from your answer",
                btn_label: "Ok",
              },
              {
                id: 1,
                content: randomHintMsg,
                btn_label: "Ok",
                type: "most_important_info_final",
                is_disabled_allow: true,
              },
            ]);
            setSelectedHint({
              id: 0,
              content:
                "Great Job in identifying the most important information, I have generated short notes from your answer",
              btn_label: "Ok",
            });
            setHintShow(true);
            return false;
          }
        }
      }
      if (
        selectedType === "GIST" &&
        ((currentMode !== "WE_DO" && isAnswer === 2) ||
          (currentMode === "WE_DO" && isAnswer === 3))
      ) {
        const is_correct = options.filter((x) => x.is_correct === "true");
        const selected = options.filter(
          (x) => x.className === "container-selected-important"
        );
        if (selected.length === is_correct.length) {
          let ansArr = selected.map((x) => {
            return x?.hint;
          });
          let flag = true;
          selected.map((x) => {
            if (x.is_correct !== "true") {
              flag = false;
            }
            return true;
          });
          if (flag) {
            getAnswerInBackground(JSON.stringify(ansArr));
            const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
            setHintData([
              {
                id: 0,
                content: randomHintMsg,
                btn_label: "OK",
                type: "section_gist_final",
                is_disabled_allow: true,
              },
            ]);
            setSelectedHint({
              id: 0,
              content: randomHintMsg,
              btn_label: "OK",
              type: "section_gist_final",
              is_disabled_allow: true,
            });
            setHintShow(true);
            handleGistStatementForApq(formData?.GIST);
            return false;
          }
        }
      }

      if (selectedType === "WHO_OR_WHAT") {
        if (isAnswer === 3) {
          return true;
        }
        if (fromMode === "WE_DO") {
          let arr = [
            {
              id: 0,
              content:
                "I am going to give you some hints to help you get started",
              btn_label: "OK",
            },
            {
              id: 1,
              content:
                selectedSection[sectionInd]?.section_level?.who_or_what
                  ?.hint_text,
              btn_label: "OK",
              type: "highlight-textbox",
              is_hint: true,
            },
            {
              id: 2,
              content: "Now Please Enter your answer in the Text Box",
              btn_label: "OK",
            },
          ];
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        if (currentMode === "I_DO" && isIDoEnable) {
          getAnswerInBackground(formData?.WHO_OR_WHAT);
          // const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          // setHintData([
          //   {
          //     id: 0,
          //     content: randomHintMsg,
          //     btn_label: "OK",
          //     redirect_type: "who_what_identify",
          //   },
          // ]);
          // setSelectedHint({
          //   id: 0,
          //   content: randomHintMsg,
          //   btn_label: "OK",
          //   redirect_type: "who_what_identify",
          // });
          // setHintShow(true);
          setRectangleContent(
            currentMode === "I_DO" && isIDoEnable
              ? `We have identified the who or what in that paragraph! Great job! Now let’s try to identify the <b class='underline-word'>most important information</b> about <b>${formData?.WHO_OR_WHAT}</b> in the paragraph.`
              : usedHintSection?.WHO_OR_WHAT > 0
              ? `You identified the who or what in that paragraph! We used a few hints to get to the right answer. Now let’s try to identify the <b class='underline-word'>most important information</b> about <b>${formData?.WHO_OR_WHAT}</b> in the paragraph.`
              : dynamic_messages?.most_imp_intro1.replace(
                  "{WHO_WHAT_ANSWER}",
                  `<b>${formData?.WHO_OR_WHAT}</b>`
                )
          );
          setShowRectangle(true);
          setCustomSpeechContent({
            id: 0,
            content:
              currentMode === "I_DO" && isIDoEnable
                ? `We have identified the who or what in that paragraph! Great job! Now let’s try to identify the <b class='underline-word'>most important information</b> about <b>${formData?.WHO_OR_WHAT}</b> in the paragraph.`
                : usedHintSection?.WHO_OR_WHAT > 0
                ? dynamic_messages?.most_imp_intro1.replace(
                    "{WHO_WHAT_ANSWER}",
                    `<b>${formData?.WHO_OR_WHAT}</b>`
                  )
                : dynamic_messages?.most_imp_intro2.replace(
                    "{WHO_WHAT_ANSWER}",
                    `<b>${formData?.WHO_OR_WHAT}</b>`
                  ),
          });
          setShowCustomSpeech(true);
          return true;
        }
        const resAns = await getAnswer(formData?.WHO_OR_WHAT);
        if (!resAns) {
          handleApiError();
          return false;
        }
        if (!resAns?.next_action?.repeat) {
          const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          setHintData([
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "OK",
              redirect_type: "who_what_identify",
              is_disabled_allow: true,
            },
          ]);
          setSelectedHint({
            id: 0,
            content: randomHintMsg,
            btn_label: "OK",
            redirect_type: "who_what_identify",
            is_disabled_allow: true,
          });
          setHintShow(true);
          return true;
        }
        setUsedHintSection({
          ...usedHintSection,
          WHO_OR_WHAT: isAnswer + 1,
        });
        if (isAnswer === 0) {
          const randomHintMsg = getRandomSuccessAnswer("HINT");
          let arr = [
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "Ok",
            },
          ];
          resAns.hint_text?.map((x, index) => {
            arr.push({
              id: index + 1,
              content: x,
              btn_label: "Ok",
              is_hint: true,
              is_last_hint:
                index + 1 === resAns.hint_text.length ? true : false,
            });
            return true;
          });
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        if (isAnswer === 1) {
          let pop_content = "";
          resAns?.hint_options?.map((x, index) => {
            if (index === 0) {
              pop_content += ` . . '${x?.hint}'. . or . . `;
            } else if (index < resAns?.hint_options?.length - 1) {
              pop_content += ` . . '${x?.hint}'. . or . . `;
            } else {
              pop_content += ` '${x?.hint}'`;
            }
            return true;
          });
          setCustomSpeechContent({
            id: 0,
            content: `${resAns?.l2_option_text} ${pop_content}`,
            label_content: `${resAns?.l2_option_text}`,
          });
          setShowCustomSpeech(true);
          setOptions(resAns?.hint_options || []);
          setShowHintWhoWhatPopup(true);
          return false;
        }
        if (isAnswer === 2) {
          const right_answer = options?.find((x) => x.is_correct);
          setFormData({
            ...formData,
            WHO_OR_WHAT: right_answer?.hint || formData?.WHO_OR_WHAT,
          });
          const regex1 = new RegExp(right_answer?.hint, "gi");
          let explanation = resAns?.explanation.replace(regex1, (match) => {
            if (match === match.toUpperCase()) {
              return `<b>${match}</b>`;
            } else {
              return `<b>${match}</b>`;
            }
          });
          setHintData([
            {
              id: 0,
              content: `The right answer is ${
                right_answer?.hint || formData?.WHO_OR_WHAT
              }`,
              btn_label: "OK",
            },
            {
              id: 1,
              content: explanation,
              btn_label: "OK",
              redirect_type: "who_what_identify",
              is_disabled_allow: true,
            },
          ]);
          setSelectedHint({
            id: 0,
            content: `The right answer is ${
              right_answer?.hint || formData?.WHO_OR_WHAT
            }`,
            btn_label: "OK",
          });
          setHintShow(true);
          return true;
        }
      }

      if (selectedType === "MOST_IMPORTANT_INFO") {
        if (isAnswer === 3) {
          return true;
        }
        if (fromMode === "WE_DO") {
          let arr = [
            {
              id: 0,
              content:
                "I am going to give you some hints to help you get started",
              btn_label: "OK",
            },
            {
              id: 1,
              content:
                selectedSection[sectionInd]?.section_level?.most_important_info
                  ?.hint_text,
              btn_label: "OK",
              type: "highlight-textbox",
              is_hint: true,
            },
            {
              id: 2,
              content: "Now Please Enter your answer in the Text Box",
              btn_label: "OK",
            },
          ];
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        if (currentMode === "I_DO" && isIDoEnable) {
          getAnswerInBackground(formData?.MOST_IMPORTANT_INFO);
          // const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          // setHintData([
          //   {
          //     id: 1,
          //     content: randomHintMsg,
          //     btn_label: "OK",
          //     type: "most_important_info_final",
          //   },
          // ]);
          // setSelectedHint({
          //   id: 1,
          //   content: randomHintMsg,
          //   btn_label: "OK",
          //   type: "most_important_info_final",
          // });
          // setHintShow(true);
          handleNextStepAfterValidation();
          return false;
        }
        const resAns = await getAnswer(formData?.MOST_IMPORTANT_INFO);
        if (!resAns) {
          handleApiError();
          return false;
        }
        if (!resAns?.next_action?.repeat) {
          if (resAns?.most_important_info_short_notes) {
            onChangeInput(
              resAns?.most_important_info_short_notes,
              "MOST_IMPORTANT_INFO",
              "string"
            );
          }
          const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          setHintData([
            {
              id: 0,
              content:
                "Great Job in identifying the most important information, I have generated short notes from your answer",
              btn_label: "Ok",
            },
            {
              id: 1,
              content: randomHintMsg,
              btn_label: "OK",
              type: "most_important_info_final",
              is_disabled_allow: true,
            },
          ]);
          setSelectedHint({
            id: 0,
            content:
              "Great Job in identifying the most important information, I have generated short notes from your answer",
            btn_label: "Ok",
          });
          setHintShow(true);
          return false;
        }
        setUsedHintSection({
          ...usedHintSection,
          MOST_IMPORTANT_INFO: isAnswer + 1,
        });
        if (isAnswer === 0) {
          let arr = [
            {
              id: 0,
              content: getRandomSuccessAnswer("HINT"),
              btn_label: "OK",
            },
          ];
          resAns.hint_text?.map((x, index) => {
            arr.push({
              id: index + 1,
              content: x,
              btn_label: "Ok",
              is_hint: true,
              is_last_hint:
                index + 1 === resAns.hint_text.length ? true : false,
            });
            return true;
          });
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        if (isAnswer === 1) {
          let pop_content = "";
          resAns?.hint_options?.map((x, index) => {
            if (index === 0) {
              pop_content += ` . . '${x?.hint}'. . or . . `;
            } else if (index < resAns?.hint_options?.length - 1) {
              pop_content += ` . . '${x?.hint}'. . or . . `;
            } else {
              pop_content += ` '${x?.hint}'`;
            }
            return true;
          });
          setCustomSpeechContent({
            id: 0,
            content: `${resAns?.l2_option_text} ${pop_content}`,
            label_content: `${resAns?.l2_option_text}`,
          });
          setShowCustomSpeech(true);
          let arr = resAns?.hint_options || [];
          let temporaryArray = arr.map((x) => {
            return {
              ...x,
              className: "",
            };
          });
          setOptions(temporaryArray);
          setShowHintImportantPopup(true);
          return false;
        }
        if (isAnswer === 2) {
          let selected_answer = "";
          let correct_answer = "";
          options
            .filter((x) => x.className === "container-selected-important")
            .map((x) => {
              selected_answer += x.hint + " ";
              return true;
            });
          options
            .filter((x) => x.is_correct === "true")
            .map((x) => {
              correct_answer += x.hint + "\n";
              return true;
            });

          setHintData([
            {
              id: 0,
              content: `Good try, but the option <br /> <Button className="btn-custom-info-ans"
              variant="contained"
              size="small" style="background-color: #008080;border: none;border-radius: 15px;padding: 5px 15px;color: white;margin: 5px 0;">${selected_answer}</Button><br />is not right! No problem, I generated the short notes for you, take a look!`,
              btn_label: "OK",
              short_notes:
                selectedSection[sectionInd]?.section_level?.most_important_info
                  ?.answer,
            },
            {
              id: 1,
              content: `The right answer is ${correct_answer}`,
              btn_label: "OK",
              type: "most_important_info_final",
              is_disabled_allow: true,
            },
          ]);
          setSelectedHint({
            id: 0,
            content: `Good try, but the option <br /> <Button className="btn-custom-info-ans"
            variant="contained"
            size="small" style="background-color: #008080;border: none;border-radius: 15px;padding: 5px 15px;color: white;margin: 5px 0;">${selected_answer}</Button><br />is not right! No problem, I generated the short notes for you, take a look!`,
            btn_label: "OK",
            short_notes:
              selectedSection[sectionInd]?.section_level?.most_important_info
                ?.answer,
          });
          setHintShow(true);
          return false;
        }
      }

      // GIST flow
      if (selectedType === "GIST") {
        if (fromMode === "WE_DO") {
          let arr = [
            {
              id: 0,
              content:
                "I am going to give you some hints to help you get started",
              btn_label: "OK",
            },
            {
              id: 1,
              content: selectedSection[sectionInd]?.gist_statement?.hint_text,
              btn_label: "Ok",
              type: "highlight-textbox",
              is_hint: true,
            },
            {
              id: 2,
              content: "Now Please Enter your answer in the Text Box",
              btn_label: "OK",
            },
          ];
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        if (currentMode === "I_DO" && isIDoEnable) {
          getAnswerInBackground(formData?.GIST);
          // const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          // setHintData([
          //   {
          //     id: 0,
          //     content: randomHintMsg,
          //     btn_label: "OK",
          //     type: "section_gist_final",
          //   },
          // ]);
          // setSelectedHint({
          //   id: 0,
          //   content: randomHintMsg,
          //   btn_label: "OK",
          //   type: "section_gist_final",
          // });
          // setHintShow(true);
          handleGistStatementForApq(formData?.GIST);
          // handleNextStepAfterValidation();
          return true;
        }
        const resAns = await getAnswer(formData?.GIST);
        if (!resAns) {
          handleApiError();
          return false;
        }
        if (!resAns?.next_action?.repeat) {
          const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          setHintData([
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "OK",
              type: "section_gist_final",
              is_disabled_allow: true,
            },
          ]);
          setSelectedHint({
            id: 0,
            content: randomHintMsg,
            btn_label: "OK",
            type: "section_gist_final",
            is_disabled_allow: true,
          });
          setHintShow(true);
          handleGistStatementForApq(formData?.GIST);
          return false;
        }
        setUsedHintSection({
          ...usedHintSection,
          GIST: isAnswer + 1,
        });
        if (isAnswer === 0) {
          const randomHintMsg = getRandomSuccessAnswer("HINT");
          let arr = [
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "Ok",
            },
          ];
          resAns.hint_text?.map((x, index) => {
            arr.push({
              id: index + 1,
              content: x,
              btn_label: "Ok",
              type:
                index + 1 === resAns?.hint_text.length
                  ? "section_gist_start_sentence"
                  : "",
              hint_sentence_starter:
                index + 1 === resAns?.hint_text.length
                  ? resAns?.hint_sentence_starter
                  : "",
              is_hint: true,
              is_last_hint:
                index + 1 === resAns.hint_text.length ? true : false,
            });
            return true;
          });
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        if (currentMode === "WE_DO") {
          if (isAnswer === 1) {
            let arr = [
              {
                id: 0,
                content:
                  "Let me give you a sentence starter that you can use to write a gist statement",
                btn_label: "Ok",
                type: "section_gist_start_sentence",
                hint_sentence_starter: resAns?.hint_sentence_starter,
              },
              {
                id: 1,
                content: "Now go Ahead and complete the gist statement",
                btn_label: "Ok",
              },
            ];
            setHintData(arr);
            setSelectedHint(arr[0]);
            setHintShow(true);
            return false;
          }
          if (isAnswer === 2) {
            let pop_content = "";
            resAns?.hint_options?.map((x, index) => {
              if (index === 0) {
                pop_content += ` . . '${x?.hint}'. . or . . `;
              } else if (index < resAns?.hint_options?.length - 1) {
                pop_content += ` . . '${x?.hint}'. . or . . `;
              } else {
                pop_content += ` '${x?.hint}'`;
              }
              return true;
            });
            setCustomSpeechContent({
              id: 0,
              content: `${resAns?.l2_option_text} ${pop_content}`,
              label_content: `${resAns?.l2_option_text}`,
            });
            setShowCustomSpeech(true);
            setOptions(resAns?.hint_options || []);
            setShowHintGistPopup(true);
            return false;
          }
          if (isAnswer >= 3) {
            const right_answer = options?.find((x) => x.is_correct === "true");
            handleGistStatementForApq(right_answer?.hint || formData?.GIST);
            setFormData({
              ...formData,
              GIST: right_answer?.hint || formData?.GIST,
            });
            const regex1 = new RegExp(right_answer?.hint, "gi");
            let explanation = resAns?.explanation.replace(regex1, (match) => {
              if (match === match.toUpperCase()) {
                return `<b>${match}</b>`;
              } else {
                return `<b>${match}</b>`;
              }
            });

            setHintData([
              {
                id: 0,
                content: `The right answer is ${
                  right_answer?.hint || formData?.GIST
                }`,
                btn_label: "OK",
              },
              {
                id: 1,
                content: explanation,
                btn_label: "OK",
                type: "section_gist_final",
                is_disabled_allow: true,
              },
            ]);
            setSelectedHint({
              id: 0,
              content: `The right answer is ${
                right_answer?.hint || formData?.GIST
              }`,
              btn_label: "OK",
            });
            setHintShow(true);
            return false;
          }
        } else {
          if (isAnswer === 1) {
            let pop_content = "";
            resAns?.hint_options?.map((x, index) => {
              if (index === 0) {
                pop_content += ` . . '${x?.hint}'. . or . . `;
              } else if (index < resAns?.hint_options?.length - 1) {
                pop_content += ` . . '${x?.hint}'. . or . . `;
              } else {
                pop_content += ` '${x?.hint}'`;
              }
              return true;
            });
            setCustomSpeechContent({
              id: 0,
              content: `${resAns?.l2_option_text} ${pop_content}`,
              label_content: `${resAns?.l2_option_text}`,
            });
            setShowCustomSpeech(true);
            setOptions(resAns?.hint_options || []);
            setShowHintGistPopup(true);
            return false;
          }
          if (isAnswer >= 2) {
            const right_answer = options?.find((x) => x.is_correct === "true");
            handleGistStatementForApq(right_answer?.hint || formData?.GIST);
            setFormData({
              ...formData,
              GIST: right_answer?.hint || formData?.GIST,
            });
            const regex1 = new RegExp(right_answer?.hint, "gi");
            let explanation = resAns?.explanation.replace(regex1, (match) => {
              if (match === match.toUpperCase()) {
                return `<b>${match}</b>`;
              } else {
                return `<b>${match}</b>`;
              }
            });

            setHintData([
              {
                id: 0,
                content: `The right answer is ${
                  right_answer?.hint || formData?.GIST
                }`,
                btn_label: "OK",
              },
              {
                id: 1,
                content: explanation,
                btn_label: "OK",
                type: "section_gist_final",
                is_disabled_allow: true,
              },
            ]);
            setSelectedHint({
              id: 0,
              content: `The right answer is ${
                right_answer?.hint || formData?.GIST
              }`,
              btn_label: "OK",
            });
            setHintShow(true);
            return false;
          }
        }
      }
    }

    if (selectedType === "sentence") {
      if (selectedTypeSentence === "WHO_OR_WHAT" && isAnswer === 2) {
        const is_correct = options.find((x) => x.is_correct === "true");
        if (
          is_correct &&
          formDataSentence[index2]?.WHO_OR_WHAT === is_correct?.hint
        ) {
          const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          setHintData([
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "OK",
              type: "sentence_who_what_final",
            },
          ]);
          setSelectedHint({
            id: 0,
            content: randomHintMsg,
            btn_label: "OK",
            type: "sentence_who_what_final",
          });
          setHintShow(true);
          return false;
        }
      }
      if (selectedTypeSentence === "MOST_IMPORTANT_INFO" && isAnswer === 2) {
        const is_correct = options.filter((x) => x.is_correct === "true");
        const selected = options.filter(
          (x) => x.className === "container-selected-important"
        );
        if (selected.length === is_correct.length) {
          let flag = true;
          selected.map((x) => {
            if (x.is_correct !== "true") {
              flag = false;
            }
            return true;
          });
          if (flag) {
            const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
            setHintData([
              {
                id: 0,
                content:
                  "Great Job in identifying the most important information, I have generated short notes from your answer",
                btn_label: "Ok",
              },
              {
                id: 1,
                content: randomHintMsg,
                btn_label: "Ok",
                type: "sentence_important_info_final",
              },
            ]);
            setSelectedHint({
              id: 0,
              content:
                "Great Job in identifying the most important information, I have generated short notes from your answer",
              btn_label: "Ok",
            });
            setHintShow(true);
            return false;
          }
        }
      }
      if (selectedTypeSentence === "GIST" && isAnswer === 2) {
        const is_correct = options.filter((x) => x.is_correct === "true");
        const selected = options.filter(
          (x) => x.className === "container-selected-important"
        );
        if (selected.length === is_correct.length) {
          let flag = true;
          selected.map((x) => {
            if (x.is_correct !== "true") {
              flag = false;
            }
            return true;
          });
          if (flag) {
            const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
            setHintData([
              {
                id: 0,
                content: randomHintMsg,
                btn_label: "OK",
                type: "sentence_gist_final",
              },
            ]);
            setSelectedHint({
              id: 0,
              content: randomHintMsg,
              btn_label: "OK",
              type: "sentence_gist_final",
            });
            setHintShow(true);
            handleGistStatementForApq(formDataSentence[index2]?.GIST);
            return false;
          }
        }
      }

      // WHO_OR_WHAT flow
      if (selectedTypeSentence === "WHO_OR_WHAT") {
        if (isAnswer === 3) {
          return true;
        }
        if (fromMode === "WE_DO") {
          let arr = [
            {
              id: 0,
              content: selectedSentence?.line_gist[0].who_or_what?.hint_text,
              btn_label: "OK",
            },
          ];
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        const resAns = await getSentenceAnswer(
          formDataSentence[index2]?.WHO_OR_WHAT
        );
        if (!resAns) {
          handleApiError();
          return false;
        }
        if (!resAns?.next_action?.repeat) {
          const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          setHintData([
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "OK",
              type: "sentence_who_what_final",
            },
          ]);
          setSelectedHint({
            id: 0,
            content: randomHintMsg,
            btn_label: "OK",
            type: "sentence_who_what_final",
          });
          setHintShow(true);
          return false;
        }
        if (isAnswer === 0) {
          const randomHintMsg = getRandomSuccessAnswer("HINT");
          let arr = [
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "OK",
            },
          ];
          resAns.hint_text?.map((x, index) => {
            arr.push({
              id: index + 1,
              content: x,
              btn_label: "Ok",
            });
            return true;
          });
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        if (isAnswer === 1) {
          let pop_content = "";
          resAns?.hint_options?.map((x, index) => {
            if (index === 0) {
              pop_content += `'${x?.hint}' or`;
            } else if (index < resAns?.hint_options?.length - 1) {
              pop_content += ` '${x?.hint}' or `;
            } else {
              pop_content += ` '${x?.hint}'`;
            }
            return true;
          });
          setCustomSpeechContent({
            id: 0,
            content: `${resAns?.l2_option_text} ${pop_content}`,
            label_content: `${resAns?.l2_option_text}`,
          });
          setShowCustomSpeech(true);
          setOptions(resAns?.hint_options || []);
          setShowHintWhoWhatPopup(true);
          return false;
        }
        if (isAnswer === 2) {
          onChangeInputSentence(
            selectedSentence?.line_gist[0]?.who_or_what?.answer,
            "WHO_OR_WHAT"
          );
          const regex1 = new RegExp(
            selectedSentence?.line_gist[0]?.who_or_what?.answer,
            "gi"
          );
          let explanation = resAns?.explanation.replace(regex1, (match) => {
            if (match === match.toUpperCase()) {
              return `<b>${match}</b>`;
            } else {
              return `<b>${match}</b>`;
            }
          });
          setHintData([
            {
              id: 0,
              content: `The right answer is ${selectedSentence?.line_gist[0]?.who_or_what?.answer}`,
              btn_label: "OK",
            },
            {
              id: 1,
              content: explanation,
              btn_label: "OK",
              type: "sentence_who_what_final",
            },
          ]);
          setSelectedHint({
            id: 0,
            content: `The right answer is ${selectedSentence?.line_gist[0]?.who_or_what?.answer}`,
            btn_label: "OK",
          });
          setHintShow(true);
          return false;
        }
        return false;
      }

      // MOST_IMPORTANT_INFO flow
      if (selectedTypeSentence === "MOST_IMPORTANT_INFO") {
        if (isAnswer === 3) {
          return true;
        }
        if (fromMode === "WE_DO") {
          let arr = [
            {
              id: 0,
              content:
                selectedSentence?.line_gist[0].most_important_info?.hint_text,
              btn_label: "OK",
            },
          ];
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        const resAns = await getSentenceAnswer(
          formDataSentence[index2]?.MOST_IMPORTANT_INFO
        );
        if (!resAns) {
          handleApiError();
          return false;
        }
        if (!resAns?.next_action?.repeat) {
          const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          setHintData([
            {
              id: 0,
              content:
                "Great Job in identifying the most important information, I have generated short notes from your answer",
              btn_label: "Ok",
            },
            {
              id: 1,
              content: randomHintMsg,
              btn_label: "Ok",
              type: "sentence_important_info_final",
            },
          ]);
          setSelectedHint({
            id: 0,
            content:
              "Great Job in identifying the most important information, I have generated short notes from your answer",
            btn_label: "Ok",
          });
          setHintShow(true);
          if (resAns?.most_important_info_short_notes) {
            onChangeInputSentence(
              resAns?.most_important_info_short_notes,
              "MOST_IMPORTANT_INFO"
            );
          }
          return false;
        }
        if (isAnswer === 0) {
          let arr = [
            {
              id: 0,
              content: getRandomSuccessAnswer("HINT"),
              btn_label: "OK",
            },
          ];
          resAns.hint_text?.map((x, index) => {
            arr.push({
              id: index + 1,
              content: x,
              btn_label: "Ok",
            });
            return true;
          });
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        if (isAnswer === 1) {
          let pop_content = "";
          resAns?.hint_options?.map((x, index) => {
            if (index === 0) {
              pop_content += ` . . '${x?.hint}'. . or . . `;
            } else if (index < resAns?.hint_options?.length - 1) {
              pop_content += ` . . '${x?.hint}'. . or . . `;
            } else {
              pop_content += ` '${x?.hint}'`;
            }
            return true;
          });
          setCustomSpeechContent({
            id: 0,
            content: `${resAns?.l2_option_text} ${pop_content}`,
            label_content: `${resAns?.l2_option_text}`,
          });
          setShowCustomSpeech(true);
          let arr = resAns?.hint_options || [];
          let temporaryArray = arr.map((x) => {
            return {
              ...x,
              className: "",
            };
          });
          setOptions(temporaryArray);
          setShowHintImportantPopup(true);
          return false;
        }
        if (isAnswer === 2) {
          let selected_answer = "";

          let correct_answer = "";
          // if (currentMode == "WE_DO") {
          //   let arr = resAns?.hint_options || [];
          //   selected_answer = formDataSentence[index2]?.MOST_IMPORTANT_INFO;
          //   arr
          //     .filter((x) => x.is_correct == "true")
          //     .map((x) => {
          //       correct_answer += x.hint + "\n";
          //     });
          // } else {
          options
            .filter((x) => x.className === "container-selected-important")
            .map((x) => {
              selected_answer += x.hint + " ";
              return true;
            });
          options
            .filter((x) => x.is_correct === "true")
            .map((x) => {
              correct_answer += x.hint + "\n";
              return true;
            });
          // }

          setHintData([
            {
              id: 0,
              content: `Good try, but the option <br /> <Button className="btn-custom-info-ans"
              variant="contained"
              size="small" style="background-color: #008080;border: none;border-radius: 15px;padding: 5px 15px;color: white;margin: 5px 0;">${selected_answer}</Button><br />is not right! No problem, I generated the short notes for you, take a look!`,
              btn_label: "OK",
              short_notes:
                selectedSentence?.line_gist[0]?.most_important_info?.answer,
            },
            {
              id: 1,
              content: `The right answer is ${correct_answer}`,
              btn_label: "OK",
              type: "sentence_important_info_final",
            },
          ]);
          setSelectedHint({
            id: 0,
            content: `Good try, but the option <br /> <Button className="btn-custom-info-ans"
            variant="contained"
            size="small" style="background-color: #008080;border: none;border-radius: 15px;padding: 5px 15px;color: white;margin: 5px 0;">${selected_answer}</Button><br />is not right! No problem, I generated the short notes for you, take a look!`,
            btn_label: "OK",
            short_notes:
              selectedSentence?.line_gist[0]?.most_important_info?.answer,
          });
          setHintShow(true);
          // onChangeInputSentence(
          //   selectedSentence?.line_gist[0]?.most_important_info?.answer,
          //   "MOST_IMPORTANT_INFO"
          // );
          return false;
        }
      }

      // GIST flow
      if (selectedTypeSentence === "GIST") {
        if (fromMode === "WE_DO") {
          let arr = [
            {
              id: 0,
              content: selectedSection[sectionInd]?.gist_statement?.hint_text,
              btn_label: "OK",
            },
          ];
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }
        const resAns = await getAnswer(formDataSentence[index2]?.GIST);
        if (!resAns) {
          handleApiError();
          return false;
        }
        if (!resAns?.next_action?.repeat) {
          const randomHintMsg = getRandomSuccessAnswer("SUCCESS");
          setHintData([
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "OK",
              type: "sentence_gist_final",
            },
          ]);
          setSelectedHint({
            id: 0,
            content: randomHintMsg,
            btn_label: "OK",
            type: "sentence_gist_final",
          });
          setHintShow(true);
          handleGistStatementForApq(formDataSentence[index2]?.GIST);
          return false;
        }
        if (isAnswer === 0) {
          const randomHintMsg = getRandomSuccessAnswer("HINT");
          let arr = [
            {
              id: 0,
              content: randomHintMsg,
              btn_label: "Ok",
            },
          ];
          resAns.hint_text?.map((x, index) => {
            arr.push({
              id: index + 1,
              content: x,
              btn_label: "Ok",
              type:
                index + 1 === resAns?.hint_text.length
                  ? "sentence_gist_start_sentence"
                  : "",
              hint_sentence_starter:
                index + 1 === resAns?.hint_text.length
                  ? resAns?.hint_sentence_starter
                  : "",
            });
            return true;
          });
          setHintData(arr);
          setSelectedHint(arr[0]);
          setHintShow(true);
          return false;
        }

        if (isAnswer === 1) {
          let pop_content = "";
          resAns?.hint_options?.map((x, index) => {
            if (index === 0) {
              pop_content += ` . . '${x?.hint}'. . or . . `;
            } else if (index < resAns?.hint_options?.length - 1) {
              pop_content += ` . . '${x?.hint}'. . or . . `;
            } else {
              pop_content += ` '${x?.hint}'`;
            }
            return true;
          });
          setCustomSpeechContent({
            id: 0,
            content: `${resAns?.l2_option_text} ${pop_content}`,
            label_content: `${resAns?.l2_option_text}`,
          });
          setShowCustomSpeech(true);
          setOptions(resAns?.hint_options || []);
          setShowHintGistPopup(true);
          return false;
        }
        if (isAnswer >= 2) {
          const right_answer = options?.find((x) => x.is_correct === "true");
          handleGistStatementForApq(right_answer?.hint || formData?.GIST);
          setFormData({
            ...formData,
            GIST: right_answer?.hint || formData?.GIST,
          });
          const regex1 = new RegExp(right_answer?.hint, "gi");
          let explanation = resAns?.explanation.replace(regex1, (match) => {
            if (match === match.toUpperCase()) {
              return `<b>${match}</b>`;
            } else {
              return `<b>${match}</b>`;
            }
          });
          setHintData([
            {
              id: 0,
              content: `The right answer is ${
                right_answer?.hint || formData?.GIST
              }`,
              btn_label: "OK",
            },
            {
              id: 1,
              content: explanation,
              btn_label: "OK",
              type: "sentence_gist_final",
            },
          ]);
          setSelectedHint({
            id: 0,
            content: `The right answer is ${
              right_answer?.hint || formData?.GIST
            }`,
            btn_label: "OK",
          });
          setHintShow(true);
          return false;
        }
      }
    }
    return false;
  };

  const onClickPreview = () => {
    setShowCustomSpeech(false);
    if (selectedType === "GIST") {
      setSelectedType("MOST_IMPORTANT_INFO");
      setIsAnswer(3);
      return;
    }
    if (selectedType === "MOST_IMPORTANT_INFO" || showRectangle) {
      setSelectedType("WHO_OR_WHAT");
      setShowMostImpInfo(false);
      setShowRectangle(false);
      setIsAnswer(3);
      return;
    }

    if (selectedType === "sentence") {
      if (selectedTypeSentence === "GIST") {
        setSentenceOverview("overview");
        setSelectedTypeSentence("MOST_IMPORTANT_INFO");
        setIsAnswer(3);
        return;
      }
      if (selectedTypeSentence === "MOST_IMPORTANT_INFO") {
        setSelectedTypeSentence("WHO_OR_WHAT");
        setIsAnswer(3);
        return;
      }
    }
  };

  const onClickNext = async (fromMode = "") => {
    setHighlightedSubmitButton(false);
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    setShowCustomSpeech(false);
    if (
      showRectangle ||
      (selectedType === "GIST" && sectionGistType === "intro")
    ) {
      return;
    }
    setWhoWhatError("");
    setMostImportantInfoError("");
    setGistError("");
    if (selectedType === "sentence" && sentenceOverview === "overview") {
      setSentenceOverview("gist");
      setSelectedTypeSentence("GIST");
      onLoadSectionGist();
      return;
    }
    if (fromMode !== "WE_DO") {
      const isValid = checkValidations();
      if (!isValid) {
        return;
      }
    }
    const isValidAnswer = await checkAnswerValidations(fromMode);
    if (!isValidAnswer) {
      // if (currentMode == "WE_DO" && isAnswer == 0) {
      //   setIsAnswer(isAnswer + 2);
      // } else {
      setIsAnswer(isAnswer + 1);
      // }

      return;
    }
    handleNextStepAfterValidation();
  };

  const handleNextStepAfterValidation = () => {
    setIsAnswer(0);
    if (selectedType === "WHO_OR_WHAT") {
      setSelectedType("MOST_IMPORTANT_INFO");
      return;
    }
    if (selectedType === "MOST_IMPORTANT_INFO") {
      setSelectedType("GIST");
    }

    if (selectedType === "GIST" || selectedType === "sentence") {
      let ind = data?.body.findIndex((x) => x?.id === selected?.id);
      if (ind === 0) {
        setRectangleContent(
          "And that’s it! We just completed the first section of the article. Next we’ll do a section together and then you’ll do one yourself. But don’t worry, I’ll be there with you every step along the way. Don’t forget to use hints!"
        );
        setShowRectangle(true);
        setCustomSpeechContent({
          id: 0,
          content:
            "And that’s it! We just completed the first section of the article. Next we’ll do a section together and then you’ll do one yourself. But don’t worry, I’ll be there with you every step along the way. Don’t forget to use hints!",
        });
        setShowCustomSpeech(true);
        setShowFirstFinalScreen(true);
      }
      if (
        selectedType === "GIST" &&
        ind < data?.body?.length - 1 &&
        usedHintSection?.WHO_OR_WHAT > 0 &&
        usedHintSection?.MOST_IMPORTANT_INFO > 0 &&
        usedHintSection?.GIST > 0 &&
        false
      ) {
        // setSelectedSentence(selected.items[0]);
        handleMode("sentence");
        setSelected(data?.body[ind + 1]);
        let arr = [];
        const selectedSection =
          props?.data?.answers_and_clues?.content_answers?.gists || [];
        const selectedSectionAnswer = selectedSection.find(
          (x) => x.section_id === data?.body[ind + 1]?.id
        );
        data?.body[ind + 1].items.map((x) => {
          let img_url = "";
          let caption = "";
          x.items.map((y) => {
            if (y.type === "Image") {
              img_url = y?.url;
              caption = y?.caption;
            }
            if (y.type === "TextBlock") {
              y.lines.map((z) => {
                const sentenceAnswer = selectedSectionAnswer?.line_level.find(
                  (p) => p.line_text === z
                );
                arr.push({
                  line_text: z,
                  url: img_url,
                  caption: caption,
                  line_gist: sentenceAnswer?.line_gists,
                });
                return true;
              });
            }
            return true;
          });
          return true;
        });
        setSentenceOverview("sentence-intro");
        setSelectedSentenceArr(arr);
        setIndex2(0);
        setSelectedSentence(arr[0]); //revert back to 0
        setSelectedType("sentence");
        let ls = [];
        arr.map((x) => {
          ls.push({
            WHO_OR_WHAT: "",
            MOST_IMPORTANT_INFO: "",
            GIST: "",
            line_text: x?.line_text,
          });
          return true;
        });
        setFormDataSentence(ls);
        setSelectedTypeSentence("WHO_OR_WHAT");
        return;
      } else if (selectedType === "sentence") {
        if (selectedTypeSentence === "WHO_OR_WHAT") {
          setSelectedTypeSentence("MOST_IMPORTANT_INFO");
          return;
        }
        if (selectedSentenceArr?.length - 1 > index2) {
          setSelectedTypeSentence("WHO_OR_WHAT");
          setSelectedSentence(selectedSentenceArr[index2 + 1]);
          setIndex2(index2 + 1);
          return;
        } else if (
          sentenceOverview === "form" &&
          selectedSentenceArr.length - 1 === index2
        ) {
          setSentenceOverview("overview");
          return;
        }
      }
      setIndex2(0);
      if (ind < data?.body?.length - 1) {
        if (
          (usedHintSection?.WHO_OR_WHAT > 0 &&
            usedHintSection?.MOST_IMPORTANT_INFO > 0) ||
          (usedHintSection?.MOST_IMPORTANT_INFO > 0 &&
            usedHintSection?.GIST > 0) ||
          (usedHintSection?.GIST > 0 && usedHintSection?.WHO_OR_WHAT > 0)
        ) {
          handleMode("sentence");
        } else {
          handleMode();
        }
        setUsedHintSection({
          WHO_OR_WHAT: 0,
          MOST_IMPORTANT_INFO: 0,
          GIST: 0,
        });
        resetForm();

        setSelected(data?.body[ind + 1]);
        let clues = props?.data?.answers_and_clues?.content_answers?.gists.find(
          (x) => x.section_id === props?.data?.content?.body[ind + 1]?.id
        );
        setHighlightWord(
          clues?.section_level?.gists_clues?.highlight_word || ""
        );
        setCircleWord(clues?.section_level?.gists_clues?.circle_word || "");
        setUnderlineWord(
          clues?.section_level?.gists_clues?.underscore_phrases || []
        );
        setSentenceOverview("sentence-intro");
        setSelectedType("WHO_OR_WHAT");
        setShowMostImpInfo(false);
      } else {
        navigate("/apq/remind");
      }
    }
  };

  const handleMode = (from = "") => {
    if (from === "sentence") {
      setCurrentMode("WE_DO");
      return;
    }
    switch (currentMode) {
      case "I_DO":
        setCurrentMode("WE_DO");
        break;
      case "WE_DO":
        setCurrentMode("YOU_DO");
        break;
      default:
        setCurrentMode("YOU_DO");
        break;
    }
  };

  const resetForm = () => {
    setFormData({
      WHO_OR_WHAT: "",
      MOST_IMPORTANT_INFO: "",
      GIST: "",
    });
  };

  const onChangeInput = (val, key, type = "String") => {
    if (val === " ") {
      val = "";
    }
    if (key === "WHO_OR_WHAT") {
      setWhoWhatError("");
    }
    if (key === "MOST_IMPORTANT_INFO") {
      setMostImportantInfoError("");
    }
    if (key === "GIST") {
      setGistError("");
    }
    setFormData((prevState) => ({
      ...prevState,
      [key]: val,
    }));
    setShowCustomSpeech(false);
    if (showHintWhoWhatPopup || showHint3Popup) {
      handleClose();
    }
    if (showHintGistPopup) {
      handleClose();
    }
  };

  const onChangeInputSentence = (val, key, type = "String") => {
    // val = val.replace("  ", " ");
    if (val === " ") {
      val = "";
    }
    if (key === "GIST") {
      setFormData({
        ...formData,
        [key]: val,
      });
    }
    let temporaryArray = formDataSentence.slice();
    temporaryArray[index2][key] = val;
    setFormDataSentence(temporaryArray);
    if (showHintWhoWhatPopup || showHint3Popup || showHintGistPopup) {
      handleClose();
    }
  };

  const handleClose = () => {
    setShowHintWhoWhatPopup(false);
    setShowHintImportantPopup(false);
    setShowHintGistPopup(false);
  };

  const onKeyDownHandler = () => {
    onClickNext();
  };

  const onClickBear = () => {
    if (currentMode === "WE_DO") {
      return;
    }
    setHintShow(true);
  };

  const onClickRectangleBtn = () => {
    setShowRectangle(false);
    setRectangleContent("");
    setShowCustomSpeech(false);
    setCustomSpeechContent("");
    if (showFirstFinalScreen) {
      setShowFirstFinalScreen(false);
      return;
    }
    setHintData([
      {
        id: 0,
        content:
          currentMode === "I_DO" && isIDoEnable
            ? "Now lets walk through and identify most important information the paragraph is about. Click the flashing button to continue."
            : currentMode !== "YOU_DO"
            ? dynamic_messages?.section_hint2.replace(
                "{WHO_WHAT_ANSWER}",
                `<b>${formData?.WHO_OR_WHAT}</b>`
              )
            : "Now, lets write the answer about the most important information",
        btn_label: "Ok",
        type: "start_most_important_info",
        highlight_type:
          currentMode === "I_DO" && isIDoEnable ? "lesion_btn" : "",
      },
    ]);
    setSelectedHint({
      id: 0,
      content:
        currentMode === "I_DO" && isIDoEnable
          ? "Now lets walk through and identify most important information the paragraph is about. Click the flashing button to continue."
          : currentMode !== "YOU_DO"
          ? dynamic_messages?.section_hint2.replace(
              "{WHO_WHAT_ANSWER}",
              `<b>${formData?.WHO_OR_WHAT}</b>`
            )
          : "Now, lets write the answer about the most important information",
      btn_label: "Ok",
      type: "start_most_important_info",
      highlight_type: currentMode === "I_DO" && isIDoEnable ? "lesion_btn" : "",
    });
    setShowUnderline(true);
    if (currentMode === "I_DO" && isIDoEnable) {
      setHintShow(false);
    } else {
      setHintShow(true);
    }
    setShowMostImpInfo(true);
  };

  const handleSelectedOption = (index) => {
    let updatedOptions = options.map((option, i) => {
      return {
        ...option,
        className: i === index ? "container-selected-important" : "", // Set className for the selected index and reset others
      };
    });
    setOptions(updatedOptions);
  };

  const handleSelectedOptionForGist = (index) => {
    let updatedOptions = options.map((option, i) => {
      return {
        ...option,
        className: i === index ? "container-selected-important" : "", // Set className for the selected index and reset others
      };
    });
    setOptions(updatedOptions);
  };

  const getCurrentIndex = () => {
    let ind = data?.body?.findIndex((x) => x?.id === selected?.id);
    return ind + 1;
  };

  const getCurrentSentenceIndex = () => {
    let ind = selectedSentenceArr?.findIndex(
      (x) => x?.line_text === selectedSentence?.line_text
    );
    return ind + 1;
  };

  const onLoadSectionGist = () => {
    if (currentMode === "I_DO" && isIDoEnable) {
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.i_do_hint_for_gist,
          btn_label: "Ok",
          highlight_type: "lesion_btn",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.i_do_hint_for_gist,
        btn_label: "Ok",
        highlight_type: "lesion_btn",
      });
      // setHintShow(true);
    } else if (currentMode !== "WE_DO") {
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.section_hint3,
          btn_label: "OK",
          is_speech: true,
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.section_hint3,
        btn_label: "OK",
        is_speech: true,
      });
      setHintShow(true);
    }
  };

  const stopAudio = () => {
    // setIsPlaying(false);
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    // if ("speechSynthesis" in window) {
    //   if (speaking) {
    //     setSpeaking(false);
    //     window.speechSynthesis.cancel();
    //   }
    // }
  };

  // for we do flow start

  useEffect(() => {
    if (!showFirstFinalScreen && selectedType === "WHO_OR_WHAT") {
      onLoadBox();
    }
  }, [showFirstFinalScreen]);

  useEffect(() => {
    if (selectedType === "WHO_OR_WHAT") {
      onLoadBox();
    }
  }, [selectedType]);

  useEffect(() => {
    if (selectedType !== "sentence" && sectionGistType === "detail") {
      onLoadBox();
    }
  }, [sectionGistType]);

  useEffect(() => {
    if (
      selectedType === "sentence" &&
      selectedTypeSentence === "WHO_OR_WHAT" &&
      index2 !== 0
    ) {
      onLoadBox();
    }
  }, [selectedTypeSentence]);

  useEffect(() => {
    if (selectedType === "sentence" && sentenceOverview === "gist") {
      onLoadBox();
    }
  }, [sentenceOverview]);

  const onLoadBox = () => {
    if (currentMode === "WE_DO") {
      onClickNext("WE_DO");
    }
  };
  // for we do flow end

  const checkIsDoneValid = () => {
    const selected_count = options.filter(
      (x) => x?.className === "container-selected-important"
    );
    return selected_count?.length === 0;
  };

  const handleCorrection = (value) => {
    const words = value.split(" ");
    const lastWord = words.pop();

    if (typo && !typo.check(lastWord)) {
      const suggestions = typo.suggest(lastWord);
      const correctedWord = suggestions.length > 0 ? suggestions[0] : lastWord;
      return [...words, correctedWord].join(" ");
    }

    return value;
  };

  const handleCorrectionOnChange = (key, val = "", index = -1) => {
    if (!isAutoCorrect) {
      return;
    }
    if (selectedType !== "Sentence") {
      setFormData((prevState) => ({
        ...prevState,
        [key]: handleCorrection(prevState[key]),
      }));
    } else {
      if (key === "GIST") {
        setFormData((prevState) => ({
          ...prevState,
          [key]: handleCorrection(val),
        }));
      }
      let temporaryArray = formDataSentence.slice();
      temporaryArray[index2][key] = handleCorrection(val);
      setFormDataSentence(temporaryArray);
    }
  };

  const onFocusTextBox = () => {
    setHighlightedWhoWhat(false);
    setHighlightedMostImportant(false);
    setHighlightedGist(false);
  };

  const onCompleteParagraphReading = (from) => {
    if (from === "START") {
      setHintData([
        {
          id: 0,
          content:
            "Now that we have read the paragraph, lets walk through and identify who or what the paragraph is about. Click the flashing button to continue.",
          btn_label: "Ok",
        },
      ]);
      setSelectedHint({
        id: 0,
        content:
          "Now that we have read the paragraph, lets walk through and identify who or what the paragraph is about. Click the flashing button to continue.",
        btn_label: "Ok",
      });
      setHintShow(true);
    } else if (from === "END") {
      //   setHintData([
      //     {
      //       id: 0,
      //       content: "Click the flashing button to continue",
      //       btn_label: "Ok",
      //     },
      //   ]);
      //   setSelectedHint({
      //     id: 0,
      //     content: "Click the flashing button to continue",
      //     btn_label: "Ok",
      //   });
      setHintShow(false);
    }
  };

  const handleIDoStart = (content) => {
    setHintData([
      {
        id: 0,
        content,
        btn_label: "Ok",
        type: "i_do_mode_content",
      },
    ]);
    setSelectedHint({
      id: 0,
      content,
      btn_label: "Ok",
      type: "i_do_mode_content",
    });
    setHintShow(true);
  };

  return (
    <>
      <HeadSection
        onClickPreview={onClickPreview}
        onClickNext={onClickNext}
        onClickDone={onClickNext}
        showSubmitBtn={
          showRectangle ||
          (selectedType === "GIST" && sectionGistType === "intro") ||
          (selectedType === "sentence" &&
            (sentenceOverview === "sentence-intro" ||
              sentenceOverview === "overview"))
            ? false
            : true
        }
        selectedPassage={props?.selectedPassage}
        setSelectedPassage={props?.setSelectedPassage}
        hintShow={hintShow}
        highlightedSubmitButton={highlightedSubmitButton}
        currentMode={currentMode}
        selectedHint={selectedHint}
        onClickBtn={onClickHintBtn}
      />
      <div className={props?.selectedThemeClass}>
        {showRectangle ? (
          <Grid container spacing={2} className="mt-4">
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <RectangularCallout
                selected={{
                  id: 0,
                  content: rectangleContent,
                  btn_label: "Let's go!",
                }}
                type="SECTION"
                onClickBtn={onClickRectangleBtn}
              />
              <Hint
                data={[]}
                showVolume={false}
                from={"section-page"}
                audio={audio}
                setAudio={setAudio}
              />
              <Progress
                val={4 + getCurrentIndex()}
                totalSections={props?.totalSections}
              />
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        ) : selectedType === "" ||
          selectedType === "WHO_OR_WHAT" ||
          selectedType === "MOST_IMPORTANT_INFO" ? (
          <>
            <WhoWhatScreen
              title={`Section ${getCurrentIndex()} of ${
                data?.body?.length || 0
              }`}
              selected={selected}
              formData={formData}
              onChangeInput={onChangeInput}
              handleCorrectionOnChange={handleCorrectionOnChange}
              selectedType={selectedType}
              onKeyDownHandler={onKeyDownHandler}
              whoWhatError={whoWhatError}
              mostImportantInfoError={mostImportantInfoError}
              selectedHint={selectedHint}
              highlightedWhoWhat={highlightedWhoWhat}
              highlightMostImportant={highlightMostImportant}
              showMostImpInfo={showMostImpInfo}
              highlightWord={highlightWord}
              circleWord={circleWord}
              underlineWord={underlineWord}
              showUnderline={showUnderline}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              speaking={speaking}
              setSpeaking={setSpeaking}
              showParagraphHighlightAndCircle={showParagraphHighlightAndCircle}
              isAnswer={isAnswer}
              currentMode={currentMode}
              onLoadBox={onLoadBox}
              totalSections={props?.totalSections}
              getCurrentIndex={getCurrentIndex}
              hintShow={hintShow}
              highlightSubmitButton={highlightSubmitButton}
              answers_and_clues={
                props?.data?.answers_and_clues?.content_answers?.gists || []
              }
              onCompleteParagraphReading={onCompleteParagraphReading}
              handleIDoStart={handleIDoStart}
              onFocusTextBox={onFocusTextBox}
            />
          </>
        ) : selectedType === "GIST" ? (
          <>
            <label style={{ paddingLeft: "25px" }}>
              Section {getCurrentIndex()} of {data?.body?.length || 0}
            </label>
            <GistScreen
              selected={selected}
              formData={formData}
              onChangeInput={onChangeInput}
              handleCorrectionOnChange={handleCorrectionOnChange}
              selectedType={selectedType}
              onKeyDownHandler={onKeyDownHandler}
              gistError={gistError}
              onLoadSectionGist={onLoadSectionGist}
              isAnswer={isAnswer}
              sectionGistType={sectionGistType}
              setSectionGistType={setSectionGistType}
              highlightGist={highlightGist}
              setCustomSpeechContent={setCustomSpeechContent}
              setShowCustomSpeech={setShowCustomSpeech}
              currentIndex={getCurrentIndex()}
              totalSections={props?.totalSections}
              hintShow={hintShow}
              currentMode={currentMode}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              speaking={speaking}
              setSpeaking={setSpeaking}
              highlightSubmitButton={highlightSubmitButton}
              answers_and_clues={
                props?.data?.answers_and_clues?.content_answers?.gists || []
              }
              selectedHint={selectedHint}
              onCompleteParagraphReading={onCompleteParagraphReading}
              handleIDoStart={handleIDoStart}
              onFocusTextBox={onFocusTextBox}
            />
          </>
        ) : (
          <>
            <SentenceScreen
              title={`Sentence ${getCurrentSentenceIndex()} of ${
                selectedSentenceArr?.length || 0
              } (Section ${getCurrentIndex()} of ${data?.body?.length || 0})`}
              selected={selectedSentence}
              selectedSection={selected}
              sentenceOverview={sentenceOverview}
              setSentenceOverview={setSentenceOverview}
              formData={formDataSentence[index2]}
              onChangeInput={onChangeInputSentence}
              handleCorrectionOnChange={handleCorrectionOnChange}
              selectedType={selectedTypeSentence}
              finalValues={formDataSentence}
              onKeyDownHandler={onKeyDownHandler}
              whoWhatError={whoWhatError}
              mostImportantInfoError={mostImportantInfoError}
              gistError={gistError}
              highlightedWhoWhat={highlightedWhoWhat}
              highlightMostImportant={highlightMostImportant}
              highlightGist={highlightGist}
              setCustomSpeechContent={setCustomSpeechContent}
              setShowCustomSpeech={setShowCustomSpeech}
              onClickNext={onClickNext}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              speaking={speaking}
              setSpeaking={setSpeaking}
              answers_and_clues={
                props?.data?.answers_and_clues?.content_answers?.gists || []
              }
              setHintData={setHintData}
              setSelectedHint={setSelectedHint}
              setHintShow={setHintShow}
              selectedHint={selectedHint}
              isAnswer={isAnswer}
              currentMode={currentMode}
              onLoadBox={onLoadBox}
              totalSections={props?.totalSections}
              getCurrentIndex={getCurrentIndex}
              hintShow={hintShow}
            />
          </>
        )}
      </div>

      {((["WHO_OR_WHAT", "MOST_IMPORTANT_INFO", ""].includes(selectedType) &&
        !showRectangle) ||
        (selectedType === "GIST" && sectionGistType !== "intro") ||
        (selectedType === "sentence" &&
          sentenceOverview !== "sentence-intro")) && (
        <Hint
          show={hintShow}
          setShow={setHintShow}
          onClickBtn={onClickHintBtn}
          selected={selectedHint}
          onClickBear={onClickBear}
          showVolume={false}
          audio={audio}
          setAudio={setAudio}
          data={hintData}
        />
      )}
      <Task
        show={show}
        setShow={setShow}
        onClickBtn={onClickBtn}
        selected={selectedTask}
        onClickIcon={handleInactivity}
      />
      <Volume
        content={
          hintShow && selectedHint?.type !== "i_do_mode_content"
            ? selectedHint?.content
            : show
            ? selectedTask?.content
            : showCustomSpeech
            ? customSpeechContent?.content
            : ""
        }
        stopAudio={stopAudio}
        audio={audio}
        setAudio={setAudio}
        onClickBtn={onClickHintBtn}
        selected={selectedHint}
      />

      <Modal
        open={showHintWhoWhatPopup}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="show-hint-modal options-modal" sx={style}>
          <label>
            {customSpeechContent?.content
              ? customSpeechContent?.content.replaceAll(". .", "")
              : ""}
          </label>

          {options?.length > 0 &&
            options.map((item) => {
              return (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setCustomSpeechContent(false);
                    if (selectedType === "WHO_OR_WHAT") {
                      onChangeInput(item?.hint, "WHO_OR_WHAT", "String");
                    } else if (selectedType === "sentence") {
                      onChangeInputSentence(
                        item?.hint,
                        "WHO_OR_WHAT",
                        "String"
                      );
                    }
                  }}
                  style={{ backgroundColor: "#008080" }}
                >
                  {item?.hint}
                </Button>
              );
            })}
        </Box>
      </Modal>

      <Modal
        open={showHint3Popup}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="show-hint-modal-important options-modal" sx={style}>
          <label>
            {/* I have some ideas about what the most important information is here.
            Select all of the below options that are correct! Remember to click
            the submit button when you’re done. */}
            {customSpeechContent?.label_content}
          </label>
          <Grid container spacing={2} className="">
            {options.map((item, index) => (
              <Grid item xs={6}>
                <div
                  key={item.id}
                  onClick={() => handleSelectedOption(index)}
                  className={`most-important-ans-container ${item.className}`}
                >
                  <p className={`level-ans`}>{item.hint}</p>
                </div>
              </Grid>
            ))}
          </Grid>
          <Button
            className="hint-done-btn"
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#008080",
              float: "right",
              marginTop: "15px",
            }}
            onClick={() => {
              let selected_answer = "";
              options
                .filter((x) => x.className === "container-selected-important")
                .map((x) => {
                  selected_answer += x.hint + "\n";
                  return true;
                });
              if (selectedType === "MOST_IMPORTANT_INFO") {
                onChangeInput(selected_answer, "MOST_IMPORTANT_INFO", "String");
              } else {
                onChangeInputSentence(
                  selected_answer,
                  "MOST_IMPORTANT_INFO",
                  "String"
                );
              }
              handleClose();
            }}
            disabled={checkIsDoneValid()}
          >
            Submit
          </Button>
        </Box>
      </Modal>

      <Modal
        open={showHintGistPopup}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="show-gist-hint-modal options-modal" sx={style}>
          <label>
            {/* Nice try, but that’s not quite right! Here are some ideas about what
            the gist statement could be. Select one option that you think is
            correct! Remember to click the submit button when you’re done. */}
            {customSpeechContent?.label_content}
          </label>
          <Grid container spacing={2} className="">
            {options.map((item, index) => (
              <Grid item xs={6}>
                <div
                  key={item.id}
                  onClick={() => handleSelectedOptionForGist(index)}
                  className={`most-important-ans-container ${item.className}`}
                >
                  <p className={`level-ans`}>{item.hint}</p>
                </div>
              </Grid>
            ))}
          </Grid>
          <Button
            className="hint-done-btn"
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#008080",
              float: "right",
              marginTop: "15px",
            }}
            onClick={() => {
              let selected_answer = "";
              options
                .filter((x) => x.className === "container-selected-important")
                .map((x) => {
                  selected_answer += x.hint + " ";
                  return true;
                });
              setCustomSpeechContent(false);
              if (selectedType === "GIST") {
                onChangeInput(selected_answer, "GIST", "String");
              } else if (selectedType === "sentence") {
                onChangeInputSentence(selected_answer, "GIST", "String");
              }
            }}
            disabled={checkIsDoneValid()}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default SectionScreen;
