import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  audio: null,
  isHintAudioPlaying: false,
  speaking: false,
  showMeAudio: null,
  captionAudio: null
};

export const UiSlice = createSlice({
  name: "hintAudioStore",
  initialState,
  reducers: {
    setAudio: (state, action) => {
      state.audio = action.payload;
    },
    setIsHintAudioPlaying: (state, action) => {
      state.isHintAudioPlaying = action.payload;
    },
    setSpeaking: (state, action) => {
      state.speaking = action.payload;
    },
    setShowMeAudio: (state, action) => {
      state.showMeAudio = action.payload;
    },
    setCaptionAudio: (state, action) => {
      state.captionAudio = action.payload;
    },
  },
});

export const { setAudio, setIsHintAudioPlaying, setSpeaking, setShowMeAudio, setCaptionAudio } = UiSlice.actions;
export default UiSlice.reducer;
