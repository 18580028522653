import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./reducer/auth";
import uiReducer from "./reducer/ui";
import speakingReducer from "./reducer/speaking";
import paragraphAudioReducer from "./reducer/paragraphAudio";
import hintAudioReducer from "./reducer/hintAudio";
import appReducer from "./reducer/appReducers";
import sectionReducer from "./reducer/sectionReducer";
import themeReducer from "./reducer/themeReducer";

const reducers = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  speakingStore: speakingReducer,
  paragraphAudioStore: paragraphAudioReducer,
  hintAudioStore: hintAudioReducer,
  appStore: appReducer,
  sectionStore: sectionReducer,
  themeStore: themeReducer
});

const persistConfig = {
  key: "cali",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
