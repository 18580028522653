import TextField from "@mui/material/TextField";

import "../../constants/styles.scss";
import "./styles.scss";

const Input = ({
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  errorText = "",
  className,
  disabled = false,
}) => {
  return (
    <div className={className + " input-main-container-style"}>
      <div className="input-container-style">
        <TextField
          type={type}
          placeholder={placeholder}
          id="outlined-error-helper-text"
          label={label}
          value={value}
          error={errorText ? true : false}
          helperText={errorText}
          size="small"
          disabled={disabled}
          fullWidth
          onChange={onChange}
          autoComplete="off"
        />
        {/* {rightSideIcon && (
          <button
            className="input-icon-container-style"
            onClick={onClickRightSideIcon}
          >
            {rightSideIcon}
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Input;
