import React, { useEffect, useState } from "react";
import "./styles.scss";
import HeadSection from "../../components/headSection";
import Hint from "../../components/hint";
import Volume from "../../components/volume";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Detail from "./detail";
import Progress from "../../components/progress";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import dummyImage from "../../assets/images/dummy.svg";
import Button from "@mui/material/Button";
import useDisableBrowserBack from "../../components/useDisableBrowserBack";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  minHeight: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  textAlign: "center",
  display: "table",
};

const Vocab = (props) => {
  useDisableBrowserBack();
  const navigate = useNavigate();
  const { dynamic_messages } = useSelector((state) => state.appStore);
  const [data, setData] = useState([]);
  const [step, setStep] = useState(0);
  const [modelStep, setModelStep] = useState(0);
  const [hintData, setHintData] = useState([
    {
      id: 0,
      content: dynamic_messages?.vocab_hint1,
      btn_label: "OK",
    },
  ]);

  const [hintShow, setHintShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [handleError, setHandleError] = useState(false);
  const [selectedHint, setSelectedHint] = useState({
    id: 0,
    content: dynamic_messages?.vocab_hint1,
    btn_label: "OK",
  });

  const [customSpeechContent, setCustomSpeechContent] = useState("");
  const [showCustomSpeech, setShowCustomSpeech] = useState(false);
  const [highlightedSubmitButton, setHighlightedSubmitButton] = useState(false);
  const { isHintAudioPlaying, speaking } = useSelector(
    (state) => state.hintAudioStore
  );

  const [audio, setAudio] = useState(null);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (!isHintAudioPlaying) {
      setShowCustomSpeech(false);
      setCustomSpeechContent("");
    }
    if (!isHintAudioPlaying && speaking && isAllSelectedCorrect()) {
      highlightSubmitButtonBox();
    } else if (isHintAudioPlaying) {
      setHighlightedSubmitButton(false);
    }
  }, [isHintAudioPlaying]);

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name: "Vocab",
        properties: { username: props.userData?.username || "" },
      });
    }
  }, [appInsights]);

  const shuffleArray = (array) => {
    if (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    } else {
      return [];
    }
  };

  // Shuffle the array of answers
  const shuffledAnswers = shuffleArray(
    props?.data?.match_list.map((item) => item.meaning)
  );

  useEffect(() => {
    if (props?.data) {
      const itemsWithRightItem = props?.data?.match_list.map((item, index) => ({
        ...item,
        right_item: shuffledAnswers[index],
        className: "",
        is_updated: false,
      }));
      setData(itemsWithRightItem);
    }
  }, [props?.data]);

  const highlightSubmitButtonBox = () => {
    setHighlightedSubmitButton(true);
    // setTimeout(() => setHighlightedSubmitButton(false), 8000);
  };

  const onClickHintBtn = () => {
    if (selectedHint?.id < hintData.length - 1) {
      setSelectedHint(hintData[selectedHint?.id + 1]);
    } else {
      if (selectedHint?.type === "open_model") {
        setOpen(true);
        setHintShow(false);
        return;
      }
      if (selectedHint?.type === "redirect_next") {
        setHintShow(false);
        onClickNext();
        return;
      }
      setHintShow(false);
    }
  };

  const onClickPreview = () => {
    navigate("/flashcard");
  };

  const onMissMatchMeaning = () => {
    setHintData([
      {
        id: 0,
        content: dynamic_messages?.vocab_error_hint1,
        btn_label: "OK",
      },
    ]);
    setSelectedHint({
      id: 0,
      content: dynamic_messages?.vocab_error_hint1,
      btn_label: "OK",
    });
    setHintShow(true);
  };

  const onClickNext = () => {
    setHighlightedSubmitButton(false);
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    if (!isAllSelectedCorrect()) {
      // setStep(1);
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.vocab_error_hint1,
          btn_label: "OK",
        },
      ]);
      if (handleError) {
        setSelectedHint({
          id: 0,
          content: dynamic_messages?.vocab_error_hint1,
          btn_label: "OK",
        });
        setHandleError(false);
      } else {
        setSelectedHint({
          id: 0,
          content: dynamic_messages?.vocab_error_hint1,
          btn_label: "OK",
        });
        setHandleError(true);
      }

      setHintShow(true);
      return;
    } else if (step === 0) {
      setStep(1);
      setHintData([
        {
          id: 0,
          content: dynamic_messages?.vocab_hint2,
          btn_label: "Ok",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: dynamic_messages?.vocab_hint2,
        btn_label: "Ok",
        type: "redirect_next",
      });
      setHintShow(true);
      return;
    }

    navigate("/intro/purpose");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setIsFlipped(!isFlipped); // Toggle the state
  };

  const isAllSelectedCorrect = () => {
    const newArr = data.filter((x) => x.className === "match-vocab-answer");
    return newArr?.length === data?.length;
  };

  const handleDataOnDragEnd = (arr) => {
    let content = "";
    arr.map((x) => {
      if (x?.className === "match-vocab-answer") {
        content += `${x?.word}. . ${x?.right_item}. . `;
      }
      return true;
    });
    if (content) {
      setShowCustomSpeech(true);
      setCustomSpeechContent(content);
    } else {
      setShowCustomSpeech(false);
      setCustomSpeechContent("");
    }
    if (!speaking && isAllSelectedCorrect()) {
      highlightSubmitButtonBox();
    }
  };

  const onClickBear = () => {
    setHintShow(true);
  };

  return (
    <>
      <HeadSection
        onClickPreview={onClickPreview}
        onClickNext={onClickNext}
        onClickDone={onClickNext}
        selectedPassage={props?.selectedPassage}
        setSelectedPassage={props?.setSelectedPassage}
        hintShow={hintShow}
        highlightedSubmitButton={highlightedSubmitButton}
      />
      <Grid
        container
        spacing={2}
        className={`mt-2 ${props.selectedThemeClass}`}
      >
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Detail
            data={data}
            setData={setData}
            handleDataOnDragEnd={handleDataOnDragEnd}
            onMissMatchMeaning={onMissMatchMeaning}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Progress
            val={3}
            margin={"25px 20px 25px 0px"}
            totalSections={props?.totalSections}
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Hint
        show={hintShow}
        setShow={setHintShow}
        onClickBtn={onClickHintBtn}
        selected={selectedHint}
        showVolume={false}
        onClickBear={onClickBear}
        audio={audio}
        setAudio={setAudio}
      />

      <Volume
        content={
          hintShow
            ? selectedHint?.content
            : showCustomSpeech
            ? customSpeechContent
            : ""
        }
        audio={audio}
        setAudio={setAudio}
        onClickBtn={onClickHintBtn}
      />

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="show-hint-modal-important" sx={style}>
          <h4>
            <b>Erosion</b>
          </h4>
          <div className={`image-container ${!isFlipped ? "flipped" : ""}`}>
            <div className="card-cust">
              {!isFlipped ? (
                <div className="front">
                  <img
                    src={dummyImage}
                    alt="dummy"
                    width="100%"
                    onClick={handleClick}
                  />
                </div>
              ) : (
                <div className="front">
                  <div className="content">
                    <label
                      style={{
                        cursor: "pointer",
                        width: "300px",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {modelStep === 0
                        ? "Erosion is the process of eroding or being eroded by wind, water, or other natural agents."
                        : "Now let’s give it another try!"}
                      <br />
                      <Button
                        className="btn-trans-n"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          if (modelStep === 0) {
                            setModelStep(1);
                          } else {
                            handleClose();
                          }
                        }}
                        style={{
                          backgroundColor: "#008080",
                          marginTop: "5px",
                          float: "right",
                        }}
                      >
                        Ok
                      </Button>
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Vocab;
