import React, { useEffect, useState } from "react";
import dummyImage from "../../../../assets/images/dummy.svg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const Detail = (props) => {
  const { selected, currentMode, showClues } = props;

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);

  const [highlightWord, setHighlightWord] = useState("");
  const [circleWord, setCircleWord] = useState("");
  const [underlineWord, setUnderlineWord] = useState([]);

  useEffect(() => {
    if (selected) {
      setHighlightWord(
        selected?.line_gist[0]?.gists_clues?.highlight_word || ""
      );
      setCircleWord(selected?.line_gist[0]?.gists_clues?.circle_word || "");
      setUnderlineWord(
        selected?.line_gist[0]?.gists_clues?.underscore_phrases || []
      );
    }
  }, [selected]);

  const renderTextItems = (paragraph) => {
    const regex1 = new RegExp(" " + highlightWord, "gi");
    const regex2 = new RegExp(" " + circleWord, "gi");

    let replacedText1 = paragraph;
    if (showClues && underlineWord && currentMode != "YOU_DO") {
      underlineWord.map((x) => {
        const regex3 = new RegExp(x, "gi");
        replacedText1 = replacedText1.replace(regex3, (match) => {
          if (match === match.toUpperCase()) {
            return `<span class="underline-word">${match}</span>`;
          } else {
            return `<span class="underline-word">${match}</span>`;
          }
        });
      });
    }

    let replacedText2 = "";
    if (showClues && highlightWord && currentMode != "YOU_DO") {
      replacedText2 = replacedText1.replace(regex1, (match) => {
        if (match === match.toUpperCase()) {
          return ` <span class="highlighted-ptext">${match}</span>`;
        } else {
          return ` <span class="highlighted-ptext">${match}</span>`;
        }
      });
    } else {
      replacedText2 = replacedText1;
    }

    let replacedText3 = "";
    if (showClues && circleWord && currentMode != "YOU_DO") {
      replacedText3 = replacedText2.replace(regex2, (match) => {
        if (match === match.toUpperCase()) {
          return ` <span class="circle-ptext">${match}</span>`;
        } else {
          return ` <span class="circle-ptext">${match}</span>`;
        }
      });
    } else {
      replacedText3 = replacedText2;
    }

    return <div dangerouslySetInnerHTML={{ __html: replacedText3 }} />;
  };

  const handlePopup = () => {
    setOpen(!open);
  };

  return (
    <div className="detail-outer-sentence" style={{ display: "flex" }}>
      {selected?.url && (
        <img
          src={selected?.url || dummyImage}
          alt="who what image"
          className="who-what-img"
          onClick={() => {
            handlePopup();
            setSelectedImage(selected);
          }}
          style={{ cursor: "pointer", width: "230px" }}
        />
      )}
      {renderTextItems(selected?.line_text)}
      {/* <p>{selected?.line_text}</p> */}

      <Modal
        open={open}
        // onClose={handlePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="show-image-detail-modal" sx={style}>
          <img
            src={selectedImage?.url}
            alt="who what image"
            className="who-what-img"
            style={{ width: "100%" }}
          />
          <br />
          <span style={{ fontSize: "18px" }}>{selectedImage?.caption}</span>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#008080",
              float: "right",
              marginTop: "10px",
            }}
            onClick={() => {
              handlePopup();
            }}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Detail;
