import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import noImage from "../../../assets/images/no-image.svg";

const Detail = (props) => {
  const { type, selected, setSelected, isFlipped, setIsFlipped } =
    props;

  const [data, setData] = useState([]);

  useEffect(() => {
    const arr = props.data.map((x, index) => {
      return {
        ...x,
        id: index + 1,
      };
    });
    setData(arr);
    setSelected(arr[0]);
  }, [type, props?.data]);

  const onClickPreview = () => {
    setIsFlipped(false);
    let ind = data.findIndex((x) => x?.id === selected?.id);
    if (ind > 0) {
      setSelected(data[ind - 1]);
    }
  };

  const onClickNext = () => {
    setIsFlipped(false);
    let ind = data.findIndex((x) => x?.id === selected?.id);
    if (ind < data.length - 1) {
      setSelected(data[ind + 1]);
    }
  };

  const handleClick = () => {
    setIsFlipped(!isFlipped); // Toggle the state
  };

  return (
    <div className="flashcard-detail-outer">
      {type === "image" && (
        <h4>
          <b>{selected?.word}</b>
        </h4>
      )}
      {selected?.type === "youtube" && type === "video" ? (
        <YouTube opts={{ height: 300 }} videoId={selected?.url} />
      ) : type === "video" ? (
        <video
          src={selected?.url}
          controls
          style={{ width: "80%", display: "block", margin: "0 auto" }}
        />
      ) : selected ? (
        <div
          className={`image-container ${!isFlipped ? "flipped" : ""}`}
          onClick={handleClick}
        >
          <div className="card-cust">
            {!isFlipped ? (
              <div className="front">
                <img
                  src={selected?.image_url}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = noImage;
                  }}
                  alt="flashcard"
                  className="flashcard-img"
                />
              </div>
            ) : (
              <div className="front">
                <div className="content">
                  <h5>{selected?.meaning}</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {type === "image" && (
        <>
          <SkipPreviousIcon
            fontSize="large"
            style={{ cursor: "pointer" }}
            onClick={onClickPreview}
          />
          <b style={{ fontSize: "12px", margin: "0 10px" }}>
            {selected?.id}/{data?.length}
          </b>
          <SkipNextIcon
            fontSize="large"
            style={{ cursor: "pointer" }}
            onClick={onClickNext}
          />
        </>
      )}
    </div>
  );
};

export default Detail;
