import React, { useState, useContext } from "react";
import userIcon from "../../../assets/icons/user.svg";
import homeIcon from "../../../assets/icons/home.svg";
// import listIcon from "../../../assets/icons/list.png";
import timeIcon from "../../../assets/icons/time.svg";
import "../../../constants/styles.scss";
import "./styles.scss";
import { TimerContext } from "../../../components/timerContext";
import { useDispatch, useSelector } from "react-redux";
import { setAudio } from "../../../store/reducer/paragraphAudio";
import { setTextSize } from "../../../store/reducer/themeReducer";
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Box,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WidgetsIcon from "@mui/icons-material/Widgets";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import SettingDrawer from "../../../components/settingDrawer";
import FormatSizeOutlinedIcon from "@mui/icons-material/FormatSizeOutlined";
import { Icon } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

const Header = (props) => {
  const { onChangeRoutes, user_data } = props;
  const roles = JSON.parse(user_data?.roles.replace(/'/g, '"'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timer = useContext(TimerContext);

  const { audio } = useSelector((state) => state.paragraphAudioStore);
  const { textSize } = useSelector((state) => state.themeStore);

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [textAnchorEl, setTextAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Handlers for profile popover
  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  // Handlers for new menu popover
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // Handlers for text size popover
  const handleTextClick = (event) => {
    setTextAnchorEl(event.currentTarget);
  };

  const handleTextClose = () => {
    setTextAnchorEl(null);
  };

  const profileOpen = Boolean(profileAnchorEl);
  const menuOpen = Boolean(menuAnchorEl);
  const textOpen = Boolean(textAnchorEl);
  const profileId = profileOpen ? "profile-popover" : undefined;
  const menuId = menuOpen ? "menu-popover" : undefined;
  const textId = textOpen ? "text-popover" : undefined;

  const handleLogout = () => {
    setProfileAnchorEl(null);
    localStorage.clear();
    window.location.reload();
    console.log("Logged out");
  };

  const formatTime = () => {
    const minutes = Math.floor(timer?.timer / 60);
    const seconds = timer?.timer % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleMenuChange = (url) => {
    setMenuAnchorEl(null);
    navigate(url);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setProfileAnchorEl(null);
    setIsDrawerOpen(open);
  };

  const handleTextSizeToggle = (val) => {
    dispatch(setTextSize(val));
    handleTextClose();
  };

  const playPauseTimer = () => {
    if (timer?.isRunning) {
      timer?.pauseTimer();
    } else {
      timer?.resumeTimer();
    }
  };

  return (
    <>
      <div className="dashboard-header-container">
        <div className="dashboard-header-inner-container">
          <div
            className="dashboard-header-user-profile-text-container ms-0 cursor-pointer"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={homeIcon}
              alt="home-icon"
              className="rounded-icon cursor-pointer" // Add a class for rounded borders
              onClick={() => {
                onChangeRoutes(-1);
                if (audio) {
                  audio.pause();
                  audio.currentTime = 0;
                  dispatch(setAudio(audio));
                }
              }}
              style={{ width: "40px", height: "40px" }}
            />
            <h5
              className="header-font-change mx-3"
              style={{ color: "white", position: "relative" }}
            >
              <b onClick={handleTextClick} style={{ marginLeft: "5px" }}>
                <Icon className="text-A-1">A</Icon>
                <Icon className="text-A-2">A</Icon>
              </b>
            </h5>
            <Popover
              id={textId}
              open={textOpen}
              anchorEl={textAnchorEl}
              onClose={handleTextClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <List>
                <ListItem
                  button
                  onClick={() => handleTextSizeToggle("small")}
                  className={
                    textSize === "small" ? "active-text-size-dropdown" : ""
                  }
                >
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <FormatSizeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Small" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleTextSizeToggle("medium")}
                  className={
                    textSize === "medium" ? "active-text-size-dropdown" : ""
                  }
                >
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <FormatSizeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Medium" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleTextSizeToggle("large")}
                  className={
                    textSize === "large" ? "active-text-size-dropdown" : ""
                  }
                >
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <FormatSizeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Large" />
                </ListItem>
              </List>
            </Popover>
            {/* <img
              src={listIcon}
              alt="list-icon"
              className="rounded-icon cursor-pointer" // Add a class for rounded borders
              onClick={() => {
                onChangeRoutes(-1);
                if (audio) {
                  audio.pause();
                  audio.currentTime = 0;
                  dispatch(setAudio(audio));
                }
              }}
              style={{ width: "40px", height: "40px" }}
            /> */}
          </div>
        </div>
        <div className="dashboard-header-inner-container timer-section">
          <div
            style={{
              margin: "10px auto 0 auto",
              display: "inline-flex",
              backgroundColor: "#d9d9d9",
              borderRadius: "100px",
              padding: "5px",
              position: "relative", // Adjust width as needed
              top: "-5px",
            }}
            className="timer-main-label"
          >
            <img
              src={timeIcon}
              alt="time-icon"
              width={25}
              className="timer-clock-icon"
              style={{
                borderRadius: "50%",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
            />
            <div>
              <h5 style={{ margin: 0 }}>
                <b>{formatTime()}</b>
              </h5>
            </div>
            <div
              style={{
                fontSize: 18,
                position: "absolute",
                top: "4px",
              }}
              className="min-label"
            >
              <p>min</p>
            </div>
          </div>
        </div>
        <div className="dashboard-header-inner-container">
          <div style={{ marginLeft: "auto", display: "flex" }}>
            {/* {timer?.timer > 0 &&
              (timer?.isRunning ? (
                <PauseCircleOutlineIcon className="play-pause-timer" onClick={playPauseTimer} />
              ) : (
                <PlayCircleOutlineIcon className="play-pause-timer" onClick={playPauseTimer} />
              ))} */}
            {roles.includes("admin") && (
              <WidgetsIcon
                width={30}
                style={{
                  borderRadius: "50%",
                  border: 0,
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  color: "white",
                  marginTop: "5px",
                }}
                onClick={handleMenuClick}
              />
            )}
            <img
              src={userIcon}
              alt="profile-icon"
              width={35}
              style={{
                borderRadius: "50%",
                cursor: "pointer",
                padding: "2px 4px",
              }}
              onClick={handleProfileClick}
            />

            <Popover
              id={menuId}
              open={menuOpen}
              anchorEl={menuAnchorEl}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List>
                <ListItem button onClick={() => handleMenuChange("/register")}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Create New Account" />
                </ListItem>
              </List>
            </Popover>

            {/* Popover for Profile Options */}
            <Popover
              id={profileId}
              open={profileOpen}
              anchorEl={profileAnchorEl}
              onClose={handleProfileClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List>
                {/* My Profile Option */}
                <ListItem
                  button
                  onClick={() => console.log("My Profile clicked")}
                >
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Profile" />
                </ListItem>

                {/* Settings Option */}
                <ListItem button onClick={toggleDrawer(true)}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <SettingsApplicationsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>

                {/* Logout Option */}
                <ListItem button onClick={() => handleLogout()}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <ExitToAppOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </Popover>
          </div>
        </div>

        {/* Drawer for Settings */}
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
        >
          <Box
            sx={{ width: 650, padding: "16px 16px 0 16px" }}
            role="presentation"
          >
            {/* Close Icon */}
            <IconButton
              onClick={toggleDrawer(false)}
              style={{ position: "absolute", top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <SettingDrawer />
          </Box>
        </Drawer>
      </div>
    </>
  );
};

export default Header;
