import React, { useEffect, useState } from "react";
import "./styles.scss";
import Detail from "./detail";
import GistScreen from "./gist";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Progress from "../../../components/progress";
import Hint from "../../../components/hint";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import RectangularCallout from "../../../components/rectangularCallout";
import { setAudio } from "../../../store/reducer/paragraphAudio";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../apis/api";
import { setLoader } from "../../../store/reducer/ui";
import { setPause } from "../../../store/actions/appActions";

const SentenceScreen = (props) => {
  const {
    selected,
    sentenceOverview,
    selectedType,
    formData,
    onChangeInput,
    handleCorrectionOnChange,
    finalValues,
    onKeyDownHandler,
    whoWhatError,
    mostImportantInfoError,
    gistError,
    isAnswer,
    highlightedWhoWhat,
    highlightMostImportant,
    highlightGist,
    setCustomSpeechContent,
    setShowCustomSpeech,
    onClickNext,
    isPlaying,
    setIsPlaying,
    speaking,
    setSpeaking,
    setSentenceOverview,
    setHintData,
    setSelectedHint,
    setHintShow,
    selectedHint,
    currentMode,
    onLoadBox,
    hintShow,
  } = props;

  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.paragraphAudioStore);
  const { audio: audioHint } = useSelector((state) => state.hintAudioStore);
  const { pause } = useSelector((state) => state.appStore);
  // const [pause, setPause] = useState(false);

  const [contentArr, setContentArr] = useState([
    {
      id: 0,
      content:
        "You’re doing a great job working hard but we’re going to break down the paragraph one sentence at a time. The sentence level is great for clearer understanding, let’s go see what that looks like!",
      btn_label: "Let`s go",
    },
  ]);
  const [selectedContent, setSelectedContent] = useState({
    id: 0,
    content:
      "You’re doing a great job working hard but we’re going to break down the paragraph one sentence at a time. The sentence level is great for clearer understanding, let’s go see what that looks like!",
    btn_label: "Let`s go",
  });

  const [highlightedPlay, setHighlightedPlay] = useState(false);
  const [showClues, setShowClues] = useState(false);

  // const [audio, setAudio] = useState(null);

  const [sectionImages, setSectionImages] = useState([]);

  useEffect(() => {
    let arr = [];
    props.selectedSection.items.map((x) => {
      x.items.map((y) => {
        if (y.type == "Image") {
          arr.push(y);
        }
      });
    });
    setSectionImages(arr);
  }, []);

  useEffect(() => {
    setCustomSpeechContent({
      id: 0,
      content:
        "You’re doing a great job working hard but we’re going to break down the paragraph one sentence at a time. The sentence level is great for clearer understanding, let’s go see what that looks like!",
      btn_label: "Let`s go",
    });
    setShowCustomSpeech(true);
  }, []);

  useEffect(() => {
    if (selectedHint?.highlight_type == "play_btn") {
      setHighlightedPlay(true);
      setTimeout(() => setHighlightedPlay(false), 8000);
    }
    if (selectedHint?.type == "show_sentence_clues") {
      setShowClues(true);
      onLoadBox();
    }
  }, [selectedHint]);

  const onClickBtn = () => {
    if (selectedContent?.id < contentArr.length - 1) {
      setSelectedContent(contentArr[selectedContent?.id + 1]);
      setCustomSpeechContent(contentArr[selectedContent?.id + 1]);
      setShowCustomSpeech(true);
    } else {
      setShowCustomSpeech(false);
      setSentenceOverview("form");
      setHintData([
        {
          id: 0,
          content:
            "I’ll read the sentence for you first. Click on the play button on the left side below the sentence. ",
          btn_label: "Ok",
          highlight_type: "play_btn",
        },
        {
          id: 1,
          content: `Now let’s identify the who or what of the sentence.${
            currentMode != "YOU_DO" ? " I have highlighted the main words." : ""
          }`,
          btn_label: "Ok",
          highlight_type: "who_what_sentence",
          type: "show_sentence_clues",
        },
      ]);
      setSelectedHint({
        id: 0,
        content: `Now let’s identify the who or what of the sentence.${
          currentMode != "YOU_DO" ? " I have highlighted the main words." : ""
        }`,
        btn_label: "Ok",
        highlight_type: "play_btn",
      });
      setHintShow(true);
      // onClickNext();
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (speaking) {
      setSpeaking(false);
      audio.pause();
      audio.currentTime = 0;
      return;
    }
    let content = selected?.line_text;
    setSpeaking(true);
    if (content) {
      getSpeech(content);
    }

    // setIsPlaying(!isPlaying);
    // if ("speechSynthesis" in window) {
    //   if (speaking) {
    //     setSpeaking(false);
    //     window.speechSynthesis.cancel();
    //     return;
    //   }
    //   let content = selected?.line_text;

    //   const speechChunks = chunkText(content, 200);
    //   setSpeaking(true);
    //   const speakNextChunk = (index) => {
    //     if (index < speechChunks.length) {
    //       const speech = new SpeechSynthesisUtterance(speechChunks[index]);
    //       speech.lang = "en-US";
    //       speech.voice = window.speechSynthesis
    //         .getVoices()
    //         .filter(function (voice) {
    //           return (
    //             (voice.name == "Microsoft Zira - English (United States)" ||
    //               voice?.name ==
    //                 "Microsoft Zira Desktop - English (United States)") &&
    //             voice.lang == "en-US"
    //           );
    //         })[0];
    //       window.speechSynthesis.speak(speech);
    //       speech.onend = () => {
    //         speakNextChunk(index + 1); // Speak the next chunk when the current one ends
    //         stopAudio();
    //       };
    //     } else {
    //       setSpeaking(false); // Update speaking state when all chunks are spoken
    //     }
    //   };
    //   speakNextChunk(0);
    // } else {
    //   console.log("Speech synthesis not supported in this browser.");
    // }
  };

  const togglePlayResume = () => {
    if (speaking && audio) {
      if (pause) {
        audio.play();
        if (audioHint) {
          audioHint.pause();
        }
        setIsPlaying(true);
        dispatch(setPause(false));
      } else {
        audio.pause();
        dispatch(setPause(true));
        setIsPlaying(false);
      }
    }

    // if ("speechSynthesis" in window) {
    //   if (speaking) {
    //     if (!window.speechSynthesis.speaking) {
    //       setIsPlaying(false);
    //       setSpeaking(false);
    //       setPause(false);
    //       togglePlayPause();
    //       return;
    //     }
    //     if (pause) {
    //       window.speechSynthesis.resume();
    //       setPause(false);
    //     } else {
    //       window.speechSynthesis.pause();
    //       setPause(true);
    //     }
    //   }
    // }
  };

  const stopAudio = () => {
    setIsPlaying(false);
    setSpeaking(false);
    dispatch(setPause(false));
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }

    // if ("speechSynthesis" in window) {
    //   if (speaking) {
    //     setSpeaking(false);
    //     setPause(false);
    //     window.speechSynthesis.cancel();
    //   }
    // }
  };

  const chunkText = (str, maxLength) => {
    const words = str.split(" "); // Split the string into words
    let result = [];
    let currentLine = "";

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (currentLine.length === 0) {
        currentLine += word;
      } else if (currentLine.length + 1 + word.length <= maxLength) {
        currentLine += " " + word;
      } else {
        result.push(currentLine);
        currentLine = word;
      }
    }
    if (currentLine.length > 0) {
      result.push(currentLine);
    }

    return result;
  };

  // online speech
  const getSpeech = async (text) => {
    try {
      if (!text) {
        return;
      }
      dispatch(setLoader(true));
      let payload = {
        text,
      };
      const response = await api.post("/aispeech", payload);
      const audioBlob = base64toBlob(
        response.data.audio.replace("b'", "").replace("'", "")
      );
      const audioBlobUrl = URL.createObjectURL(audioBlob);
      playAudio(audioBlobUrl);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  const base64toBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "audio/mpeg" });
    return blob;
  };

  const playAudio = (audioUrl) => {
    const audio1 = new Audio(audioUrl);
    dispatch(setAudio(audio1));
    if (audioHint) {
      audioHint.pause();
    }
    audio1
      .play()
      .catch((error) => console.error("Error playing audio:", error));
    audio1.onended = function () {
      stopAudio();
    };
  };
  // online speech end

  return (
    <>
      <Grid
        container
        spacing={2}
        className=""
        style={{ maxHeight: "80vh", overflow: "auto" }}
      >
        {sentenceOverview == "form" && (
          <>
            <Grid item xs={8}>
              <label style={{ paddingLeft: "25px" }}>{props?.title}</label>
              <Detail
                selected={selected}
                type="WHO_WHAT"
                currentMode={currentMode}
                showClues={showClues}
              />

              <div>
                {isPlaying && !pause ? (
                  <PauseCircleOutlineIcon
                    fontSize="large"
                    style={{
                      marginRight: "10px",
                      margin: "15px",
                      cursor: "pointer",
                      color: "#008080",
                    }}
                    className={`${highlightedPlay ? "highlighted" : ""}`}
                    onClick={!speaking ? togglePlayPause : togglePlayResume}
                  />
                ) : (
                  <PlayCircleOutlineIcon
                    fontSize="large"
                    style={{
                      marginRight: "30px",
                      margin: "15px",
                      cursor: "pointer",
                      color: "#008080",
                    }}
                    className={`${highlightedPlay ? "highlighted" : ""}`}
                    onClick={!speaking ? togglePlayPause : togglePlayResume}
                  />
                )}
                <StopCircleOutlinedIcon
                  fontSize="large"
                  style={{
                    cursor: "pointer",
                    color: !isPlaying ? "grey" : "#008080",
                  }}
                  onClick={stopAudio}
                  title="Stop"
                />
              </div>

              <Progress
                val={4 + props.getCurrentIndex()}
                margin={"15px"}
                totalSections={props?.totalSections}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid>
                <label>
                  <b>Who/What</b>
                </label>
                <TextField
                  type="text"
                  placeholder="who/what"
                  className={`${
                    highlightedWhoWhat ? "highlighted-textfield" : ""
                  }`}
                  id="Erosion"
                  label=""
                  value={formData?.WHO_OR_WHAT}
                  size="small"
                  fullWidth
                  // style={{ background: "#d9d9d9" }}
                  style={{
                    background:
                      selectedType == "WHO_OR_WHAT" && isAnswer != 3
                        ? "white"
                        : "#d9d9d9",
                  }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled={
                    selectedType != "WHO_OR_WHAT" || isAnswer == 3 || hintShow
                  }
                  onChange={(e) => {
                    onChangeInput(e.target.value, "WHO_OR_WHAT");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") onKeyDownHandler();
                    if (e.key == " ") handleCorrectionOnChange("WHO_OR_WHAT");
                  }}
                  onBlur={() => {
                    handleCorrectionOnChange("WHO_OR_WHAT");
                  }}
                  error={whoWhatError ? true : false}
                  autoComplete="off"
                />
              </Grid>
              <br />
              {selectedType == "MOST_IMPORTANT_INFO" && (
                <Grid>
                  <label>
                    <b>Most Important Information</b>
                  </label>
                  <TextField
                    type="text"
                    placeholder="Notes about the most important information about the main idea"
                    className={`${
                      highlightMostImportant ? "highlighted-textfield" : ""
                    }`}
                    id="answer"
                    label=""
                    value={formData?.MOST_IMPORTANT_INFO}
                    size="small"
                    fullWidth
                    // style={{ background: "#D9D9D9" }}
                    style={{
                      background:
                        selectedType == "MOST_IMPORTANT_INFO" && isAnswer != 3
                          ? "white"
                          : "#d9d9d9",
                    }}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    disabled={
                      selectedType != "MOST_IMPORTANT_INFO" ||
                      isAnswer == 3 ||
                      hintShow
                    }
                    onChange={(e) => {
                      onChangeInput(e.target.value, "MOST_IMPORTANT_INFO");
                    }}
                    onKeyDown={(e) => {
                      // if (e.key === "Enter") onKeyDownHandler();
                      if (e.key == " ") handleCorrectionOnChange("WHO_OR_WHAT");
                    }}
                    onBlur={() => {
                      handleCorrectionOnChange("WHO_OR_WHAT");
                    }}
                    multiline
                    rows={10}
                    error={mostImportantInfoError ? true : false}
                    autoComplete="off"
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}

        {sentenceOverview == "overview" && (
          <Grid
            container
            style={{
              padding: "0 31px",
              marginTop: "15px",
              maxHeight: "450px",
              overflow: "auto",
            }}
          >
            <Grid item xs={3}>
              <label>
                <b>Who/What</b>
              </label>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <label>
                <b>Most Important Information</b>
              </label>
            </Grid>
            <Grid item xs={3}></Grid>
            {finalValues &&
              finalValues.map((row) => {
                return (
                  <>
                    <Grid item xs={3}>
                      <label className="sentence-item">
                        {row?.WHO_OR_WHAT}
                      </label>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <label className="sentence-item">
                        {row?.MOST_IMPORTANT_INFO}
                      </label>
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </>
                );
              })}
          </Grid>
        )}

        {sentenceOverview == "gist" && (
          <>
            <Grid container spacing={2} className="mt-4">
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <GistScreen
                  selected={selected}
                  selectedSection={props?.selectedSection}
                  onChangeInput={onChangeInput}
                  handleCorrectionOnChange={props?.handleCorrectionOnChange}
                  onKeyDownHandler={onKeyDownHandler}
                  gistError={gistError}
                  selectedType={selectedType}
                  highlightGist={highlightGist}
                  formData={formData}
                  answers_and_clues={props?.answers_and_clues || []}
                  sectionImages={sectionImages}
                  hintShow={hintShow}
                />
                <Progress
                  val={4 + props.getCurrentIndex()}
                  totalSections={props?.totalSections}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </>
        )}

        {sentenceOverview == "sentence-intro" && (
          <>
            <Grid container spacing={2} className="mt-4">
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <RectangularCallout
                  selected={selectedContent}
                  type="GIST"
                  onClickBtn={onClickBtn}
                />
                <Hint data={[]} showVolume={false} from={"gist-page"} />
                <Progress
                  val={4 + props.getCurrentIndex()}
                  totalSections={props?.totalSections}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default SentenceScreen;
