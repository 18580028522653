import React, { useRef, useState, useEffect } from "react";
import bearIcon from "../../assets/icons/octopus.svg";
import "./styles.scss";
import Button from "@mui/material/Button";
import Volume from "../../components/volume";

const Hint = (props) => {
  const {
    show = false,
    selected,
    onClickBtn,
    onClickBear = () => {},
    showVolume = true,
    from = "",
    data = [],
  } = props;

  const popupRef = useRef(null);

  const [previousHint, setPreviousHint] = useState([]);

  const themeCls = window.localStorage.getItem("selectedTheme");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        show &&
        !selected?.is_disabled_allow &&
        !selected?.is_last_hint &&
        selected?.type !== "i_do_mode_content" &&
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        onClickBtn();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef, show, selected]);

  const getPreviousHint = () => {
    const arr = data.filter((x) => x?.is_hint && x?.id < selected?.id).sort((a, b) => {
      if(a?.id > b?.id){
        return -1
      } else {
        return 1;
      }
    });
    setPreviousHint(arr);
  };

  useEffect(() => {
    if (show && selected?.is_hint) {
      getPreviousHint();
    } else {
      setPreviousHint([])
    }
  }, [selected]);

  return (
    <div ref={popupRef}>
      <img
        className={
          from === "intro-page"
            ? "hint-outer-intro"
            : from === "section-page"
            ? "hint-outer-section"
            : from === "gist-page"
            ? "hint-outer-gist"
            : "hint-outer"
        }
        src={bearIcon}
        alt="bear-icon"
        onClick={onClickBear}
        // style={{ backgroundColor: 'transparent' }}
      />
      {showVolume && (
        <Volume
          content={
            selected?.btn_label_final_ans
              ? selected?.content + " " + selected?.btn_label_final_ans
              : selected?.content
          }
          audio={props?.audio}
          setAudio={props?.setAudio}
          onClickBtn={onClickBtn}
        />
      )}

      {show &&
        previousHint?.map((x, index) => {
          return (
            <div
              className={`previous-hint-callout-outer ${
                x?.content?.length > 350 ? "hint-callout-large" : ""
              } ${themeCls}`}
              style={{ right: `calc(${330 + 320*index}px + 8%)`}}
            >
              <div dangerouslySetInnerHTML={{ __html: x?.content }} />
            </div>
          );
        })}

      {show && selected?.content && (
        <div
          className={`hint-callout-outer ${
            selected?.content?.length > 350 ? "hint-callout-large" : ""
          } ${themeCls}`}
        >
          <div dangerouslySetInnerHTML={{ __html: selected?.content }} />
          <br />
          {/* <p className="inner-content">{selected?.content}</p> */}
          {selected?.btn_label1 && (
            <Button
              className="btn-trans-n"
              variant="contained"
              size="small"
              // onClick={onClickBtn}
              style={{
                marginTop: "10px",
                bottom: 30,
                width: "85%",
                backgroundColor: "#008080",
              }}
            >
              {selected?.btn_label1}
            </Button>
          )}
          {selected?.btn_label_final_ans && (
            <Button
              className="btn-trans-n"
              variant="contained"
              size="small"
              // onClick={onClickBtn}
              style={{
                marginTop: "10px",
                bottom: 30,
                width: "85%",
                backgroundColor: "#BDBDBD",
                color: "black",
              }}
            >
              {selected?.btn_label_final_ans}
            </Button>
          )}
          {selected?.btn_label && (
            <Button
              className="btn-trans-n"
              variant="contained"
              size="small"
              onClick={() => {
                if ("speechSynthesis" in window) {
                  window.speechSynthesis.cancel();
                }
                onClickBtn();
              }}
              style={{
                float: "right",
                borderRadius: 20,
                marginTop: "-10px",
                backgroundColor: "#008080",
              }}
            >
              {selected?.btn_label}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Hint;
