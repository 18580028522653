import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  is_speaking: false,
};

export const UiSlice = createSlice({
  name: "speakingStore",
  initialState,
  reducers: {
    setSpeakingValue: (state, action) => {
      state.is_speaking = action.payload;
    },
  },
});

export const { setSpeakingValue } = UiSlice.actions;
export default UiSlice.reducer;
