import React, { useEffect, useState, useRef } from "react";
import noImage from "../../../../assets/images/no-image.svg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setCaptionAudio } from "../../../../store/reducer/hintAudio";
import { setLoader } from "../../../../store/reducer/ui";
import api from "../../../../apis/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const Detail = (props) => {
  const {
    selected,
    highlightWord,
    circleWord,
    showParagraphHighlightAndCircle,
    underlineWord,
    showUnderline,
    currentMode,
    instructions,
    showInstruction,
    currentWordIndex,
    hintShow
  } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const [allImages, setAllImages] = useState([]);

  const { isIDoEnable } = useSelector((state) => state.sectionStore);
  const { showLoading } = useSelector((state) => state.ui);
  const { readImageCaption } = useSelector((state) => state.themeStore);
  const { audio } = useSelector((state) => state.paragraphAudioStore);
  const { audio: audioHint, captionAudio } = useSelector((state) => state.hintAudioStore);

  const captionRef = useRef(false);

  useEffect(() => {
    let arr = [];
    let arrWord = [];
    selected?.items.map((x) => {
      x.items.map((y) => {
        if (y.type === "Image") {
          arr.push(y);
        }
        if (y.type === "TextBlock") {
          let paragraph = y.lines.join(" ");
          arrWord = [...arrWord, ...paragraph.split(" ")];
        }
        return true;
      });
      return true;
    });
    setAllImages(arr);
  }, [selected]);

  useEffect(() => {
    if (captionAudio) {
      captionAudio.pause();
      captionAudio.currentTime = 0;
      dispatch(setCaptionAudio(null))
    }
  }, [audio, audioHint]);

  const wrapWordsInSpans = (htmlString, tempIndex = 0) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    function wrapTextNodes(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        const words = node.nodeValue
          .split(/(\s+|(?=[,.!?;:"()[\]])|(?<=[,.!?;:"()[\]]))/)
          .filter((word) => word !== "");

        const wrappedWords = words
          .map((word) => {
            if (/\s/.test(word)) {
              return word;
            }
            return `<span id=${tempIndex} class=${
              tempIndex++ < currentWordIndex + 1 && currentWordIndex > -1
                ? "highlight-speech-word"
                : ""
            }>${word}</span>`;
          })
          .join("");

        const spanWrapper = document.createElement("span");
        spanWrapper.innerHTML = wrappedWords;

        node.replaceWith(...spanWrapper.childNodes);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach(wrapTextNodes);
      }
    }

    Array.from(tempDiv.childNodes).forEach(wrapTextNodes);

    return tempDiv.innerHTML;
  };

  // Function to render text items as paragraphs
  // const renderTextItems = (items, ind) => {
  //   return items.map((item, itemIndex) => {
  //     if (item.type === "TextBlock") {
  //       let paragraph = item.lines.join(" ");

  //       // if (currentMode == "I_DO") {
  //       //   let replacedText1 = paragraph;
  //       //   if (showInstruction && instructions?.style.length) {
  //       //     switch (instructions?.style[0]) {
  //       //       case "highlight":
  //       //         instructions.format_string.map((x) => {
  //       //           const regex3 = new RegExp(x, "gi");
  //       //           replacedText1 = replacedText1.replace(regex3, (match) => {
  //       //             if (match === match.toUpperCase()) {
  //       //               return `<span class="highlighted-ptext">${match}</span>`;
  //       //             } else {
  //       //               return `<span class="highlighted-ptext">${match}</span>`;
  //       //             }
  //       //           });
  //       //         });
  //       //         break;
  //       //       case "bold":
  //       //         instructions.format_string.map((x) => {
  //       //           const regex3 = new RegExp(x, "gi");
  //       //           replacedText1 = replacedText1.replace(regex3, (match) => {
  //       //             if (match === match.toUpperCase()) {
  //       //               return `<span class="bold-word">${match}</span>`;
  //       //             } else {
  //       //               return `<span class="bold-word">${match}</span>`;
  //       //             }
  //       //           });
  //       //         });
  //       //         break;
  //       //       case "underline":
  //       //         instructions.format_string.map((x) => {
  //       //           const regex3 = new RegExp(x, "gi");
  //       //           replacedText1 = replacedText1.replace(regex3, (match) => {
  //       //             if (match === match.toUpperCase()) {
  //       //               return `<span class="underline-word">${match}</span>`;
  //       //             } else {
  //       //               return `<span class="underline-word">${match}</span>`;
  //       //             }
  //       //           });
  //       //         });
  //       //         break;
  //       //       case "italicize":
  //       //         instructions.format_string.map((x) => {
  //       //           const regex3 = new RegExp(x, "gi");
  //       //           replacedText1 = replacedText1.replace(regex3, (match) => {
  //       //             if (match === match.toUpperCase()) {
  //       //               return `<span class="italicize-word">${match}</span>`;
  //       //             } else {
  //       //               return `<span class="italicize-word">${match}</span>`;
  //       //             }
  //       //           });
  //       //         });
  //       //         break;
  //       //       default:
  //       //         break;
  //       //     }
  //       //   }
  //       //   return <div dangerouslySetInnerHTML={{ __html: replacedText1 }} />;
  //       // }

  //       const regex1 = new RegExp(" " + highlightWord, "gi");
  //       const regex2 = new RegExp(" " + circleWord, "gi");
  //       let replacedText1 = paragraph;
  //       if (
  //         underlineWord &&
  //         underlineWord?.length &&
  //         showUnderline &&
  //         currentMode != "YOU_DO"
  //       ) {
  //         underlineWord.map((x) => {
  //           const regex3 = new RegExp(x, "gi");
  //           replacedText1 = replacedText1.replace(regex3, (match) => {
  //             if (match === match.toUpperCase()) {
  //               return `<span class="underline-word">${match}</span>`;
  //             } else {
  //               return `<span class="underline-word">${match}</span>`;
  //             }
  //           });
  //         });
  //       }

  //       let replacedText2 = "";
  //       if (
  //         highlightWord &&
  //         showParagraphHighlightAndCircle &&
  //         currentMode != "YOU_DO"
  //       ) {
  //         replacedText2 = replacedText1.replace(regex1, (match) => {
  //           if (match === match.toUpperCase()) {
  //             return ` <span class="highlighted-ptext">${match}</span>`;
  //           } else {
  //             return ` <span class="highlighted-ptext">${match}</span>`;
  //           }
  //         });
  //       } else {
  //         replacedText2 = replacedText1;
  //       }

  //       let replacedText3 = "";
  //       if (
  //         circleWord &&
  //         showParagraphHighlightAndCircle &&
  //         currentMode != "YOU_DO"
  //       ) {
  //         replacedText3 = replacedText2.replace(regex2, (match) => {
  //           if (match === match.toUpperCase()) {
  //             return ` <span class="circle-ptext">${match}</span>`;
  //           } else {
  //             return ` <span class="circle-ptext">${match}</span>`;
  //           }
  //         });
  //       } else {
  //         replacedText3 = replacedText2;
  //       }

  //       const finalText = wrapWordsInSpans(replacedText3);
  //       return <div dangerouslySetInnerHTML={{ __html: finalText }} />;
  //     }
  //     return null;
  //   });
  // };

  const renderTextItemsV2 = () => {
    let paragraph = "";
    selected?.items?.map((row) => {
      let content = "";
      row?.items?.map((item) => {
        if (item?.type === "TextBlock") {
          content += " " + item?.lines.join();
        }
        return true;
      });
      if (content) paragraph += "<p class='flow-text'>" + content + "</p>";
      return true;
    });
    if (currentMode === "I_DO" && isIDoEnable && instructions.length) {
      let replacedText1 = paragraph;
      for (let i = 0; i < instructions.length; i++) {
        if (showInstruction && instructions[i]?.style.length) {
          switch (instructions[i]?.style[0]) {
            case "highlight":
              instructions[i].format_string.map((x) => {
                const regex3 = !/\s/.test(x)
                  ? new RegExp(`\\b${x}\\b`, "gi")
                  : new RegExp(x, "gi");
                replacedText1 = replacedText1.replace(regex3, (match) => {
                  if (match === match.toUpperCase()) {
                    return `<span class="highlighted-ptext">${match}</span>`;
                  } else {
                    return `<span class="highlighted-ptext">${match}</span>`;
                  }
                });
                return true;
              });
              break;
            case "bold":
              instructions[i].format_string.map((x) => {
                const regex3 = !/\s/.test(x)
                  ? new RegExp(`\\b${x}\\b`, "gi")
                  : new RegExp(x, "gi");
                replacedText1 = replacedText1.replace(regex3, (match) => {
                  if (match === match.toUpperCase()) {
                    return `<span class="bold-word">${match}</span>`;
                  } else {
                    return `<span class="bold-word">${match}</span>`;
                  }
                });
                return true;
              });
              break;
            case "underline":
              instructions[i].format_string.map((x) => {
                const regex3 = !/\s/.test(x)
                  ? new RegExp(`\\b${x}\\b`, "gi")
                  : new RegExp(x, "gi");
                replacedText1 = replacedText1.replace(regex3, (match) => {
                  if (match === match.toUpperCase()) {
                    return `<span class="underline-word">${match}</span>`;
                  } else {
                    return `<span class="underline-word">${match}</span>`;
                  }
                });
                return true;
              });
              break;
            case "italicize":
              instructions[i].format_string.map((x) => {
                const regex3 = !/\s/.test(x)
                  ? new RegExp(`\\b${x}\\b`, "gi")
                  : new RegExp(x, "gi");
                replacedText1 = replacedText1.replace(regex3, (match) => {
                  if (match === match.toUpperCase()) {
                    return `<i>${match}</i>`;
                  } else {
                    return `<i>${match}</i>`;
                  }
                });
                return true;
              });
              break;
            default:
              break;
          }
        }
      }

      const finalText1 = wrapWordsInSpans(replacedText1);
      return <div dangerouslySetInnerHTML={{ __html: finalText1 }} />;
    }

    const regex1 = !/\s/.test(highlightWord)
      ? new RegExp(`\\b${highlightWord}\\b`, "gi")
      : new RegExp(" " + highlightWord, "gi");
    const regex2 = !/\s/.test(circleWord)
      ? new RegExp(`\\b${circleWord}\\b`, "gi")
      : new RegExp(" " + circleWord, "gi");
    let replacedText1 = paragraph;
    if (
      underlineWord &&
      underlineWord?.length &&
      showUnderline &&
      (currentMode === "WE_DO" || (currentMode === "I_DO" && !isIDoEnable))
      // currentMode != "YOU_DO"
    ) {
      underlineWord.map((x) => {
        const regex3 = !/\s/.test(x)
          ? new RegExp(`\\b${x}\\b`, "gi")
          : new RegExp(x, "gi");
        replacedText1 = replacedText1.replace(regex3, (match) => {
          if (match === match.toUpperCase()) {
            return `<span class="underline-word">${match}</span>`;
          } else {
            return `<span class="underline-word">${match}</span>`;
          }
        });
        return true;
      });
    }

    let replacedText2 = "";
    if (
      highlightWord &&
      showParagraphHighlightAndCircle &&
      currentMode === "WE_DO"
      // currentMode != "YOU_DO"
    ) {
      replacedText2 = replacedText1.replace(regex1, (match) => {
        if (match === match.toUpperCase()) {
          return ` <span class="highlighted-ptext">${match}</span>`;
        } else {
          return ` <span class="highlighted-ptext">${match}</span>`;
        }
      });
    } else {
      replacedText2 = replacedText1;
    }

    let replacedText3 = "";
    if (
      circleWord &&
      showParagraphHighlightAndCircle &&
      currentMode === "WE_DO"
      // currentMode != "YOU_DO"
    ) {
      replacedText3 = replacedText2.replace(regex2, (match) => {
        if (match === match.toUpperCase()) {
          return ` <span class="circle-ptext">${match}</span>`;
        } else {
          return ` <span class="circle-ptext">${match}</span>`;
        }
      });
    } else {
      replacedText3 = replacedText2;
    }
    const finalText = wrapWordsInSpans(replacedText3);
    return <div dangerouslySetInnerHTML={{ __html: finalText }} />;
  };

  const handlePopup = () => {
    setOpen(!open);
  };

  const base64toBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "audio/mpeg" });
    return blob;
  };

  const onHoverImage = async (text) => {
    try {
      if (!text || captionAudio || showLoading || !readImageCaption || hintShow || captionRef.current) {
        return;
      }
      if (audioHint && audioHint?.currentTime > 0) {
        return;
      }
      if (audio && audio?.currentTime > 0) {
        return;
      }
      captionRef.current = true;
      setTimeout(() => {
        captionRef.current = false;
      }, 2000);
      dispatch(setLoader(true));
      let payload = {
        text,
      };
      const response = await api.post("/aispeech", payload);
      const audioBlob = base64toBlob(
        response.data.audio.replace("b'", "").replace("'", "")
      );
      const audioBlobUrl = URL.createObjectURL(audioBlob);
      const audio1 = new Audio(audioBlobUrl);
      dispatch(setCaptionAudio(audio1));
      audio1
        .play()
        .catch((error) => console.error("Error playing audio:", error));
      audio1.onended = function () {
        dispatch(setCaptionAudio(null))
      };
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  return (
    <div className="detail-outer" style={{ direction: "ltr" }}>
      {allImages.length > 0 && (
        <div style={{ width: "230px", float: "left", marginRight: "15px" }}>
          {allImages?.map((img) => {
            return (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handlePopup();
                  setSelectedImage(img);
                }}
              >
                <img
                  src={img?.url}
                  width="100%"
                  alt="section"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = noImage;
                  }}
                  onMouseEnter={() => {
                    onHoverImage(img.caption);
                  }}
                />
                <br />
                <p style={{ fontSize: "13px", marginBottom: 0 }}>{img.caption}</p>
              </div>
            );
          })}
        </div>
      )}
      {/* {selected?.items?.map((row, rowIndex) => (
        <div className="test" key={rowIndex} style={{ display: "flex" }}>
          <div>
            {row?.title && (
              <div dangerouslySetInnerHTML={{ __html: row?.title }} />
            )}
            {renderTextItems(row.items, rowIndex)}
          </div>
        </div>
      ))} */}

      <p>{renderTextItemsV2()}</p>

      {/* <p>
        {allWords.map((word, index) => (
          <span
            key={index}
            style={{ color: currentWordIndex >= index ? "red" : "black" }}
          >
            {word}{" "}
          </span>
        ))}
      </p> */}

      <Modal
        open={open}
        // onClose={handlePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="show-image-detail-modal" sx={style}>
          <img
            src={selectedImage?.url}
            alt="who what"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = noImage;
            }}
            className="who-what-img"
            style={{ maxHeight: "350px", maxWidth: "100%" }}
          />
          <br />
          <span className="img-modal-caption">{selectedImage?.caption}</span>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#008080",
              float: "right",
              marginTop: "10px",
            }}
            onClick={() => {
              handlePopup();
            }}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Detail;
