import React, { useState, useEffect } from "react";
import "./styles.scss";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTimer } from "../../components/timerContext";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { useDispatch, useSelector } from "react-redux";
import { setIsIDoEnable } from "../../store/reducer/sectionReducer";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#008080",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const maxLoadingTime = 10;

const ProgressChip = styled(Chip)(({ theme, progress }) => ({
  background: `linear-gradient(90deg, teal, teal ${progress}%, #30cfcf ${progress}%, teal 100%)`,
  "& .MuiChip-label": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
}));

const HeadSection = (props) => {
  const {
    onClickPreview,
    onClickNext,
    showPrevious = true,
    showNext = true,
    onClickDone = () => {},
    showSubmitBtn = true,
    selectedPassage,
    setSelectedPassage,
    showPassage = false,
    passageData = [],
    hintShow = false,
    highlightedSubmitButton = false,
    currentMode = "",
    selectedHint,
    onClickBtn,
  } = props;

  const backCount = localStorage.getItem("back-count");

  const [spinner, setSpinner] = useState(false);
  const [seconds, setSeconds] = useState(maxLoadingTime);
  const [progress, setProgress] = useState(0);

  const dispatch = useDispatch();
  const { isIDoEnable, I_do_json } = useSelector((state) => state.sectionStore);
  const showLoading = useSelector((state) => state.ui.showLoading);
  const { isHintAudioPlaying } = useSelector((state) => state.hintAudioStore);

  const { stopTimer, isRunning } = useTimer();
  const selectedPassageName = window.localStorage.getItem(
    "selectedPassageName"
  );

  useEffect(() => {
    if (highlightedSubmitButton) {
      setSeconds(maxLoadingTime);
      setProgress(0);
      setSpinner(true);
    } else {
      setSpinner(false);
    }
  }, [highlightedSubmitButton]);

  const handleChange = (event) => {
    let ind = passageData.find((x) => x.contentID === event.target.value);
    setSelectedPassage(event.target.value);
    window.localStorage.setItem("selectedPassage", event?.target.value);
    window.localStorage.setItem("selectedPassageName", ind?.name);
    window.localStorage.setItem("gistList", JSON.stringify([]));
    if (isRunning) {
      localStorage.removeItem("timer");
      stopTimer();
    }
  };

  useEffect(() => {
    let timer;
    if (spinner && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setProgress((prevProgress) => prevProgress + maxLoadingTime);
      }, 1000);
    } else if (seconds === 0) {
      setSpinner(false);
      setSeconds(maxLoadingTime);
      setProgress(0);
      if (highlightedSubmitButton) {
        onClickDone();
        return;
      }
      if (hintShow && selectedHint?.is_disabled_allow) {
        onClickBtn();
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [spinner, seconds]);

  const handleAudioEnd = () => {
    if (!spinner) {
      setSeconds(maxLoadingTime);
      setProgress(0);
      setSpinner(true);
    }
  };

  useEffect(() => {
    if (
      hintShow &&
      selectedHint?.is_disabled_allow &&
      !showLoading &&
      (selectedHint?.content.length < 130 || !isHintAudioPlaying)
    ) {
      if (!spinner) {
        setSeconds(maxLoadingTime);
        setProgress(0);
        setSpinner(true);
      }
    } else if (!highlightedSubmitButton) {
      setSpinner(false);
    }
  }, [selectedHint, showLoading, isHintAudioPlaying, hintShow]);

  return (
    <div className="dashboard-header-container-2 head-section">
      <div
        className="dashboard-header-inner-container-1"
        style={{ width: "45%" }}
      >
        <div
          style={{
            marginLeft: "auto",
            marginRight: "-17px",
            display: "inline-flex",
            // minWidth: "462px",
          }}
        >
          {showPassage ? (
            <FormControl style={{ width: "200px" }}>
              <InputLabel id="demo-simple-select-label">Passages</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPassage}
                label="Passages"
                onChange={handleChange}
                size="small"
              >
                {passageData?.map((p) => {
                  return <MenuItem value={p?.contentID}>{p?.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          ) : (
            <label className="passage-label">{selectedPassageName}</label>
          )}

          {/* <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className="theme-toggle"
            style={{ marginLeft: "5px" }}
          >
            <Typography style={{ minWidth: "82px" }}>Loader Music</Typography>
            <AntSwitch
              checked={loaderMusic}
              onChange={(e) => {
                dispatch(setLoaderMusic(e.target.checked));
              }}
              inputProps={{ "aria-label": "ant design" }}
            />
          </Stack> */}
        </div>
      </div>
      <div className="dashboard-header-inner-container-1 submit-container">
        <div style={{ margin: "0 auto" }}>
          {showSubmitBtn && !spinner ? (
            <Chip
              label="Submit"
              color="primary"
              disabled={hintShow}
              // onClick={handleClick}
              onClick={onClickDone}
              className={`${
                highlightedSubmitButton
                  ? "submit-btn highlighted-btn"
                  : "submit-btn"
              }`}
            />
          ) : showSubmitBtn && spinner ? (
            <div
              disabled={true}
              variant="contained"
              style={{ position: "relative" }}
            >
              <Box>
                <ProgressChip
                  label="Submitting..."
                  color="primary"
                  style={{
                    border: "1px solid gray",
                    // cursor:
                    //   highlightedSubmitButton && spinner ? "pointer" : "none",
                  }}
                  progress={progress}
                  component="span"
                  className={`${
                    highlightedSubmitButton
                      ? "submit-btn highlighted-btn"
                      : "submit-btn"
                  }`}
                  // disabled={hintShow}
                  onClick={() => {
                    if (hintShow) {
                      onClickBtn();
                    } else if (highlightedSubmitButton && spinner) {
                      onClickDone();
                    }
                  }}
                />
              </Box>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="dashboard-header-inner-container-1"
        style={{ width: "45%", textAlign: "right" }}
      >
        <div
          style={{
            marginLeft: "auto",
            marginRight: "-17px",
            display: "inline-flex",
            // minWidth: "462px",
          }}
        >
          {currentMode === "I_DO" && I_do_json?.talk_tracks && (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              className="theme-toggle"
            >
              <Typography style={{ minWidth: "82px" }}>I-Do Mode</Typography>
              <AntSwitch
                defaultChecked
                defaultValue={isIDoEnable}
                onChange={(e) => {
                  dispatch(setIsIDoEnable(e.target.checked));
                }}
                inputProps={{ "aria-label": "ant design" }}
              />
              {/* <Typography>On</Typography> */}
            </Stack>
          )}

          {/* {showAutoCorrectToggle && (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              className="theme-toggle"
            >
              <Typography style={{ minWidth: "110px" }}>
                Correct Typos
              </Typography>
              <AntSwitch
                defaultChecked
                defaultValue={isAutoCorrect}
                onChange={(e) => {
                  setIsAutoCorrect(e.target.checked);
                }}
                inputProps={{ "aria-label": "ant design" }}
              />
            </Stack>
          )}
           */}

          {showPrevious && (
            <Button
              className="btn-trans-n mx-2 preview-btn"
              startIcon={<ArrowBackIosIcon />}
              disabled={backCount >= 2 || hintShow}
              onClick={() => {
                localStorage.setItem("back-count", Number(backCount) + 1);
                onClickPreview();
              }}
            >
              <b>Previous</b>
            </Button>
          )}

          {showNext && (
            <Button
              className="btn-trans-n mx-2 next-btn"
              endIcon={<ArrowForwardIosIcon />}
              disabled={!showNext || hintShow}
              onClick={() => {
                if (Number(backCount) > 0) {
                  localStorage.setItem("back-count", Number(backCount) - 1);
                }
                onClickNext();
              }}
            >
              <b>Next</b>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeadSection;
