import { useState } from "react";
import { useSelector } from "react-redux";
import ProtectRoutes from "../../routes/protect";
import Header from "./header";
import "./styles.scss";
import "../../constants/styles.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { TimerProvider } from "../../components/timerContext";
import ErrorBoundary from "../../components/errorBoundary";

const data = [
  {
    label: "Introduction",
    selectedIndex: 1,
    url: "/intro",
  },
  {
    label: "Video",
    selectedIndex: 2,
    url: "/video",
  },
  {
    label: "Section",
    selectedIndex: 3,
    url: "/section",
  },
];

const Universal = () => {
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  let ind = data.findIndex((x) => x.url === location?.pathname);
  const [selectedBox, setSelectedBox] = useState(ind > -1 ? ind : 0);

  const themeCls = window.localStorage.getItem("selectedTheme");
  const [selectedThemeClass, setSelectedThemeClass] = useState(
    themeCls ? themeCls : "medium-font"
  );

  const onChangeRoutes = (ind = 0) => {
    if (ind < 0) {
      setSelectedBox(-1);
      return navigate("/");
    }
    if (ind > data?.length) {
      return;
    }
    setSelectedBox(ind);
    navigate(data[ind]?.url);
  };

  return (
    <div className="container-fluid dashboard-main-container">
      <TimerProvider>
        <Header
          onChangeRoutes={onChangeRoutes}
          selectedBox={selectedBox}
          data={data}
          selectedThemeClass={selectedThemeClass}
          setSelectedThemeClass={setSelectedThemeClass}
          user_data={authData?.user_data}
        />
        <div className="dashboard-main-inside-container">
          <div className="row dashboard-right-section">
            <ErrorBoundary>
              <ProtectRoutes selectedThemeClass={selectedThemeClass} />
            </ErrorBoundary>
          </div>
        </div>
      </TimerProvider>
    </div>
  );
};

export default Universal;
