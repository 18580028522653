import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import loaderIcon from "../../assets/icons/loader.gif";
import music from "../../assets/video/music.mp3";
import "./styles.scss";

const audio = new Audio(music);

const Loader = (props) => {
  const showLoading = useSelector((state) => state.ui.showLoading);
  const { loaderMusic } = useSelector((state) => state.sectionStore);

  useEffect(() => {
    if (showLoading && audio && loaderMusic) {
      audio
        .play()
        .then(() => {
          console.log("Audio is playing");
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
        });
    } else if(audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [showLoading]);

  return showLoading ? (
    <div className="loader-main-container">
      {/* <LoaderIcon color={Colors.mediumSlateBlue} width={50} height={50} /> */}
      <img src={loaderIcon} width={130} alt="loader" />
    </div>
  ) : props?.loading === true ? (
    <div className="loader-main-container">
      {/* <LoaderIcon color={Colors.mediumSlateBlue} width={50} height={50} /> */}
      <img src={loaderIcon} width={130} alt="loader" />
    </div>
  ) : null;
};

export default Loader;
