import React from "react";
import { Typography, styled, Switch, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoaderMusic,
  setIsAutoCorrect,
} from "../../../store/reducer/sectionReducer";
import { setReadImageCaption } from "../../../store/reducer/themeReducer";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#008080",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const GeneralSetting = (props) => {
  const dispatch = useDispatch();
  const { loaderMusic, isAutoCorrect } = useSelector(
    (state) => state.sectionStore
  );
  const { readImageCaption } = useSelector((state) => state.themeStore);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        className="loader-toggle"
        sx={{
          marginBottom: "5px",
        }}
      >
        <AntSwitch
          checked={loaderMusic}
          onChange={(e) => {
            dispatch(setLoaderMusic(e.target.checked));
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Typography style={{ minWidth: "82px" }}>Loader Music</Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        className="loader-toggle"
        sx={{
          marginBottom: "5px",
        }}
      >
        <AntSwitch
          checked={isAutoCorrect}
          onChange={(e) => {
            dispatch(setIsAutoCorrect(e.target.checked));
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Typography style={{ minWidth: "82px" }}>Correct Typos</Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        className="loader-toggle"
        sx={{
          marginBottom: "5px",
        }}
      >
        <AntSwitch
          checked={readImageCaption}
          onChange={(e) => {
            dispatch(setReadImageCaption(e.target.checked));
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Typography style={{ minWidth: "82px" }}>Read Image Caption</Typography>
      </Stack>
    </>
  );
};

export default GeneralSetting;
