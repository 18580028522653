import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import Detail from "./detail";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Progress from "../../../components/progress";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../apis/api";
import { setLoader } from "../../../store/reducer/ui";
import { setAudio } from "../../../store/reducer/paragraphAudio";
import { setPause } from "../../../store/actions/appActions";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

const maxLoadingTime = 10;

const ProgressChip = styled(Chip)(({ theme, progress }) => ({
  background: `linear-gradient(90deg, teal, teal ${progress}%, #30cfcf ${progress}%, teal 100%)`,
  "& .MuiChip-label": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
}));

const WhoWhatScreen = (props) => {
  const {
    selected,
    formData,
    onChangeInput,
    handleCorrectionOnChange,
    selectedType,
    onKeyDownHandler,
    whoWhatError,
    mostImportantInfoError,
    selectedHint,
    highlightedWhoWhat,
    highlightMostImportant,
    showMostImpInfo,
    highlightWord,
    circleWord,
    isPlaying,
    setIsPlaying,
    speaking,
    setSpeaking,
    showParagraphHighlightAndCircle,
    underlineWord,
    showUnderline,
    currentMode,
    hintShow,
    highlightSubmitButton,
    answers_and_clues,
    onCompleteParagraphReading,
    handleIDoStart,
    onFocusTextBox,
  } = props;

  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.paragraphAudioStore);
  const { pause } = useSelector((state) => state.appStore);
  const { audio: audioHint, speaking: speakingVolume } = useSelector(
    (state) => state.hintAudioStore
  );
  const speakingVolumeRef = useRef(speakingVolume);

  const { I_do_json, isIDoEnable } = useSelector((state) => state.sectionStore);

  const [highlightedPlay, setHighlightedPlay] = useState(false);
  const [highlightedVariousPlay, setHighlightedVariousPlay] = useState(false);

  const [selectedJson, setSelectedJson] = useState({});
  const [showInstruction, setShowInstruction] = useState(false);
  const [instructions, setInstructions] = useState([]);
  const [audioWordTime, setAudioWordTime] = useState([]);

  const [currentWordIndex, setCurrentWordIndex] = useState(-1);
  const audioRef = useRef(null);
  const variousRef = useRef(null);

  const [spinner, setSpinner] = useState(false);
  const [seconds, setSeconds] = useState(maxLoadingTime);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    stopAudio();
  }, [selected]);

  useEffect(() => {
    setSelectedJson(I_do_json?.talk_tracks ? I_do_json?.talk_tracks[0] : {});
  }, [I_do_json]);

  useEffect(() => {
    if (selectedHint?.highlight_type === "play_btn") {
      setHighlightedPlay(true);
      // setTimeout(() => setHighlightedPlay(false), 8000);
    }
    if (selectedHint?.highlight_type === "lesion_btn") {
      highlightVariousButton();
    }
  }, [selectedHint]);

  useEffect(() => {
    if (currentMode === "I_DO" && !isIDoEnable && variousRef?.current) {
      audio.pause();
      audio.currentTime = 0;
      setCurrentWordIndex(-1);
      variousRef.current = false;
    } else {
      setHighlightedPlay(false);
    }
  }, [isIDoEnable]);

  useEffect(() => {
    speakingVolumeRef.current = speakingVolume;
    if (audio && audio?.currentTime > 0) {
      audio.volume = speakingVolume ? 1 : 0;
      dispatch(setAudio(audio));
    }
  }, [speakingVolume]);

  useEffect(() => {
    if (
      !hintShow &&
      selectedHint?.type === "i_do_mode_content" &&
      !speakingVolume &&
      currentMode === "I_DO" &&
      isIDoEnable
    ) {
      audio.pause();
      audio.currentTime = 0;
      dispatch(setAudio(audio));
      setCurrentWordIndex(-1);
      variousRef.current = false;
      redirectNextVarious();
    } else if (hintShow) {
      setIsPlaying(false);
    }
  }, [hintShow]);

  const handleTimeUpdate = () => {
    if (!audio || variousRef?.current) {
      setCurrentWordIndex(-1);
      return;
    }
    const currentTime = audio.currentTime;
    const lastWords = audioWordTime.filter(
      (x) => x?.audio_offset_ms <= Math.floor(currentTime * 1000)
    );
    const wordIndex = lastWords.length - 1;
    if (wordIndex >= currentWordIndex) {
      setCurrentWordIndex(wordIndex);
    }
  };

  if (audio) {
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("ended", () => setCurrentWordIndex(-1));
  }

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (speaking) {
      setSpeaking(false);
      audio.pause();
      audio.currentTime = 0;
      setCurrentWordIndex(-1);
      return;
    }
    let content = "";
    selected?.items?.map((row) => {
      return row?.items?.map((item) => {
        if (item?.type === "TextBlock") {
          content += " " + item?.lines.join();
        }
        return true;
      });
    });
    setSpeaking(true);
    if (content) {
      getSpeech(content);
    }
  };

  const togglePlayResume = () => {
    if (speaking && audio) {
      if (pause) {
        audio.play();
        if (audioHint) {
          audioHint.pause();
          audioHint.currentTime = 0;
          setCurrentWordIndex(-1);
        }
        setIsPlaying(true);
        dispatch(setPause(false));
      } else {
        audio.pause();
        dispatch(setPause(true));
        setIsPlaying(false);
      }
    }
  };

  const redirectNextVarious = () => {
    variousRef.current = false;
    let talk_tracks = I_do_json.talk_tracks.filter((x) => x.talk_track);
    let ind = talk_tracks.findIndex(
      (x) => x?.talk_track === selectedJson?.talk_track
    );
    if (ind < talk_tracks.length - 1) {
      let arr = instructions;
      let obj = {
        ...I_do_json.talk_tracks[ind].format_instructions,
        seq: 0,
      };
      if (obj.style[0] === "bold") {
        obj.seq = 1;
      } else if (obj.style[0] === "underline") {
        obj.seq = 2;
      } else if (obj.style[0] === "italicize") {
        obj.seq = 3;
      } else if (obj.style[0] === "highlight") {
        obj.seq = 4;
      }
      arr.push(obj);
      const sortArr = arr.sort((a, b) => {
        if (a.seq > b.seq) {
          return 1;
        } else {
          return -1;
        }
      });
      setInstructions(sortArr);
      setShowInstruction(true);
      setSelectedJson(talk_tracks[ind + 1]);
      highlightVariousButton();
      onCompleteParagraphReading("END");
    } else if (ind === talk_tracks.length - 1) {
      let arr = instructions;
      let obj = {
        ...talk_tracks[ind].format_instructions,
        seq: 0,
      };
      if (obj.style[0] === "bold") {
        obj.seq = 1;
      } else if (obj.style[0] === "underline") {
        obj.seq = 2;
      } else if (obj.style[0] === "italicize") {
        obj.seq = 3;
      } else if (obj.style[0] === "highlight") {
        obj.seq = 4;
      }
      arr.push(obj);
      const sortArr = arr.sort((a, b) => {
        if (a.seq > b.seq) {
          return 1;
        } else {
          return -1;
        }
      });
      setInstructions(sortArr);
      setShowInstruction(true);
      const sectionInd = answers_and_clues.findIndex(
        (x) => x.section_id === selected?.id
      );
      if (selectedType === "WHO_OR_WHAT" && sectionInd > -1) {
        onChangeInput(
          I_do_json?.who_or_what
            ? I_do_json?.who_or_what
            : answers_and_clues[sectionInd].section_level?.who_or_what?.answer,
          "WHO_OR_WHAT"
        );
        highlightSubmitButton();
      } else if (selectedType === "MOST_IMPORTANT_INFO" && sectionInd > -1) {
        onChangeInput(
          I_do_json?.most_important_info
            ? I_do_json?.most_important_info.join("\n")
            : answers_and_clues[sectionInd].section_level?.most_important_info,
          "MOST_IMPORTANT_INFO"
        );
        highlightSubmitButton();
      }
    }
  };

  const stopAudio = () => {
    setIsPlaying(false);
    setSpeaking(false);
    dispatch(setPause(false));
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      dispatch(setAudio(audio));
      setCurrentWordIndex(-1);
    }
    if (
      currentMode === "I_DO" &&
      isIDoEnable &&
      variousRef?.current &&
      I_do_json?.talk_tracks &&
      speakingVolumeRef?.current
    ) {
      redirectNextVarious();
    }
    variousRef.current = false;
  };

  // online speech
  const getSpeech = async (text) => {
    setHighlightedPlay(false);
    setHighlightedVariousPlay(false);
    try {
      if (!text) {
        return;
      }
      // setInstructions({});
      // setShowInstruction(false);
      dispatch(setLoader(true));
      let payload = {
        text,
      };
      const response = await api.post("/aispeech", payload);
      const audioBlob = base64toBlob(
        response.data.audio.replace("b'", "").replace("'", "")
      );
      setAudioWordTime(response?.data?.events || []);
      const audioBlobUrl = URL.createObjectURL(audioBlob);
      playAudio(audioBlobUrl);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      return {};
    }
  };

  const base64toBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "audio/mpeg" });
    return blob;
  };

  const playAudio = (audioUrl) => {
    const audio1 = new Audio(audioUrl);
    audioRef.current = audio1;
    if (
      // currentMode === "I_DO" &&
      // isIDoEnable &&
      // variousRef?.current &&
      !speakingVolume
    ) {
      audio1.volume = 0;
    }
    dispatch(setAudio(audio1));
    if (audioHint) {
      audioHint.pause();
    }

    audio1
      .play()
      .catch((error) => console.error("Error playing audio:", error));
    audio1.onended = function () {
      if (currentMode === "I_DO" && isIDoEnable && !variousRef?.current) {
        highlightVariousButton();
        onCompleteParagraphReading("START");
      }
      stopAudio();
    };
  };

  const playIDoVarious = () => {
    setSpinner(false);
    if (speaking) {
      setIsPlaying(false);
      setSpeaking(false);
      dispatch(setPause(false));
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
        setCurrentWordIndex(-1);
      }
    }
    if (audio && audio?.currentTime > 0) {
      audio.pause();
      audio.currentTime = 0;
    }
    if (audioHint && audioHint?.currentTime > 0) {
      audioHint.pause();
      audioHint.currentTime = 0;
    }
    let content = "";
    if (currentMode === "I_DO" && isIDoEnable && selectedJson?.talk_track) {
      content = selectedJson?.talk_track;
      // setShowInstruction(false);
    }
    if (content) {
      variousRef.current = true;
      handleIDoStart(content);
      getSpeech(content);
    }
  };
  // online speech end

  useEffect(() => {
    let timer;
    if (spinner && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setProgress((prevProgress) => prevProgress + maxLoadingTime);
      }, 1000);
    } else if (seconds === 0) {
      setSpinner(false);
      setSeconds(maxLoadingTime);
      setProgress(0);
      if (highlightedVariousPlay && isIDoEnable) {
        playIDoVarious();
        return;
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [spinner, seconds]);

  const highlightVariousButton = () => {
    setHighlightedVariousPlay(true);
    setSeconds(maxLoadingTime);
    setProgress(0);
    setSpinner(true);
    // setTimeout(() => setHighlightedVariousPlay(false), 8000);
  };

  return (
    <>
      <Grid container spacing={2} className="" style={{ overflowX: "hidden" }}>
        <Grid item xs={8}>
          <label style={{ paddingLeft: "25px" }}>{props?.title}</label>
          <Detail
            selected={selected}
            highlightWord={highlightWord}
            circleWord={circleWord}
            showParagraphHighlightAndCircle={showParagraphHighlightAndCircle}
            underlineWord={underlineWord}
            showUnderline={showUnderline}
            currentMode={currentMode}
            type="WHO_WHAT"
            instructions={instructions}
            showInstruction={showInstruction}
            currentWordIndex={currentWordIndex}
            hintShow={hintShow}
          />

          <div>
            {isPlaying && !pause ? (
              <label
                className={`${
                  highlightedPlay
                    ? "highlighted-play active-speech-btn"
                    : "active-speech-btn"
                }`}
                onClick={!speaking ? togglePlayPause : togglePlayResume}
              >
                Pause &nbsp;
                <PauseCircleOutlineIcon />
              </label>
            ) : (
              <Tooltip title="Read Paragraph" placement="top" arrow>
                <>
                  <label
                    onClick={() => {
                      if (variousRef.current) {
                        return;
                      }
                      if (!speaking) {
                        togglePlayPause();
                      } else {
                        togglePlayResume();
                      }
                    }}
                    className={`${
                      highlightedPlay
                        ? "highlighted-play active-speech-btn"
                        : "active-speech-btn"
                    }`}
                  >
                    Play &nbsp;
                    <PlayCircleOutlineIcon />
                  </label>
                </>
              </Tooltip>
            )}
            <Tooltip title="Stop Reading" placement="top" arrow>
              <>
                <label
                  className={
                    isPlaying ? "active-speech-btn" : "inactive-speech-btn"
                  }
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={stopAudio}
                >
                  Stop &nbsp;
                  <StopCircleOutlinedIcon />
                </label>
              </>
            </Tooltip>

            {currentMode === "I_DO" &&
              isIDoEnable &&
              I_do_json?.talk_tracks &&
              (!spinner ? (
                <Tooltip title="Read Lesson" placement="top" arrow>
                  <>
                    <label
                      className={`${
                        highlightedVariousPlay
                          ? "active-speech-btn highlighted-play"
                          : "active-speech-btn"
                      }`}
                      onClick={() => {
                        // if (!variousRef.current) {
                        playIDoVarious();
                        // }
                      }}
                      style={{
                        float: "right",
                        paddingRight: "6px",
                      }}
                    >
                      Show Me &nbsp;
                      <SmartDisplayIcon />
                    </label>
                  </>
                </Tooltip>
              ) : (
                <div
                  disabled={true}
                  variant="contained"
                  style={{
                    position: "relative",
                    margin: "15px",
                    float: "right",
                  }}
                >
                  <Box>
                    <ProgressChip
                      label="Show Me"
                      deleteIcon={<SmartDisplayIcon />}
                      onDelete={() => {}}
                      color="primary"
                      style={{
                        fontSize: "1.2rem",
                        padding: "10px",
                        border: "1px solid gray",
                        cursor: "pointer",
                      }}
                      progress={progress}
                      component="span"
                      className={`${
                        highlightedVariousPlay ? "highlighted-btn" : ""
                      }`}
                      onClick={() => {
                        if (!variousRef.current) {
                          playIDoVarious();
                        }
                      }}
                    />
                  </Box>
                </div>
              ))}
          </div>

          <Progress
            val={4 + props.getCurrentIndex()}
            margin={"15px"}
            totalSections={props?.totalSections}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid>
            <label>
              <b>Who/What</b>
            </label>
            <TextField
              type="text"
              placeholder="who/what"
              id="Erosion"
              label=""
              value={formData?.WHO_OR_WHAT}
              size="small"
              fullWidth
              className={`${highlightedWhoWhat ? "highlighted-textfield" : ""}`}
              style={{
                background:
                  selectedType === "WHO_OR_WHAT" && props?.isAnswer !== 3
                    ? "white"
                    : "#d9d9d9",
                pointerEvents: hintShow ? "none" : null,
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              disabled={
                (currentMode === "I_DO" && isIDoEnable) ||
                selectedType !== "WHO_OR_WHAT" ||
                props?.isAnswer === 3 ||
                hintShow
              }
              onChange={(e) => {
                onChangeInput(e.target.value, "WHO_OR_WHAT");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") onKeyDownHandler();
                if (e.key === " ") handleCorrectionOnChange("WHO_OR_WHAT");
              }}
              onBlur={() => {
                handleCorrectionOnChange("WHO_OR_WHAT");
              }}
              onFocus={onFocusTextBox}
              error={whoWhatError ? true : false}
              autoComplete="off"
            />
          </Grid>
          <br />
          {selectedType !== "" &&
            selectedType !== "WHO_OR_WHAT" &&
            showMostImpInfo && (
              <Grid>
                <label>
                  <b>Most Important Information</b>
                </label>
                <TextField
                  type="text"
                  placeholder="Notes about the most important information about the main idea"
                  className={`${
                    highlightMostImportant ? "highlighted-textfield" : ""
                  }`}
                  id="answer"
                  label=""
                  value={formData?.MOST_IMPORTANT_INFO}
                  size="small"
                  fullWidth
                  style={{
                    background:
                      selectedType === "MOST_IMPORTANT_INFO" &&
                      props?.isAnswer !== 3
                        ? "white"
                        : "#d9d9d9",
                    pointerEvents: hintShow ? "none" : null,
                  }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled={
                    (currentMode === "I_DO" && isIDoEnable) ||
                    selectedType !== "MOST_IMPORTANT_INFO" ||
                    props?.isAnswer === 3 ||
                    hintShow
                  }
                  onChange={(e) => {
                    onChangeInput(e.target.value, "MOST_IMPORTANT_INFO");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ")
                      handleCorrectionOnChange("MOST_IMPORTANT_INFO");
                  }}
                  onBlur={() => {
                    handleCorrectionOnChange("MOST_IMPORTANT_INFO");
                  }}
                  onFocus={onFocusTextBox}
                  multiline
                  rows={10}
                  error={mostImportantInfoError ? true : false}
                  autoComplete="off"
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default WhoWhatScreen;
