import React, { useEffect, useState } from "react";
import "./styles.scss";
import Detail from "./detail";
import Grid from "@mui/material/Grid";
import Progress from "../../../components/progress";
import Hint from "../../../components/hint";
import RectangularCallout from "../../../components/rectangularCallout";
import { useSelector } from "react-redux";

const GistScreen = (props) => {
  const {
    selected,
    formData,
    onChangeInput,
    handleCorrectionOnChange,
    selectedType,
    onKeyDownHandler,
    gistError,
    onLoadSectionGist,
    sectionGistType,
    setSectionGistType,
    highlightGist,
    setCustomSpeechContent,
    setShowCustomSpeech,
    currentIndex,
    hintShow,
    currentMode,
    isPlaying,
    setIsPlaying,
    speaking,
    setSpeaking,
    highlightSubmitButton,
    answers_and_clues,
    selectedHint,
    onCompleteParagraphReading,
    handleIDoStart,
    onFocusTextBox,
  } = props;
  const [contentArr, setContentArr] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);

  const [sectionImages, setSectionImages] = useState([]);

  const { dynamic_messages } = useSelector((state) => state.appStore);

  useEffect(() => {
    let arr = [];
    selected.items.map((x) => {
      x.items.map((y) => {
        if (y.type === "Image") {
          arr.push(y);
        }
        return true;
      });
      return true;
    });
    setSectionImages(arr);
  }, []);

  useEffect(() => {
    let arr = [];
    if (currentIndex === 1) {
      arr = [
        {
          id: 0,
          content: dynamic_messages?.gist_intro1,
          btn_label: "Continue",
        },
        {
          id: 1,
          content:
            dynamic_messages?.gist_intro2 +
            " <ul class='fit-content' style='margin: 0 auto'><li class='fit-content'>The <b>who or what.</b></li><li class='fit-content'>The <b>most important information</b> about the who or what, in the paragraph.</li></ul>",
          btn_label: "Ok",
        },
      ];
    } else {
      arr = [
        {
          id: 0,
          content:
            "We have taken notes on the important ideas. Our next step is to summarize by writing a <b>gist</b> statement.",
          btn_label: "Ok",
        },
      ];
    }
    setContentArr(arr);
    setSelectedContent(arr[0]);
    setSectionGistType("intro");
    setCustomSpeechContent(arr[0]);
    setShowCustomSpeech(true);
  }, []);

  const onClickBtn = () => {
    if (selectedContent?.id < contentArr.length - 1) {
      setSelectedContent(contentArr[selectedContent?.id + 1]);
      setCustomSpeechContent(contentArr[selectedContent?.id + 1]);
      setShowCustomSpeech(true);
    } else {
      onLoadSectionGist();
      setSectionGistType("detail");
      setShowCustomSpeech(false);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        className="mt-4"
        style={{ maxHeight: "80vh", overflow: "auto" }}
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          {sectionGistType === "intro" ? (
            <>
              <RectangularCallout
                selected={selectedContent}
                type="GIST"
                onClickBtn={onClickBtn}
              />
              <Hint data={[]} showVolume={false} from={"gist-page"} />
            </>
          ) : (
            <Detail
              selected={selected}
              formData={formData}
              onChangeInput={onChangeInput}
              handleCorrectionOnChange={handleCorrectionOnChange}
              selectedType={selectedType}
              onKeyDownHandler={onKeyDownHandler}
              gistError={gistError}
              highlightGist={highlightGist}
              sectionImages={sectionImages}
              hintShow={hintShow}
              currentMode={currentMode}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              speaking={speaking}
              setSpeaking={setSpeaking}
              highlightSubmitButton={highlightSubmitButton}
              answers_and_clues={answers_and_clues}
              selectedHint={selectedHint}
              onCompleteParagraphReading={onCompleteParagraphReading}
              handleIDoStart={handleIDoStart}
              onFocusTextBox={onFocusTextBox}
            />
          )}
          <Progress
            val={4 + props.currentIndex}
            totalSections={props?.totalSections}
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </>
  );
};

export default GistScreen;
