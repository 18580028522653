import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

var reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
};
var appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=caf86dea-9b16-4449-8925-540a387d5ae4;IngestionEndpoint=https://westus3-1.in.applicationinsights.azure.com/;ApplicationId=ea8da7ff-7f9a-4954-8016-368d6de76dd6',
        extensions: [reactPlugin, clickPluginInstance],
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensionConfig: {
         
        // *** Add the Click Analytics plug-in. ***
          [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

export { reactPlugin, appInsights };