import { useEffect } from "react";
import DisableDevtool from "disable-devtool";

const useDisableBrowserBack = () => {
  if (process.env.REACT_APP_ENVIREMENT === "production") {
    DisableDevtool();
  }

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    window.addEventListener("beforeunload", function (event) {
      event.preventDefault(); // Prevent default browser behavior
      // Optionally, you can provide a custom message here
      event.returnValue = "";
    });

    return () => {
      window.removeEventListener("beforeunload", function (event) {
        event.preventDefault(); // Prevent default browser behavior
        // Optionally, you can provide a custom message here
        event.returnValue = "";
      });
    };
  }, []);
};

export default useDisableBrowserBack;
