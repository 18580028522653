import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import ToastContext from "../../components/toast/toastContext";

import { loginUser } from "../../store/reducer/auth";
import { setLoader } from "../../store/reducer/ui";
import { verifyPassword, isLoggedIn } from "../../utils/helper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import "./styles.scss";
import "../../constants/styles.scss";
import clipArtImage from "../../assets/images/login-clipart.svg";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [usernameErrorText, setUsernameErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    isLoggedIn() && navigate("/");
  });

  const onChangeInput = (event, type) => {
    const value = event.target.value;
    formData[type] = value;
    setFormData({ ...formData });
    switch (type) {
      case "username":
        value
          ? setUsernameErrorText("")
          : setUsernameErrorText("Username is required.");
        break;
      case "password":
        verifyPassword(value)
          ? setPasswordErrorText("")
          : setPasswordErrorText("Password must be at least 8 characters.");
        break;
      default:
        break;
    }
  };

  const onClickLogin = async () => {
    if (!formData?.username || !formData?.password) {
      toastMessage("error", "Username or password is required!");
      return;
    }
    dispatch(setLoader(true));
    const { error, payload } = await dispatch(loginUser(formData));
    if (error) {
      toastMessage("error", "Incorrect username or password!");
    } else if (payload.status === 200 && payload.data.status) {
      navigate("/");
    } else {
      toastMessage("error", "Incorrect username or password!");
    }
    dispatch(setLoader(false));
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <Grid
        container
        spacing={2}
        className="login-outer"
        justifyContent="center"
      >
        <Grid item xs={7} className="left-section">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <h2 className="text-center mb-4 theme-color">
                <b>Welcome back!</b>
              </h2>
              <Grid style={{ marginBottom: "15px" }}>
                <TextField
                  label="Username"
                  placeholder="username"
                  value={formData?.username || ""}
                  onChange={(event) => {
                    onChangeInput(event, "username");
                  }}
                  style={{
                    background: "white",
                    borderRadius: "8px",
                  }}
                  errorText={usernameErrorText}
                  error={usernameErrorText ? true : false}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  onKeyDown={(e) => {
                    if (e.key === "Enter") onClickLogin();
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid style={{ marginBottom: "15px" }}>
                <TextField
                  label="Password"
                  placeholder="********"
                  value={formData?.password || ""}
                  onChange={(event) => {
                    onChangeInput(event, "password");
                  }}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  errorText={passwordErrorText}
                  error={passwordErrorText ? true : false}
                  style={{
                    background: "white",
                    borderRadius: "8px",
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  onKeyDown={(e) => {
                    if (e.key === "Enter") onClickLogin();
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={onClickLogin}
                  disabled={
                    formData?.username && verifyPassword(formData?.password)
                      ? false
                      : true
                  }
                  style={{ backgroundColor: "#008080" }}
                  fullWidth
                  text="Sign in"
                />
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} className="right-section">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <h2 className="top-content">
              <b>
                What do you want <br /> to learn today?
              </b>
            </h2>
            <img src={clipArtImage} alt="clip-art" width="80%" />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
